@import '~bootstrap/scss/functions.scss';
@import '~bootstrap/scss/variables.scss';
@import '~bootstrap/scss/mixins.scss';

.bg {
  min-height: 560px;
  background-repeat: no-repeat;
  background-size: cover;
}

.navigation-section {
  a {
    width: 460px;
    height: 108px;
    border-radius: 8px;
    font-size: 32px !important;
    font-weight: 700 !important;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      flex-grow: 1;
      max-height: 100px;
    }

    span {
      text-align: left;
      flex-grow: 2;
    }

    @include media-breakpoint-down('sm') {
      width: 280px;
      height: 95px;
      font-size: 24px !important;
      img {
        height: 78.54px;
      }
      &:first-child {
        margin-bottom: 20px;
      }
    }

    @include media-breakpoint-up('lg') {
      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
