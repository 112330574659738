.isActive {
  position: relative;
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    display: block;
    opacity: 0.2;
  }
}

.link {
  height: 48px;
  .hidden {
    display: none;
  }
  &:hover:not(.isActive) {
    svg {
      display: none;
    }
    .hidden {
      display: block;
    }
  }
}

.menuIcon {
  min-width: 10%;
}
