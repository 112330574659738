.loginEnter {
  width: 380px !important;
  height: 480px !important;
}

.loginUnverified {
  width: 380px !important;
}

.qrText {
  max-width: 105px;
}

@media screen and (min-width: 2700px) {
  .loginEnter {
    width: 480px !important;
    height: 580px !important;
  }
  .loginUnverified {
    width: 480px !important;
  }
}
