.historyTable {
  thead th {
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
  tbody {
    border-top: none !important;
  }
}
