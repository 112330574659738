.logout {
  margin-bottom: 0.25rem;
}

.navbar {
  padding: 3px !important;
}

.menu {
  display: flex !important;
}

.dropdownToggle {
  &:after {
    display: none !important;
  }
  i {
    font-size: 8px;
  }
}

.dropdownItem {
  margin-right: 0.75rem !important;
}
