@import 'assets/styles/typography';
@import './assets/styles/global';
@import 'assets/styles/icomoon.css';
@import 'node_modules/swiper/swiper.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-datepicker/dist/react-datepicker.css';

.w-40 {
  width: 40% !important;
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-95 {
  min-height: 95vh;
}

.form-control[id='email'] {
  border-radius: 0;
}

.form-control[id='password'] {
  border-radius: 0;
}

.form-control::-webkit-file-upload-button {
  color: black;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-header {
  box-shadow: 0px 10px 30px rgba(255, 129, 129, 0.08);
}

.customTab {
  background: #fff;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: var(--border);
  padding: 10px 30px;
  &.disabled {
    background: var(--background-l1);
    border: 1px solid var(--border);
  }
}

.maxWidth {
  max-width: 250px;
}

.text-inherit {
  color: inherit !important;
}
