$helvetica-regular: 'Helvetica';
$open-sans-light: 'Open Sans Light';
$open-sans-regular: 'Open Sans Regular';
$open-sans-medium: 'Open Sans Medium';
$open-sans-semibold: 'Open Sans SemiBold';
$open-sans-bold: 'Open Sans Bold';

@font-face {
  font-family: $open-sans-light;
  font-weight: 300;
  src: local($open-sans-light),
    url('/assets/fonts/OpenSans/OpenSans-Light.ttf?ppdprd') format('truetype');
}

@font-face {
  font-family: $open-sans-regular;
  font-weight: 400;
  src: local($open-sans-regular),
    url('/assets/fonts/OpenSans/OpenSans-Regular.ttf?ppdprd') format('truetype');
}

@font-face {
  font-family: $open-sans-medium;
  font-weight: 500;
  src: local($open-sans-medium),
    url('/assets/fonts/OpenSans/OpenSans-Medium.ttf?ppdprd') format('truetype');
}

@font-face {
  font-family: $open-sans-semibold;
  font-weight: 600;
  src: local($open-sans-semibold),
    url('/assets/fonts/OpenSans/OpenSans-SemiBold.ttf?ppdprd')
      format('truetype');
}

@font-face {
  font-family: $open-sans-bold;
  font-weight: 700;
  src: local($open-sans-bold),
    url('/assets/fonts/OpenSans/OpenSans-Bold.ttf?ppdprd') format('truetype');
}

@font-face {
  font-family: $helvetica-regular;
  font-weight: 400;
  src: local($helvetica-regular),
    url('/assets/fonts/Helvetica/Helvetica.ttf?ppdprd') format('truetype');
}
