.slide:global {
  min-height: 100% !important;

  > .swiper {
    width: 90%;
    height: 100%;

    .swiper-button-prev,
    .swiper-button-next {
      border-radius: 50%;
      width: 50px;
      height: 24px;
      display: inline-block;
      top: 100%;
      position: relative;
      &:after {
        display: none !important;
      }
    }

    .swiper-button-prev {
      margin-left: 42%;
      margin-right: 2em;
      transform: rotate(180deg);
    }

    @media only screen and (max-width: 600px) {
      .swiper-button-prev {
        margin-left: 25%;
      }
    }

    .swiper-pagination {
      display: inline-block;
      position: relative;
      width: auto;
      .swiper-pagination-bullet {
        margin: 0px 8px !important;
      }
    }
  }
}
