.uploadedDocument :global {
  overflow-x: hidden;
}

.uploadedDocument::-webkit-scrollbar {
  width: 0.5rem;
}

.uploadedDocument::-webkit-scrollbar-track {
  border-radius: 4px;
}

.uploadedDocument::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.uploadedDocument::-webkit-scrollbar-button {
  height: 1.5rem;
}

.numberPages {
  margin-right: calc(var(--bs-gutter-x) * 1);
}

.btnPage {
  background-color: transparent !important;
  &:hover {
    color: var(--bs-primary) !important;
  }
}
