@import 'core';

h1,
.h1 {
  font-size: $h1-font-size !important;
  line-height: 130% !important;
  font-weight: $font-weight-800 !important;
}

h2,
.h2 {
  font-size: $h2-font-size !important;
  line-height: 140% !important;
  font-weight: $font-weight-700 !important;
}

h3,
.h3 {
  font-size: $h3-font-size !important;
  line-height: 160% !important;
  font-weight: $font-weight-700 !important;
}

h4,
.h4 {
  font-size: $h4-font-size !important;
  line-height: 43.2px !important;
  font-weight: $font-weight-700 !important;
}

h5,
.h5 {
  font-size: $h5-font-size !important;
  line-height: 160% !important;
  font-weight: $font-weight-600 !important;
}

h6,
.h6 {
  font-size: $h6-font-size !important;
  line-height: 160% !important;
  font-weight: $font-weight-600 !important;
}

//TODO Findout why this code "@include media-breakpoint-down("sm")" doesn't work, meanwhile will use media scree, min and max width query

@media only screen and (max-width: 768px) {
  h1,
  .h1 {
    font-size: $h1-sm-font-size !important;
    line-height: 38.4px !important;
    font-weight: $font-weight-800 !important;
  }

  h2,
  .h2 {
    font-size: $h2-sm-font-size !important;
    line-height: 28.84px !important;
    font-weight: $font-weight-700 !important;
  }

  h3,
  .h3 {
    font-size: $h3-sm-font-size !important;
    line-height: 33.6px !important;
    font-weight: $font-weight-700 !important;
  }

  h4,
  .h4 {
    font-size: $h4-sm-font-size !important;
    line-height: 25.2px !important;
    font-weight: $font-weight-700 !important;
  }

  h5,
  .h5 {
    font-size: $h5-sm-font-size !important;
    line-height: 19.6px !important;
    font-weight: $font-weight-600 !important;
  }
}

.custom-fs-1 {
  font-size: $font-size-1 !important;
}

.custom-fs-2 {
  font-size: $font-size-2 !important;
}

.custom-fs-2-1 {
  font-size: $font-size-2-1 !important;
}

.custom-fs-3 {
  font-size: $font-size-3 !important;
}

.custom-fs-4 {
  font-size: $font-size-4 !important;
}

.custom-fs-5 {
  font-size: $font-size-5 !important;
}

.custom-fs-6 {
  font-size: $font-size-6 !important;
}

.custom-fs-7 {
  font-size: $font-size-7 !important;
}

.custom-fs-8 {
  font-size: $font-size-8 !important;
}

.semi-bold {
  font-weight: $font-weight-600 !important;
}

.font-weight-500 {
  font-weight: $font-weight-500 !important;
}

p {
  font-style: normal;
  line-height: 160%;
}

.p1-300 {
  font-weight: $font-weight-300;
  font-size: $font-size-1;
}

.p1-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-1;
}

.p1-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-1;
}

.p2-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-2;
}

.p2-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-2;
}

.p3-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-3;
}

.p3-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-3;
}

.p4-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-2-1;
}

.p4-600 {
  font-weight: $font-weight-600;
  font-size: $font-size-2-1;
}

.p5-400 {
  font-weight: $font-weight-400;
  font-size: $font-size-5;
}

.p5-700 {
  font-weight: $font-weight-700;
  font-size: $font-size-5;
}

.p6-700 {
  font-weight: $font-weight-700;
  font-size: $font-size-6;
}

#mainContainer {
  .container:first-child {
    min-height: calc(100vh - 350px);
  }
}
