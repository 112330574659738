.newOtp {
  letter-spacing: 0.5rem;
  .progressBar {
    left: 0;
    top: 0;
    width: 0px;
    height: 100%;
  }
}

.details {
  max-height: 550px;
  overflow: auto;
}
