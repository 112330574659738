@font-face {
  font-family: 'icomoon';
  src: url('../../assets/styles/fonts/icomoon/icomoon.eot?ppdprd');
  src: url('../../assets/styles/fonts/icomoon/icomoon.eot?ppdprd#iefix')
      format('embedded-opentype'),
    url('../../assets/styles/fonts/icomoon/icomoon.ttf?ppdprd')
      format('truetype'),
    url('../../assets/styles/fonts/icomoon/icomoon.svg?ppdprd#icomoon')
      format('svg'),
    url('../../assets/styles/fonts/icomoon/icomoon.woff?ppdprd') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-standart-search:before {
  content: '\e900';
}
.icon-attention:before {
  content: '\e901';
}
.icon-monitor:before {
  content: '\e902';
}
.icon-close-selection:before {
  content: '\e903';
}
.icon-settings-button:before {
  content: '\e904';
}
.icon-close-burger:before {
  content: '\e905';
}
.icon-download:before {
  content: '\e906';
}
.icon-edit:before {
  content: '\e907';
}
.icon-dollar-sign:before {
  content: '\e908';
}
.icon-folder:before {
  content: '\e909';
}
.icon-arrow-down:before {
  content: '\e90a';
}
.icon-all-tokens:before {
  content: '\e90b';
}
.icon-dashboard:before {
  content: '\e90c';
}
.icon-pay:before {
  content: '\e90d';
}
.icon-payment-processors:before {
  content: '\e90e';
}
.icon-service-providers:before {
  content: '\e90f';
}
.icon-tokens:before {
  content: '\e910';
}
.icon-logout:before {
  content: '\e911';
}
.icon-users:before {
  content: '\e912';
}
.icon-utilities:before {
  content: '\e913';
}
.icon-add:before {
  content: '\e914';
}
.icon-menu-toggle:before {
  content: '\e915';
}
.icon-menu-toggle2:before {
  content: '\e916';
}
.icon-download-key:before {
  content: '\e917';
}
.icon-small-arrow-down:before {
  content: '\e918';
}
.icon-small-arrow-up:before {
  content: '\e919';
}
.icon-sort:before {
  content: '\e91a';
}
.icon-filter:before {
  content: '\e91b';
}
.icon-clipboard:before {
  content: '\e91c';
}
.icon-reports:before {
  content: '\e91d';
}
.icon-advanced-search:before {
  content: '\e91e';
}
.icon-transaction-history:before {
  content: '\e91f';
}
.icon-view:before {
  content: '\e920';
}
.icon-hierarchy:before {
  content: '\e921';
}
.icon-arrow-hierarchy:before {
  content: '\e922';
}
.icon-trash:before {
  content: '\e923';
}
.icon-sun:before {
  content: '\e924';
}
.icon-moon:before {
  content: '\e925';
}
.icon-arrow-start:before {
  content: '\e926';
}
.icon-hierarchy-reverse:before {
  content: '\e927';
}
.icon-expand:before {
  content: '\e928';
}
.icon-arrow-left:before {
  content: '\e929';
}
.icon-plus:before {
  content: '\e92a';
}
.icon-increase-rotate:before {
  content: '\e92b';
}
.icon-decrease-rotate:before {
  content: '\e92c';
}
.icon-zoom-in:before {
  content: '\e92d';
}
.icon-refresh:before {
  content: '\e92e';
}
.icon-arrow-hook:before {
  content: '\e92f';
}
.icon-close:before {
  content: '\e930';
}
.icon-send-clock:before {
  content: '\e931';
}
.icon-home:before {
  content: '\e932';
}
.icon-delete:before {
  content: '\e933';
}
.icon-archive:before {
  content: '\e934';
}
.icon-eye:before {
  content: '\e935';
}
.icon-status-signed:before {
  content: '\e936';
}
.icon-status-pending:before {
  content: '\e937';
}
.icon-status-cancelled:before {
  content: '\e938';
}
.icon-upload-key:before {
  content: '\e939';
}
.icon-delete-fill:before {
  content: '\e93a';
}
.icon-chevron-down:before {
  content: '\e93b';
}
.icon-user:before {
  content: '\e93c';
}
.icon-clock:before {
  content: '\e93d';
}
.icon-zampass-account:before {
  content: '\e93e';
}
.icon-reset:before {
  content: '\e93f';
}
.icon-documents:before {
  content: '\e940';
}
.icon-profile:before {
  content: '\e941';
}
.icon-history:before {
  content: '\e942';
}
.icon-documents-archive:before {
  content: '\e943';
}
.icon-arrow-right:before {
  content: '\e944';
}
.icon-default-sort:before {
  content: '\e945';
}
.icon-arrow-down-sort:before {
  content: '\e946';
}
.icon-arrow-up-sort:before {
  content: '\e947';
}
.icon-cancel:before {
  content: '\e948';
}
.icon-restore:before {
  content: '\e949';
}
.icon-zoom-out:before {
  content: '\e94a';
}
.icon-add-fill:before {
  content: '\e94b';
}
.icon-idle-timer:before {
  content: '\e94c';
}
.icon-arrow-first:before {
  content: '\e94d';
}
.icon-arrow-last:before {
  content: '\e94e';
}
