// Do not edit directly
// Generated on Tue, 06 Aug 2024 10:36:55 GMT

$es-sizing-x320-sizing: 1280;
$es-sizing-x300-sizing: 1200;
$es-sizing-x274-sizing: 1096;
$es-sizing-x249-sizing: 996;
$es-sizing-x223-sizing: 892;
$es-sizing-x213-sizing: 852;
$es-sizing-x198-sizing: 792;
$es-sizing-x172-sizing: 688;
$es-sizing-x160-sizing: 640;
$es-sizing-x147-sizing: 588;
$es-sizing-x146-sizing: 584;
$es-sizing-x121-sizing: 484;
$es-sizing-x120-sizing: 480;
$es-sizing-x119-sizing: 476;
$es-sizing-x118-sizing: 472;
$es-sizing-x117-sizing: 468;
$es-sizing-x116-sizing: 464;
$es-sizing-x115-sizing: 460;
$es-sizing-x114-sizing: 456;
$es-sizing-x113-sizing: 452;
$es-sizing-x112-sizing: 448;
$es-sizing-x111-sizing: 444;
$es-sizing-x110-sizing: 440;
$es-sizing-x109-sizing: 436;
$es-sizing-x108-sizing: 432;
$es-sizing-x107-sizing: 428;
$es-sizing-x106-sizing: 424;
$es-sizing-x105-sizing: 420;
$es-sizing-x104-sizing: 416;
$es-sizing-x103-sizing: 412;
$es-sizing-x102-sizing: 408;
$es-sizing-x101-sizing: 404;
$es-sizing-x100-sizing: 400;
$es-sizing-x99-sizing: 396;
$es-sizing-x98-sizing: 392;
$es-sizing-x97-sizing: 388;
$es-sizing-x96-sizing: 384;
$es-sizing-x95-sizing: 380;
$es-sizing-x94-sizing: 376;
$es-sizing-x93-sizing: 372;
$es-sizing-x92-sizing: 368;
$es-sizing-x91-sizing: 364;
$es-sizing-x90-sizing: 360;
$es-sizing-x89-sizing: 356;
$es-sizing-x88-sizing: 352;
$es-sizing-x87-sizing: 348;
$es-sizing-x86-sizing: 344;
$es-sizing-x85-sizing: 340;
$es-sizing-x84-sizing: 336;
$es-sizing-x83-sizing: 332;
$es-sizing-x82-sizing: 328;
$es-sizing-x81-sizing: 324;
$es-sizing-x80-sizing: 320;
$es-sizing-x79-sizing: 316;
$es-sizing-x78-sizing: 312;
$es-sizing-x77-sizing: 308;
$es-sizing-x76-sizing: 304;
$es-sizing-x75-sizing: 300;
$es-sizing-x74-sizing: 296;
$es-sizing-x73-sizing: 292;
$es-sizing-x72-sizing: 288;
$es-sizing-x70-sizing: 280;
$es-sizing-x60-sizing: 240;
$es-sizing-x59-sizing: 236;
$es-sizing-x58-sizing: 232;
$es-sizing-x57-sizing: 228;
$es-sizing-x56-sizing: 224;
$es-sizing-x55-sizing: 220;
$es-sizing-x54-sizing: 216;
$es-sizing-x53-sizing: 212;
$es-sizing-x52-sizing: 208;
$es-sizing-x51-sizing: 204;
$es-sizing-x50-sizing: 200;
$es-sizing-x49-sizing: 196;
$es-sizing-x48-sizing: 192;
$es-sizing-x47-sizing: 188;
$es-sizing-x46-sizing: 184;
$es-sizing-x45-sizing: 180;
$es-sizing-x44-sizing: 176;
$es-sizing-x43-sizing: 172;
$es-sizing-x42-sizing: 168;
$es-sizing-x41-sizing: 164;
$es-sizing-x40-sizing: 160;
$es-sizing-x39-sizing: 156;
$es-sizing-x38-sizing: 152;
$es-sizing-x37-sizing: 148;
$es-sizing-x36-sizing: 144;
$es-sizing-x35-sizing: 140;
$es-sizing-x34-sizing: 136;
$es-sizing-x33-sizing: 132;
$es-sizing-x32-sizing: 128;
$es-sizing-x31-sizing: 124;
$es-sizing-x30-sizing: 120;
$es-sizing-x29-sizing: 116;
$es-sizing-x28-sizing: 112;
$es-sizing-x27-sizing: 108;
$es-sizing-x26-sizing: 104;
$es-sizing-x25-sizing: 100;
$es-sizing-x24-sizing: 96;
$es-sizing-x23-sizing: 92;
$es-sizing-x22-sizing: 88;
$es-sizing-x21-sizing: 84;
$es-sizing-x20-sizing: 80;
$es-sizing-x19-sizing: 76;
$es-sizing-x18-sizing: 72;
$es-sizing-x17-sizing: 68;
$es-sizing-x16-sizing: 64;
$es-sizing-x15-sizing: 60;
$es-sizing-x14-sizing: 56;
$es-sizing-x13-sizing: 52;
$es-sizing-x12-sizing: 48;
$es-sizing-x11-sizing: 44;
$es-sizing-x10-sizing: 40;
$es-sizing-x9-sizing: 36;
$es-sizing-x8-sizing: 32;
$es-sizing-x7-sizing: 28;
$es-sizing-x6-sizing: 24;
$es-sizing-22x025-sizing: 22;
$es-sizing-x5-sizing: 20;
$es-sizing-x4-sizing: 16;
$es-sizing-15x025-sizing: 15;
$es-sizing-14x025-sizing: 14;
$es-sizing-x3-sizing: 12;
$es-sizing-10x025-sizing: 10;
$es-sizing-x2-sizing: 8;
$es-sizing-7x025-sizing: 7;
$es-sizing-6x025-sizing: 6;
$es-sizing-5x025-sizing: 5;
$es-sizing-x1-sizing: 4;
$es-sizing-x075-sizing: 3;
$es-sizing-x05-sizing: 2;
$es-sizing-x025-sizing: 1;
$es-sizing-x0-sizing: 0;
$es-spacing-x025-spacing: 1;
$es-spacing-x05-spacing: 2;
$es-spacing-x1-spacing: 4;
$es-spacing-x2-spacing: 8;
$es-spacing-x3-spacing: 12;
$es-spacing-x4-spacing: 16;
$es-spacing-x5-spacing: 20;
$es-spacing-x6-spacing: 24;
$es-spacing-x7-spacing: 28;
$es-spacing-x8-spacing: 32;
$es-spacing-x9-spacing: 36;
$es-spacing-x10-spacing: 40;
$es-spacing-x11-spacing: 44;
$es-spacing-x12-spacing: 48;
$es-spacing-x13-spacing: 52;
$es-spacing-x14-spacing: 56;
$es-spacing-x15-spacing: 60;
$es-spacing-x16-spacing: 64;
$es-spacing-x17-spacing: 68;
$es-spacing-x18-spacing: 72;
$es-spacing-x19-spacing: 76;
$es-spacing-x20-spacing: 80;
$es-spacing-x21-spacing: 84;
$es-spacing-x22-spacing: 88;
$es-spacing-x23-spacing: 92;
$es-spacing-x24-spacing: 96;
$es-spacing-x25-spacing: 100;
$es-spacing-x30-spacing: 120;
$es-spacing-x35-spacing: 140;
$es-spacing-x40-spacing: 160;
$es-spacing-x45-spacing: 180;
$es-spacing-x50-spacing: 200;
$es-spacing-x55-spacing: 220;
$es-spacing-x60-spacing: 240;
$es-spacing-x65-spacing: 260;
$es-spacing-x70-spacing: 280;
$es-spacing-x75-spacing: 300;
$es-spacing-x80-spacing: 320;
$es-spacing-x90-spacing: 360;
$es-spacing-x95-spacing: 380;
$es-spacing-x121-spacing: 484;
$es-spacing-x146-spacing: 584;
$es-spacing-x160-spacing: 640;
$es-spacing-x172-spacing: 688;
$es-spacing-x198-spacing: 792;
$es-spacing-x223-spacing: 892;
$es-spacing-x249-spacing: 996;
$es-spacing-x274-spacing: 1096;
$es-spacing-x300-spacing: 1200;
$es-spacing-x320-spacing: 1280;
$es-color-neutral-f-20-color: rgba(255, 255, 255, 0.2);
$es-color-neutral-f-60-color: rgba(255, 255, 255, 0.6);
$es-color-neutral-f-color: #ffffff;
$es-color-neutral-100-color: #f7f7f7;
$es-color-neutral-150-color: #eff0f0;
$es-color-neutral-200-color: #ebebeb;
$es-color-neutral-250-color: #e5e5e6;
$es-color-neutral-300-color: #dddedf;
$es-color-neutral-350-color: #d5d6d8;
$es-color-neutral-400-color: #c1c2c3;
$es-color-neutral-500-color: #bdbdbd;
$es-color-neutral-520-color: #9a9a9a;
$es-color-neutral-550-color: #828282;
$es-color-neutral-600-color: #666666;
$es-color-neutral-620-color: #464749;
$es-color-neutral-650-color: #3c3d3e;
$es-color-neutral-660-color: #373839;
$es-color-neutral-666-color: #313335;
$es-color-neutral-670-color: #2f3032;
$es-color-neutral-700-color: #2c2e30;
$es-color-neutral-750-color: #292b2d;
$es-color-neutral-780-color: #242628;
$es-color-neutral-800-color: #1f2123;
$es-color-neutral-850-color: #1a1c1e;
$es-color-neutral-890-color: #121416;
$es-color-neutral-900-color: #000000;
$es-color-neutral-0-60-color: rgba(0, 0, 0, 0.6);
$es-color-neutral-0-50-color: rgba(0, 0, 0, 0.5);
$es-color-neutral-0-40-color: rgba(0, 0, 0, 0.4);
$es-color-neutral-0-35-color: rgba(0, 0, 0, 0.35);
$es-color-neutral-0-30-color: rgba(0, 0, 0, 0.3);
$es-color-neutral-0-25-color: rgba(0, 0, 0, 0.25);
$es-color-neutral-0-20-color: rgba(0, 0, 0, 0.2);
$es-color-neutral-0-15-color: rgba(0, 0, 0, 0.15);
$es-color-neutral-0-12-color: rgba(0, 0, 0, 0.12);
$es-color-neutral-0-10-color: rgba(0, 0, 0, 0.1);
$es-color-neutral-0-08-color: rgba(0, 0, 0, 0.08);
$es-color-neutral-0-05-color: rgba(0, 0, 0, 0.05);
$es-color-neutral-900-0-color: rgba(0, 0, 0, 0);
$es-color-green-100-color: rgba(46, 125, 50, 0.1);
$es-color-green-150-color: rgba(46, 125, 50, 0.2);
$es-color-green-200-color: rgba(46, 125, 50, 0.25);
$es-color-green-250-color: rgba(46, 125, 50, 0.4);
$es-color-green-270-color: rgba(46, 125, 50, 0.5);
$es-color-green-300-color: rgba(46, 125, 50, 0.7);
$es-color-green-340-color: rgba(129, 255, 141, 0.08);
$es-color-green-350-color: #83f105;
$es-color-green-400-color: #66bb6a;
$es-color-green-450-color: #388e3c;
$es-color-green-500-color: #2e7d32;
$es-color-green-550-color: #29702d;
$es-color-green-600-color: #1b5e20;
$es-color-green-650-color: #18531c;
$es-color-green-700-color: #144718;
$es-color-green-750-color: #123f16;
$es-color-green-800-color: #0f3412;
$es-color-green-850-color: #0b280d;
$es-color-green-900-color: #09200b;
$es-color-green-900-50-color: rgba(9, 32, 11, 0.5);
$es-color-green-950-color: #061407;
$es-color-red-dg-100-color: rgba(183, 41, 19, 0.08);
$es-color-red-dg-150-color: rgba(183, 41, 19, 0.2);
$es-color-red-dg-200-color: rgba(183, 41, 19, 0.3);
$es-color-red-dg-250-color: rgba(183, 41, 19, 0.4);
$es-color-red-dg-270-color: rgba(183, 41, 19, 0.5);
$es-color-red-dg-300-color: rgba(183, 41, 19, 0.7);
$es-color-red-dg-350-color: #eb7665;
$es-color-red-dg-400-color: #d5341c;
$es-color-red-dg-450-color: #bf5040;
$es-color-red-dg-500-color: #b72913;
$es-color-red-dg-550-color: #a72511;
$es-color-red-dg-600-color: #821d0d;
$es-color-red-dg-700-color: #5d1509;
$es-color-red-dg-800-color: #461007;
$es-color-red-dg-900-color: #250804;
$es-color-red-dg-900-40-color: rgba(37, 8, 4, 0.4);
$es-color-yellow-400-color: #ffcc33;
$es-color-yellow-500-color: #ffbf00;
$es-color-blue-100-color: rgba(15, 70, 146, 0.1);
$es-color-blue-150-color: rgba(15, 70, 146, 0.2);
$es-color-blue-200-color: rgba(15, 70, 146, 0.3);
$es-color-blue-250-color: rgba(15, 70, 146, 0.4);
$es-color-blue-300-color: rgba(15, 70, 146, 0.6);
$es-color-blue-350-color: rgba(15, 70, 146, 0.8);
$es-color-blue-400-color: #4982cf;
$es-color-blue-420-color: #5a78a5;
$es-color-blue-450-color: #2264bf;
$es-color-blue-500-color: #0f4692;
$es-color-blue-600-color: #173f7b;
$es-color-blue-700-color: #163761;
$es-color-blue-800-color: #112a4b;
$es-color-orange-80-color: #fffef1;
$es-color-orange-90-color: #ffd54f;
$es-color-orange-100-color: rgba(239, 108, 0, 0.1);
$es-color-orange-150-color: rgba(239, 108, 0, 0.2);
$es-color-orange-200-color: rgba(239, 108, 0, 0.3);
$es-color-orange-250-color: rgba(239, 108, 0, 0.4);
$es-color-orange-300-color: rgba(239, 108, 0, 0.7);
$es-color-orange-400-color: #ffa726;
$es-color-orange-420-color: #ff9137;
$es-color-orange-450-color: #fb8c00;
$es-color-orange-500-color: #ef6c00;
$es-color-orange-550-color: #e86612;
$es-color-orange-600-color: #e65100;
$es-color-orange-800-color: #b74b00;
$es-color-attention-400-color: #f28933;
$es-color-attention-500-color: #ef6c00;
$es-color-attention-50-color: rgba(239, 108, 0, 0.5);
$es-color-attention-600-color: #d96200;
$es-color-attention-30-color: rgba(255, 150, 63, 0.3);
$es-color-shades-100-color: #bedaff;
$es-color-shades-200-color: #6fa1d9;
$es-color-shades-300-color: #658dbb;
$es-color-shades-400-color: #557aa5;
$es-color-shades-500-color: #4b6f98;
$es-color-shades-50-color: rgba(75, 111, 152, 0.5);
$es-color-shades-700-color: #1c467d;
$es-color-shades-20-color: rgba(75, 111, 152, 0.2);
$es-color-success-300-color: #50c276;
$es-color-success-400-color: #58975b;
$es-color-success-500-color: #2e7d32;
$es-color-success-50-color: rgba(46, 125, 50, 0.5);
$es-color-success-30-color: rgba(46, 125, 50, 0.3);
$es-color-success-600-color: #2a722e;
$es-color-success-20-color: rgba(88, 151, 91, 0.2);
$es-color-error-300-color: #ff4d4d;
$es-color-error-400-color: #e24e36;
$es-color-error-500-color: #ff2706;
$es-color-error-50-color: rgba(255, 39, 6, 0.5);
$es-color-error-30-color: rgba(255, 39, 6, 0.3);
$es-color-error-20-color: rgba(255, 39, 6, 0.2);
$es-color-error-600-color: #ff3e3e;
$es-color-error-700-color: #aa2828;
$es-color-neutral-blue-0-color: #ffffff;
$es-color-neutral-blue-150-color: #f3f5f6;
$es-color-neutral-blue-160-color: #eceff1;
$es-color-neutral-blue-210-color: #cfd8dc;
$es-color-neutral-blue-400-color: #b9c4cb;
$es-color-neutral-blue-650-color: #b0bec5;
$es-color-neutral-blue-660-color: #90a4ae;
$es-color-neutral-blue-test-color: #c6d1d6;
$es-color-neutral-blue-666-color: #78909c;
$es-color-neutral-blue-670-color: #607d8b;
$es-color-neutral-blue-700-color: #546e7a;
$es-color-neutral-blue-750-color: #455a64;
$es-color-neutral-blue-780-color: #37474f;
$es-color-neutral-blue-800-color: #263238;
$es-color-neutral-blue-850-color: #14161a;
$es-color-neutral-blue-900-color: #111317;
$es-color-neutral-blue-0-60-color: rgba(255, 255, 255, 0.6);
$es-color-red-250-color: #da442c;
$es-color-red-350-color: #d5341c;
$es-color-red-400-color: #bf5040;
$es-color-red-550-color: #a72511;
$es-color-red-600-color: #821d0d;
$es-color-red-500-color: #b72913;
$es-border-radius-x0-border-radius: 0;
$es-border-radius-x05-border-radius: 2;
$es-border-radius-x1-border-radius: 4;
$es-border-radius-6x025-border-radius: 6;
$es-border-radius-x2-border-radius: 8;
$es-border-radius-10x025-border-radius: 10;
$es-border-radius-x3-border-radius: 12;
$es-border-radius-14x025-border-radius: 14;
$es-border-radius-x4-border-radius: 16;
$es-border-radius-x5-border-radius: 20;
$es-border-radius-x6-border-radius: 24;
$es-border-radius-x7-border-radius: 28;
$es-border-radius-x8-border-radius: 32;
$es-border-radius-x9-border-radius: 36;
$es-border-radius-x10-border-radius: 40;
$es-border-radius-x14-border-radius: 56;
$es-border-radius-x30-border-radius: 120;
$es-opasity-0-opasity: 0;
$es-opasity-05-opasity: 5;
$es-opasity-10-opasity: 10;
$es-opasity-15-opasity: 15;
$es-opasity-20-opasity: 20;
$es-opasity-25-opasity: 25;
$es-opasity-30-opasity: 30;
$es-opasity-35-opasity: 35;
$es-opasity-40-opasity: 40;
$es-opasity-45-opasity: 45;
$es-opasity-50-opasity: 50;
$es-opasity-55-opasity: 55;
$es-opasity-60-opasity: 60;
$es-opasity-65-opasity: 65;
$es-opasity-70-opasity: 70;
$es-opasity-75-opasity: 75;
$es-opasity-80-opasity: 80;
$es-opasity-85-opasity: 85;
$es-opasity-90-opasity: 90;
$es-opasity-95-opasity: 95;
$es-opasity-100-opasity: 100;
$es-typography-font-weight-light-font-weight: 300;
$es-typography-font-weight-regular-font-weight: 400;
$es-typography-font-weight-medium-font-weight: 500;
$es-typography-font-weight-semi-bold-font-weight: 600;
$es-typography-font-weight-bold-font-weight: 700;
$es-typography-font-weight-extra-bold-font-weight: 800;
$es-typography-line-height-xxxxs-line-height: 4;
$es-typography-line-height-xxxs-line-height: 8;
$es-typography-line-height-xxs-line-height: 12;
$es-typography-line-height-xs-line-height: 16;
$es-typography-line-height-s-line-height: 20;
$es-typography-line-height-m-line-height: 24;
$es-typography-line-height-l-line-height: 28;
$es-typography-line-height-xl-line-height: 32;
$es-typography-line-height-xxl-line-height: 36;
$es-typography-line-height-xxxl-line-height: 40;
$es-typography-line-height-xxxxl-line-height: 44;
$es-typography-line-height-5-xl-line-height: 48;
$es-typography-line-height-6-xl-line-height: 52;
$es-typography-line-height-7-xl-line-height: 56;
$es-typography-line-height-8-xl-line-height: 60;
$es-typography-line-height-9-xl-line-height: 64;
$es-typography-line-height-10-xl-line-height: 68;
$es-typography-line-height-11-xl-line-height: 72;
$es-typography-line-height-12-xl-line-height: 76;
$es-typography-line-height-13-xl-line-height: 80;
$es-typography-line-height-14-xl-line-height: 84;
$es-typography-line-height-15-xl-line-height: 88;
$es-typography-line-height-16-xl-line-height: 92;
$es-typography-font-size-10px-font-size: 10;
$es-typography-font-size-11px-font-size: 11;
$es-typography-font-size-12px-font-size: 12;
$es-typography-font-size-13px-font-size: 13;
$es-typography-font-size-14px-font-size: 14;
$es-typography-font-size-15px-font-size: 15;
$es-typography-font-size-16px-font-size: 16;
$es-typography-font-size-17px-font-size: 17;
$es-typography-font-size-18px-font-size: 18;
$es-typography-font-size-19px-font-size: 19;
$es-typography-font-size-20px-font-size: 20;
$es-typography-font-size-21px-font-size: 21;
$es-typography-font-size-22px-font-size: 22;
$es-typography-font-size-23px-font-size: 23;
$es-typography-font-size-24px-font-size: 24;
$es-typography-font-size-28px-font-size: 28;
$es-typography-font-size-32px-font-size: 32;
$es-typography-font-size-36px-font-size: 36;
$es-typography-font-size-40px-font-size: 40;
$es-typography-font-size-42px-font-size: 42;
$es-typography-font-size-44px-font-size: 44;
$es-typography-font-size-48px-font-size: 48;
$es-typography-font-size-52px-font-size: 52;
$es-typography-font-size-56px-font-size: 56;
$es-typography-font-size-60px-font-size: 60;
$es-typography-font-size-64px-font-size: 64;
$es-controls-status-medium-gap-s-spacing: 4;
$es-controls-status-medium-gap-m-spacing: 8;
$es-controls-status-medium-indicator-height-xxxs-sizing: 8;
$es-controls-status-medium-indicator-weight-xxxs-sizing: 8;
$es-controls-status-medium-indicator-height-xxs-sizing: 12;
$es-controls-status-medium-indicator-weight-xxs-sizing: 12;
$es-controls-status-medium-indicator-height-xs-sizing: 16;
$es-controls-status-medium-indicator-weight-xs-sizing: 16;
$es-controls-status-medium-indicator-height-s-sizing: 20;
$es-controls-status-medium-indicator-weight-s-sizing: 20;
$es-controls-status-medium-indicator-height-m-sizing: 24;
$es-controls-status-medium-indicator-weight-m-sizing: 24;
$es-controls-status-medium-indicator-height-l-sizing: 32;
$es-controls-status-medium-indicator-weight-l-sizing: 32;
$es-controls-status-medium-indicator-height-xl-sizing: 40;
$es-controls-status-medium-indicator-weight-xl-sizing: 40;
$es-controls-status-medium-indicator-all-xxxs-border-radius: 4;
$es-controls-status-medium-indicator-all-xxs-border-radius: 6;
$es-controls-status-medium-indicator-all-xs-border-radius: 8;
$es-controls-status-medium-indicator-all-s-border-radius: 10;
$es-controls-status-medium-indicator-all-m-border-radius: 12;
$es-controls-status-medium-indicator-all-l-border-radius: 16;
$es-controls-status-medium-indicator-all-xl-border-radius: 20;
$es-controls-status-states-default-icon-color: #ffffff;
$es-controls-status-states-default-border-color: #ffffff;
$es-controls-status-states-default-text-succes-color: #58975b;
$es-controls-status-states-default-text-shades-color: #4b6f98;
$es-controls-status-states-default-text-gray-strong-color: #828282;
$es-controls-status-states-default-text-attention-color: #ef6c00;
$es-controls-status-states-default-text-error-color: #ff4d4d;
$es-controls-status-states-default-text-gray-color: #c1c2c3;
$es-controls-status-states-default-background-succes-color: #58975b;
$es-controls-status-states-default-background-shades-color: #4b6f98;
$es-controls-status-states-default-background-gray-strong-color: #828282;
$es-controls-status-states-default-background-attention-color: #ef6c00;
$es-controls-status-states-default-background-error-color: #ff4d4d;
$es-controls-status-states-default-background-gray-color: #d5d6d8;
$es-controls-status-stroke-m-border-width: 1;
$es-controls-status-stroke-l-border-width: 2;
$es-controls-divider-states-default-background-primary-color: #b72913;
$es-controls-divider-states-default-background-secondary-color: #0f4692;
$es-controls-divider-states-default-background-on-brand-color: #ffffff;
$es-controls-divider-states-default-background-gray-tint-color: #eff0f0;
$es-controls-divider-states-default-background-gray-l-ight-color: #d5d6d8;
$es-controls-divider-states-default-background-gray-color: #c1c2c3;
$es-controls-divider-states-default-background-gray-strong-color: #828282;
$es-controls-divider-states-default-background-gray-dark-color: #464749;
$es-controls-divider-states-default-background-main-lvl2-color: #f7f7f7;
$es-controls-divider-states-default-background-error-color: #ff2706;
$es-controls-divider-states-default-background-success-color: #58975b;
$es-controls-divider-states-default-background-attention-color: #ef6c00;
$es-controls-divider-states-default-background-shades-color: #4b6f98;
$es-controls-divider-states-default-text-gray-tint-color: #eff0f0;
$es-controls-divider-states-default-text-gray-light-color: #d5d6d8;
$es-controls-divider-states-default-text-gray-color: #c1c2c3;
$es-controls-divider-states-default-text-gray-strong-color: #828282;
$es-controls-divider-states-default-text-gray-dark-color: #464749;
$es-controls-divider-medium-width-s-sizing: 1;
$es-controls-divider-medium-height-s-sizing: 1;
$es-controls-divider-medium-width-m-sizing: 2;
$es-controls-divider-medium-height-m-sizing: 2;
$es-controls-divider-medium-width-l-sizing: 4;
$es-controls-divider-medium-height-l-sizing: 4;
$es-controls-divider-medium-top-xs-spacing: 2;
$es-controls-divider-medium-top-s-spacing: 4;
$es-controls-divider-medium-top-m-spacing: 8;
$es-controls-divider-medium-top-l-spacing: 12;
$es-controls-divider-medium-top-xl-spacing: 16;
$es-controls-divider-medium-bottom-xs-spacing: 2;
$es-controls-divider-medium-bottom-s-spacing: 4;
$es-controls-divider-medium-bottom-m-spacing: 8;
$es-controls-divider-medium-bottom-l-spacing: 12;
$es-controls-divider-medium-bottom-xl-spacing: 16;
$es-controls-divider-medium-left-xs-spacing: 2;
$es-controls-divider-medium-left-s-spacing: 4;
$es-controls-divider-medium-left-m-spacing: 8;
$es-controls-divider-medium-left-l-spacing: 12;
$es-controls-divider-medium-left-xl-spacing: 16;
$es-controls-divider-medium-right-xs-spacing: 2;
$es-controls-divider-medium-right-s-spacing: 4;
$es-controls-divider-medium-right-m-spacing: 8;
$es-controls-divider-medium-right-l-spacing: 12;
$es-controls-divider-medium-right-xl-spacing: 16;
$es-controls-divider-medium-gap-horizontal-s-spacing: 8;
$es-controls-divider-medium-gap-horizontal-m-spacing: 12;
$es-controls-divider-medium-gap-horizontal-l-spacing: 16;
$es-controls-divider-medium-gap-vertical-s-spacing: 8;
$es-controls-divider-medium-gap-vertical-m-spacing: 12;
$es-controls-divider-medium-gap-vertical-l-spacing: 16;
$es-controls-calendar-medium-left-xxxs-spacing: 4;
$es-controls-calendar-medium-left-xxs-spacing: 8;
$es-controls-calendar-medium-left-xs-spacing: 12;
$es-controls-calendar-medium-right-xxxs-spacing: 4;
$es-controls-calendar-medium-right-xxs-spacing: 8;
$es-controls-calendar-medium-right-xs-spacing: 12;
$es-controls-calendar-medium-gap-horizontal-s-spacing: 4;
$es-controls-calendar-medium-gap-vertical-s-spacing: 4;
$es-controls-calendar-medium-gap-horizontal-m-spacing: 8;
$es-controls-calendar-medium-gap-vertical-m-spacing: 8;
$es-controls-calendar-medium-top-s-spacing: 4;
$es-controls-calendar-medium-top-m-spacing: 8;
$es-controls-calendar-medium-top-l-spacing: 12;
$es-controls-calendar-medium-bottom-s-spacing: 4;
$es-controls-calendar-medium-bottom-m-spacing: 8;
$es-controls-calendar-medium-bottom-l-spacing: 12;
$es-controls-calendar-divider-m-border-width: 1;
$es-controls-calendar-states-container-default-background-primary-color: #ffffff;
$es-controls-calendar-states-container-default-background-secondary-color: #f7f7f7;
$es-controls-calendar-states-container-default-text-color: #1a1c1e;
$es-controls-calendar-states-container-default-divider-color: #ebebeb;
$es-controls-calendar-states-container-select-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-calendar-states-container-select-background-secondary-color: #eff0f0;
$es-controls-calendar-states-container-select-text-color: #1a1c1e;
$es-controls-calendar-states-container-select-divider-color: rgba(0, 0, 0, 0);
$es-controls-calendar-states-item-default-background-color: rgba(0, 0, 0, 0);
$es-controls-calendar-states-item-default-background-secondary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-calendar-states-item-default-text-color: #1a1c1e;
$es-controls-calendar-states-item-default-border-color: rgba(0, 0, 0, 0);
$es-controls-calendar-states-item-hover-background-color: #eff0f0;
$es-controls-calendar-states-item-hover-background-secondary-color: #eff0f0;
$es-controls-calendar-states-item-hover-text-color: #1a1c1e;
$es-controls-calendar-states-item-hover-border-color: rgba(0, 0, 0, 0);
$es-controls-calendar-states-item-active-background-color: #e5e5e6;
$es-controls-calendar-states-item-active-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-calendar-states-item-active-text-color: #1a1c1e;
$es-controls-calendar-states-item-active-border-color: rgba(0, 0, 0, 0);
$es-controls-calendar-states-item-select-background-color: #b72913;
$es-controls-calendar-states-item-select-background-secondary-color: #b72913;
$es-controls-calendar-states-item-select-text-color: #ffffff;
$es-controls-calendar-states-item-select-border-color: rgba(0, 0, 0, 0);
$es-controls-calendar-states-item-disabled-background-color: #ffffff;
$es-controls-calendar-states-item-disabled-background-secondary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-calendar-states-item-disabled-text-color: #d5d6d8;
$es-controls-calendar-states-item-disabled-border-color: rgba(0, 0, 0, 0);
$es-controls-dropdown-divider-m-border-width: 2;
$es-controls-dropdown-small-top-m-spacing: 4;
$es-controls-dropdown-small-bottom-m-spacing: 4;
$es-controls-dropdown-small-left-m-spacing: 16;
$es-controls-dropdown-small-right-m-spacing: 16;
$es-controls-dropdown-small-right-xs-spacing: 8;
$es-controls-dropdown-small-gap-vertical-s-spacing: 2;
$es-controls-dropdown-small-gap-vertical-m-spacing: 4;
$es-controls-dropdown-small-gap-horizontal-m-spacing: 8;
$es-controls-dropdown-small-all-m-border-radius: 8;
$es-controls-dropdown-small-height-m-sizing: 32;
$es-controls-dropdown-medium-right-xs-spacing: 8;
$es-controls-dropdown-medium-top-m-spacing: 12;
$es-controls-dropdown-medium-top-s-spacing: 8;
$es-controls-dropdown-medium-bottom-m-spacing: 12;
$es-controls-dropdown-medium-bottom-s-spacing: 8;
$es-controls-dropdown-medium-left-m-spacing: 16;
$es-controls-dropdown-medium-right-m-spacing: 16;
$es-controls-dropdown-medium-gap-vertical-m-spacing: 4;
$es-controls-dropdown-medium-gap-horizontal-m-spacing: 8;
$es-controls-dropdown-medium-all-m-border-radius: 8;
$es-controls-dropdown-medium-height-m-sizing: 44;
$es-controls-dropdown-large-right-xs-spacing: 8;
$es-controls-dropdown-large-top-m-spacing: 16;
$es-controls-dropdown-large-bottom-m-spacing: 16;
$es-controls-dropdown-large-left-m-spacing: 16;
$es-controls-dropdown-large-right-m-spacing: 16;
$es-controls-dropdown-large-left-l-spacing: 40;
$es-controls-dropdown-large-right-l-spacing: 40;
$es-controls-dropdown-large-gap-vertical-m-spacing: 8;
$es-controls-dropdown-large-gap-horizontal-m-spacing: 8;
$es-controls-dropdown-large-all-m-border-radius: 8;
$es-controls-dropdown-large-height-m-sizing: 56;
$es-controls-dropdown-states-default-background-primary-color: #ffffff;
$es-controls-dropdown-states-default-background-secondary-color: #f7f7f7;
$es-controls-dropdown-states-default-icon-primary-color: #b72913;
$es-controls-dropdown-states-default-icon-secondary-color: #828282;
$es-controls-dropdown-states-default-divider-color: #d5d6d8;
$es-controls-dropdown-states-default-line-color: #ffffff;
$es-controls-dropdown-states-default-text-primary-color: #1a1c1e;
$es-controls-dropdown-states-default-text-secondary-color: #828282;
$es-controls-dropdown-states-hover-background-primary-color: #eff0f0;
$es-controls-dropdown-states-hover-background-secondary-color: #ebebeb;
$es-controls-dropdown-states-hover-icon-primary-color: #d5341c;
$es-controls-dropdown-states-hover-icon-secondary-color: #1f2123;
$es-controls-dropdown-states-hover-divider-color: #d5d6d8;
$es-controls-dropdown-states-hover-line-color: #eff0f0;
$es-controls-dropdown-states-hover-text-primary-color: #1a1c1e;
$es-controls-dropdown-states-hover-text-secondary-color: #828282;
$es-controls-dropdown-states-focused-background-primary-color: #b72913;
$es-controls-dropdown-states-focused-background-secondary-color: #b72913;
$es-controls-dropdown-states-focused-icon-primary-color: #ffffff;
$es-controls-dropdown-states-focused-icon-secondary-color: #ffffff;
$es-controls-dropdown-states-focused-divider-color: #d5d6d8;
$es-controls-dropdown-states-focused-line-color: #eff0f0;
$es-controls-dropdown-states-focused-text-primary-color: #ffffff;
$es-controls-dropdown-states-focused-text-secondary-color: #ffffff;
$es-controls-dropdown-states-selected-background-primary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-dropdown-states-selected-background-secondary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-dropdown-states-selected-icon-primary-color: #d5341c;
$es-controls-dropdown-states-selected-icon-secondary-color: #1f2123;
$es-controls-dropdown-states-selected-divider-color: #d5d6d8;
$es-controls-dropdown-states-selected-line-color: #b72913;
$es-controls-dropdown-states-selected-text-primary-color: #1a1c1e;
$es-controls-dropdown-states-selected-text-secondary-color: #464749;
$es-controls-dropdown-states-active-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-dropdown-states-active-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-dropdown-states-active-icon-primary-color: #d5341c;
$es-controls-dropdown-states-active-icon-secondary-color: #1f2123;
$es-controls-dropdown-states-active-divider-color: #d5d6d8;
$es-controls-dropdown-states-active-line-color: #b72913;
$es-controls-dropdown-states-active-text-primary-color: #1a1c1e;
$es-controls-dropdown-states-active-text-secondary-color: #464749;
$es-controls-dropdown-states-active-hover-background-primary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-dropdown-states-active-hover-background-secondary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-dropdown-states-active-hover-icon-primary-color: #d5341c;
$es-controls-dropdown-states-active-hover-icon-secondary-color: #1f2123;
$es-controls-dropdown-states-active-hover-divider-color: #d5d6d8;
$es-controls-dropdown-states-active-hover-line-color: #b72913;
$es-controls-dropdown-states-active-hover-text-primary-color: #1a1c1e;
$es-controls-dropdown-states-active-hover-text-secondary-color: #464749;
$es-controls-dropdown-states-dragged-background-primary-color: #eff0f0;
$es-controls-dropdown-states-dragged-background-secondary-color: #ebebeb;
$es-controls-dropdown-states-dragged-icon-primary-color: #b72913;
$es-controls-dropdown-states-dragged-icon-secondary-color: #464749;
$es-controls-dropdown-states-dragged-divider-color: #d5d6d8;
$es-controls-dropdown-states-dragged-line-color: #ffffff;
$es-controls-dropdown-states-dragged-text-primary-color: #1a1c1e;
$es-controls-dropdown-states-dragged-text-secondary-color: #828282;
$es-controls-dropdown-states-disabled-background-primary-color: #f7f7f7;
$es-controls-dropdown-states-disabled-background-secondary-color: #f7f7f7;
$es-controls-dropdown-states-disabled-icon-primary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-dropdown-states-disabled-icon-secondary-color: #c1c2c3;
$es-controls-dropdown-states-disabled-divider-color: #d5d6d8;
$es-controls-dropdown-states-disabled-line-color: #eff0f0;
$es-controls-dropdown-states-disabled-text-secondary-color: #c1c2c3;
$es-controls-dropdown-states-disabled-text-primary-color: #c1c2c3;
$es-controls-link-stroke-m-border-width: 1;
$es-controls-link-states-default-text-primary-color: #4b6f98;
$es-controls-link-states-default-text-primary-on-dark-color: #6fa1d9;
$es-controls-link-states-default-text-secondary-color: #0f4692;
$es-controls-link-states-default-text-secondary-on-dark-color: #4982cf;
$es-controls-link-states-default-text-attention-color: #ff4d4d;
$es-controls-link-states-default-text-brand-color: #b72913;
$es-controls-link-states-default-text-on-bg-primary-color: #1a1c1e;
$es-controls-link-states-default-text-on-bg-secondary-color: #1a1c1e;
$es-controls-link-states-default-icon-primary-color: #b72913;
$es-controls-link-states-default-icon-gray-color: #828282;
$es-controls-link-states-default-icon-on-dark-color: #ffffff;
$es-controls-link-states-default-stroke-focus-color: rgba(0, 0, 0, 0);
$es-controls-link-states-default-stroke-focus-on-dark-color: rgba(0, 0, 0, 0);
$es-controls-link-states-default-background-primary-color: rgba(0, 0, 0, 0);
$es-controls-link-states-default-background-secondary-color: #e5e5e6;
$es-controls-link-states-hover-text-primary-color: #1a1c1e;
$es-controls-link-states-hover-text-primary-on-dark-color: #ffffff;
$es-controls-link-states-hover-text-secondary-color: #1a1c1e;
$es-controls-link-states-hover-text-secondary-on-dark-color: #ffffff;
$es-controls-link-states-hover-text-attention-color: #ffffff;
$es-controls-link-states-hover-text-brand-color: #1a1c1e;
$es-controls-link-states-hover-text-on-bg-primary-color: #1a1c1e;
$es-controls-link-states-hover-text-on-bg-secondary-color: #1a1c1e;
$es-controls-link-states-hover-icon-primary-color: #b72913;
$es-controls-link-states-hover-icon-gray-color: #1f2123;
$es-controls-link-states-hover-icon-on-dark-color: #ffffff;
$es-controls-link-states-hover-stroke-focus-color: rgba(0, 0, 0, 0);
$es-controls-link-states-hover-stroke-focus-on-dark-color: rgba(0, 0, 0, 0);
$es-controls-link-states-hover-background-primary-color: #eff0f0;
$es-controls-link-states-hover-background-secondary-color: #ffffff;
$es-controls-link-states-focus-text-primary-color: #1a1c1e;
$es-controls-link-states-focus-text-primary-on-dark-color: #ffffff;
$es-controls-link-states-focus-text-secondary-color: #1a1c1e;
$es-controls-link-states-focus-text-secondary-on-dark-color: #ffffff;
$es-controls-link-states-focus-text-attention-color: #ffffff;
$es-controls-link-states-focus-text-brand-color: #1a1c1e;
$es-controls-link-states-focus-text-on-bg-primary-color: #1a1c1e;
$es-controls-link-states-focus-text-on-bg-secondary-color: #1a1c1e;
$es-controls-link-states-focus-icon-primary-color: #b72913;
$es-controls-link-states-focus-icon-gray-color: #b72913;
$es-controls-link-states-focus-icon-on-dark-color: #ffffff;
$es-controls-link-states-focus-stroke-focus-color: #1a1c1e;
$es-controls-link-states-focus-stroke-focus-on-dark-color: #ffffff;
$es-controls-link-states-focus-background-primary-color: #d5d6d8;
$es-controls-link-states-focus-background-secondary-color: #d5d6d8;
$es-controls-link-states-active-text-primary-color: #b72913;
$es-controls-link-states-active-text-primary-on-dark-color: #d5341c;
$es-controls-link-states-active-text-secondary-color: #b72913;
$es-controls-link-states-active-text-secondary-on-dark-color: #d5341c;
$es-controls-link-states-active-text-attention-color: #d5341c;
$es-controls-link-states-active-text-brand-color: #b72913;
$es-controls-link-states-active-text-on-bg-primary-color: #1a1c1e;
$es-controls-link-states-active-text-on-bg-secondary-color: #ffffff;
$es-controls-link-states-active-icon-primary-color: #b72913;
$es-controls-link-states-active-icon-gray-color: #b72913;
$es-controls-link-states-active-icon-on-dark-color: #eb7665;
$es-controls-link-states-active-stroke-focus-color: rgba(0, 0, 0, 0);
$es-controls-link-states-active-stroke-focus-on-dark-color: rgba(0, 0, 0, 0);
$es-controls-link-states-active-background-primary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-link-states-active-background-secondary-color: #313335;
$es-controls-link-states-disabled-text-primary-color: #c1c2c3;
$es-controls-link-states-disabled-text-primary-on-dark-color: #828282;
$es-controls-link-states-disabled-text-secondary-color: #c1c2c3;
$es-controls-link-states-disabled-text-secondary-on-dark-color: #828282;
$es-controls-link-states-disabled-text-attention-color: #c1c2c3;
$es-controls-link-states-disabled-text-brand-color: #c1c2c3;
$es-controls-link-states-disabled-text-on-bg-primary-color: #c1c2c3;
$es-controls-link-states-disabled-text-on-bg-secondary-color: #828282;
$es-controls-link-states-disabled-icon-primary-color: #c1c2c3;
$es-controls-link-states-disabled-icon-gray-color: #c1c2c3;
$es-controls-link-states-disabled-icon-on-dark-color: #c1c2c3;
$es-controls-link-states-disabled-stroke-focus-color: rgba(0, 0, 0, 0);
$es-controls-link-states-disabled-stroke-focus-on-dark-color: rgba(0, 0, 0, 0);
$es-controls-link-states-disabled-background-primary-color: rgba(0, 0, 0, 0);
$es-controls-link-states-disabled-background-secondary-color: #e5e5e6;
$es-controls-link-small-gap-horizontal-xs-spacing: 2;
$es-controls-link-small-gap-horizontal-s-spacing: 4;
$es-controls-link-small-all-m-border-radius: 4;
$es-controls-link-small-gap-horizontal-m-spacing: 8;
$es-controls-link-small-gap-vertical-xs-spacing: 2;
$es-controls-link-small-gap-vertical-s-spacing: 4;
$es-controls-link-small-gap-vertical-m-spacing: 8;
$es-controls-link-small-left-m-spacing: 4;
$es-controls-link-small-left-l-spacing: 8;
$es-controls-link-small-right-m-spacing: 4;
$es-controls-link-small-right-l-spacing: 8;
$es-controls-link-small-top-m-spacing: 4;
$es-controls-link-small-bottom-m-spacing: 4;
$es-controls-link-small-top-l-spacing: 8;
$es-controls-link-small-bottom-l-spacing: 8;
$es-controls-link-small-height-m-sizing: 28;
$es-controls-link-all-m-border-radius: 4;
$es-controls-link-medium-height-m-sizing: 36;
$es-controls-link-medium-height-s-sizing: 32;
$es-controls-link-medium-gap-horizontal-xs-spacing: 2;
$es-controls-link-medium-gap-horizontal-s-spacing: 4;
$es-controls-link-medium-all-m-border-radius: 4;
$es-controls-link-medium-gap-horizontal-m-spacing: 8;
$es-controls-link-medium-gap-vertical-xs-spacing: 2;
$es-controls-link-medium-gap-vertical-s-spacing: 4;
$es-controls-link-medium-gap-vertical-m-spacing: 8;
$es-controls-link-medium-left-m-spacing: 4;
$es-controls-link-medium-left-xl-spacing: 12;
$es-controls-link-medium-left-xxl-spacing: 16;
$es-controls-link-medium-left-l-spacing: 8;
$es-controls-link-medium-right-m-spacing: 4;
$es-controls-link-medium-right-l-spacing: 8;
$es-controls-link-medium-right-xl-spacing: 12;
$es-controls-link-medium-right-xxl-spacing: 16;
$es-controls-link-medium-top-m-spacing: 4;
$es-controls-link-medium-bottom-m-spacing: 4;
$es-controls-link-medium-top-l-spacing: 8;
$es-controls-link-medium-bottom-l-spacing: 8;
$es-controls-link-large-height-m-sizing: 40;
$es-controls-link-large-height-s-sizing: 32;
$es-controls-link-large-gap-horizontal-xs-spacing: 2;
$es-controls-link-large-gap-horizontal-s-spacing: 4;
$es-controls-link-large-all-m-border-radius: 4;
$es-controls-link-large-gap-horizontal-m-spacing: 8;
$es-controls-link-large-gap-vertical-xs-spacing: 2;
$es-controls-link-large-gap-vertical-s-spacing: 4;
$es-controls-link-large-gap-vertical-m-spacing: 8;
$es-controls-link-large-left-m-spacing: 4;
$es-controls-link-large-left-xl-spacing: 12;
$es-controls-link-large-left-xxl-spacing: 16;
$es-controls-link-large-left-l-spacing: 8;
$es-controls-link-large-right-m-spacing: 4;
$es-controls-link-large-right-l-spacing: 8;
$es-controls-link-large-right-xl-spacing: 12;
$es-controls-link-large-right-xxl-spacing: 16;
$es-controls-link-large-top-m-spacing: 4;
$es-controls-link-large-bottom-m-spacing: 4;
$es-controls-link-large-top-l-spacing: 8;
$es-controls-link-large-bottom-l-spacing: 8;
$es-controls-splitter-stroke-m-border-width: 1;
$es-controls-splitter-stroke-l-border-width: 2;
$es-controls-splitter-medium-vertical-track-width-m-sizing: 3;
$es-controls-splitter-medium-vertical-thumb-width-m-sizing: 3;
$es-controls-splitter-medium-vertical-thumb-height-m-sizing: 32;
$es-controls-splitter-medium-horizontal-track-height-m-sizing: 3;
$es-controls-splitter-medium-horizontal-thumb-width-m-sizing: 32;
$es-controls-splitter-medium-horizontal-thumb-height-m-sizing: 3;
$es-controls-splitter-states-default-thumb-primary-color: #c1c2c3;
$es-controls-splitter-states-default-thumb-brand-color: #b72913;
$es-controls-splitter-states-default-track-secondary-color: #eff0f0;
$es-controls-splitter-states-default-track-primary-color: #ffffff;
$es-controls-splitter-states-dragged-track-primary-color: #b72913;
$es-controls-splitter-states-dragged-track-secondary-color: #b72913;
$es-controls-splitter-states-dragged-thumb-primary-color: #828282;
$es-controls-splitter-states-dragged-thumb-brand-color: #b72913;
$es-controls-transfer-stroke-m-border-width: 1;
$es-controls-transfer-stroke-l-border-width: 2;
$es-controls-transfer-medium-gap-horizontal-xs-spacing: 2;
$es-controls-transfer-medium-gap-horizontal-s-spacing: 4;
$es-controls-transfer-medium-gap-horizontal-m-spacing: 8;
$es-controls-transfer-medium-gap-vertical-xs-spacing: 2;
$es-controls-transfer-medium-gap-vertical-s-spacing: 4;
$es-controls-transfer-medium-gap-vertical-m-spacing: 8;
$es-controls-transfer-medium-left-m-spacing: 4;
$es-controls-transfer-medium-left-l-spacing: 8;
$es-controls-transfer-medium-right-l-spacing: 8;
$es-controls-transfer-medium-top-m-spacing: 12;
$es-controls-transfer-medium-bottom-m-spacing: 12;
$es-controls-transfer-medium-all-m-border-radius: 4;
$es-controls-transfer-states-default-icon-color: #ffffff;
$es-controls-transfer-states-default-border-color: #ffffff;
$es-controls-transfer-states-default-text-primary-color: #1a1c1e;
$es-controls-transfer-states-default-text-secondary-color: #828282;
$es-controls-transfer-states-default-background-primary-color: #e5e5e6;
$es-controls-menu-stroke-m-border-width: 2;
$es-controls-menu-small-top-m-spacing: 4;
$es-controls-menu-small-bottom-m-spacing: 4;
$es-controls-menu-small-left-m-spacing: 16;
$es-controls-menu-small-right-m-spacing: 16;
$es-controls-menu-small-right-xs-spacing: 8;
$es-controls-menu-small-gap-vertical-m-spacing: 4;
$es-controls-menu-small-gap-horizontal-m-spacing: 8;
$es-controls-menu-small-all-m-border-radius: 8;
$es-controls-menu-small-height-m-sizing: 28;
$es-controls-menu-medium-top-m-spacing: 12;
$es-controls-menu-medium-top-s-spacing: 8;
$es-controls-menu-medium-bottom-m-spacing: 12;
$es-controls-menu-medium-bottom-s-spacing: 8;
$es-controls-menu-medium-left-m-spacing: 16;
$es-controls-menu-medium-left-s-spacing: 12;
$es-controls-menu-medium-left-xs-spacing: 8;
$es-controls-menu-medium-right-m-spacing: 16;
$es-controls-menu-medium-right-s-spacing: 12;
$es-controls-menu-medium-right-xs-spacing: 8;
$es-controls-menu-medium-gap-vertical-m-spacing: 4;
$es-controls-menu-medium-gap-horizontal-m-spacing: 8;
$es-controls-menu-medium-all-m-border-radius: 8;
$es-controls-menu-medium-height-m-sizing: 44;
$es-controls-menu-large-right-xs-spacing: 8;
$es-controls-menu-large-top-m-spacing: 16;
$es-controls-menu-large-bottom-m-spacing: 16;
$es-controls-menu-large-left-m-spacing: 16;
$es-controls-menu-large-right-m-spacing: 16;
$es-controls-menu-large-left-l-spacing: 40;
$es-controls-menu-large-right-l-spacing: 40;
$es-controls-menu-large-gap-vertical-m-spacing: 8;
$es-controls-menu-large-gap-horizontal-m-spacing: 8;
$es-controls-menu-large-all-m-border-radius: 8;
$es-controls-menu-large-height-m-sizing: 56;
$es-controls-menu-states-default-background-primary-color: #ffffff;
$es-controls-menu-states-default-background-secondary-color: #f7f7f7;
$es-controls-menu-states-default-icon-primary-color: #b72913;
$es-controls-menu-states-default-icon-secondary-color: #464749;
$es-controls-menu-states-default-divider-color: #d5d6d8;
$es-controls-menu-states-default-line-color: #ffffff;
$es-controls-menu-states-default-text-primary-color: #1a1c1e;
$es-controls-menu-states-default-text-secondary-color: #828282;
$es-controls-menu-states-hover-background-primary-color: #f7f7f7;
$es-controls-menu-states-hover-background-secondary-color: #ebebeb;
$es-controls-menu-states-hover-icon-primary-color: #d5341c;
$es-controls-menu-states-hover-icon-secondary-color: #1f2123;
$es-controls-menu-states-hover-divider-color: #d5d6d8;
$es-controls-menu-states-hover-line-color: #eff0f0;
$es-controls-menu-states-hover-text-primary-color: #1a1c1e;
$es-controls-menu-states-hover-text-secondary-color: #828282;
$es-controls-menu-states-focused-background-primary-color: #b72913;
$es-controls-menu-states-focused-background-secondary-color: #b72913;
$es-controls-menu-states-focused-icon-primary-color: #ffffff;
$es-controls-menu-states-focused-icon-secondary-color: #ffffff;
$es-controls-menu-states-focused-divider-color: #d5d6d8;
$es-controls-menu-states-focused-line-color: #eff0f0;
$es-controls-menu-states-focused-text-primary-color: #ffffff;
$es-controls-menu-states-focused-text-secondary-color: #ffffff;
$es-controls-menu-states-active-opened-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-menu-states-active-opened-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-menu-states-active-opened-icon-primary-color: #d5341c;
$es-controls-menu-states-active-opened-icon-secondary-color: #1f2123;
$es-controls-menu-states-active-opened-divider-color: #d5d6d8;
$es-controls-menu-states-active-opened-line-color: #b72913;
$es-controls-menu-states-active-opened-text-primary-color: #1a1c1e;
$es-controls-menu-states-active-opened-text-secondary-color: #828282;
$es-controls-menu-states-dragged-background-primary-color: #eff0f0;
$es-controls-menu-states-dragged-background-secondary-color: #ebebeb;
$es-controls-menu-states-dragged-icon-primary-color: #b72913;
$es-controls-menu-states-dragged-icon-secondary-color: #464749;
$es-controls-menu-states-dragged-divider-color: #d5d6d8;
$es-controls-menu-states-dragged-line-color: #ffffff;
$es-controls-menu-states-dragged-text-primary-color: #1a1c1e;
$es-controls-menu-states-dragged-text-secondary-color: #828282;
$es-controls-menu-states-disabled-background-primary-color: #f7f7f7;
$es-controls-menu-states-disabled-background-secondary-color: #f7f7f7;
$es-controls-menu-states-disabled-icon-primary-color: rgba(183, 41, 19, 0.3);
$es-controls-menu-states-disabled-icon-secondary-color: #c1c2c3;
$es-controls-menu-states-disabled-divider-color: #d5d6d8;
$es-controls-menu-states-disabled-line-color: #eff0f0;
$es-controls-menu-states-disabled-text-secondary-color: #c1c2c3;
$es-controls-menu-states-disabled-text-primary-color: #c1c2c3;
$es-controls-tree-medium-gap-horizontal-s-spacing: 4;
$es-controls-tree-medium-gap-horizontal-m-spacing: 8;
$es-controls-tree-medium-gap-horizontal-l-spacing: 12;
$es-controls-tree-medium-gap-vertical-s-spacing: 4;
$es-controls-tree-medium-gap-vertical-m-spacing: 8;
$es-controls-tree-medium-gap-vertical-l-spacing: 12;
$es-controls-tree-medium-gap-vertical-xl-spacing: 16;
$es-controls-tree-medium-gap-vertical-xxl-spacing: 16;
$es-controls-tree-medium-gap-vertical-xxxl-spacing: 16;
$es-controls-tree-medium-top-s-spacing: 4;
$es-controls-tree-medium-top-m-spacing: 8;
$es-controls-tree-medium-top-l-spacing: 12;
$es-controls-tree-medium-bottom-s-spacing: 4;
$es-controls-tree-medium-bottom-m-spacing: 8;
$es-controls-tree-medium-bottom-l-spacing: 12;
$es-controls-tree-medium-gap-horizontal-xl-spacing: 16;
$es-controls-tree-medium-left-xxxs-spacing: 8;
$es-controls-tree-medium-left-xxxxs-spacing: 4;
$es-controls-tree-medium-left-xxs-spacing: 12;
$es-controls-tree-medium-left-xs-spacing: 16;
$es-controls-tree-medium-left-s-spacing: 20;
$es-controls-tree-medium-left-m-spacing: 28;
$es-controls-tree-medium-left-l-spacing: 48;
$es-controls-tree-medium-left-xl-spacing: 72;
$es-controls-tree-medium-left-xxl-spacing: 96;
$es-controls-tree-medium-right-s-spacing: 8;
$es-controls-tree-medium-right-m-spacing: 12;
$es-controls-tree-states-default-background-color: rgba(0, 0, 0, 0);
$es-controls-tree-states-default-icon-caret-closed-color: #464749;
$es-controls-tree-states-default-icon-caret-open-color: #b72913;
$es-controls-tree-states-default-icon-primary-color: #828282;
$es-controls-tree-states-default-text-main-color: #1a1c1e;
$es-controls-tree-states-default-text-secondary-color: #1a1c1e;
$es-controls-tree-states-default-icon-error-color: #ff2706;
$es-controls-tree-states-default-text-error-color: #ff4d4d;
$es-controls-tree-states-default-stroke-color: #d5d6d8;
$es-controls-tree-states-default-all-m-border-radius: 4;
$es-controls-tree-states-hover-background-color: #ffffff;
$es-controls-tree-states-hover-icon-caret-closed-color: #464749;
$es-controls-tree-states-hover-icon-caret-open-color: #b72913;
$es-controls-tree-states-hover-icon-primary-color: #828282;
$es-controls-tree-states-hover-text-main-color: #1a1c1e;
$es-controls-tree-states-hover-text-secondary-color: #1a1c1e;
$es-controls-tree-states-hover-icon-error-color: #ff2706;
$es-controls-tree-states-hover-text-error-color: #ff4d4d;
$es-controls-tree-states-hover-stroke-color: #d5d6d8;
$es-controls-tree-states-hover-all-m-border-radius: 4;
$es-controls-tree-states-active-background-color: rgba(183, 41, 19, 0.08);
$es-controls-tree-states-active-icon-caret-closed-color: #b72913;
$es-controls-tree-states-active-icon-caret-open-color: #b72913;
$es-controls-tree-states-active-icon-primary-color: #b72913;
$es-controls-tree-states-active-text-main-color: #b72913;
$es-controls-tree-states-active-text-secondary-color: #1a1c1e;
$es-controls-tree-states-active-icon-error-color: #ff2706;
$es-controls-tree-states-active-text-error-color: #ff4d4d;
$es-controls-tree-states-active-stroke-color: #d5d6d8;
$es-controls-tree-states-active-all-m-border-radius: 4;
$es-controls-progress-large-gap-vertical-m-spacing: 4;
$es-controls-progress-large-gap-horizontal-m-spacing: 8;
$es-controls-progress-large-height-m-sizing: 8;
$es-controls-progress-large-all-m-border-radius: 4;
$es-controls-progress-large-bottom-right-m-border-radius: 8;
$es-controls-progress-large-bottom-left-m-border-radius: 8;
$es-controls-progress-medium-gap-vertical-m-spacing: 4;
$es-controls-progress-medium-gap-horizontal-m-spacing: 4;
$es-controls-progress-medium-height-m-sizing: 4;
$es-controls-progress-medium-all-m-border-radius: 2;
$es-controls-progress-medium-bottom-right-m-border-radius: 4;
$es-controls-progress-medium-bottom-left-m-border-radius: 4;
$es-controls-progress-small-height-m-sizing: 2;
$es-controls-progress-small-all-m-border-radius: 2;
$es-controls-progress-small-bottom-right-m-border-radius: 2;
$es-controls-progress-small-bottom-left-m-border-radius: 2;
$es-controls-progress-small-gap-vertical-m-spacing: 2;
$es-controls-progress-small-gap-horizontal-m-spacing: 8;
$es-controls-progress-states-default-track-primary-color: #eff0f0;
$es-controls-progress-states-default-bar-indicator-error-color: #ff4d4d;
$es-controls-progress-states-default-bar-indicator-success-color: #58975b;
$es-controls-progress-states-default-bar-indicator-primary-color: #b72913;
$es-controls-progress-states-default-bar-indicator-attention-color: #ef6c00;
$es-controls-progress-states-default-bar-indicator-shades-color: #4b6f98;
$es-controls-progress-states-default-text-primary-color: #1a1c1e;
$es-controls-progress-states-default-text-secondary-color: #828282;
$es-controls-progress-states-default-text-success-color: #58975b;
$es-controls-progress-states-default-text-attention-color: #ef6c00;
$es-controls-progress-states-default-text-error-color: #ff4d4d;
$es-controls-progress-states-default-text-shades-color: #d5d6d8;
$es-controls-progress-states-default-icon-primary-color: #b72913;
$es-controls-progress-states-default-icon-secondary-color: #464749;
$es-controls-progress-states-default-icon-success-color: #58975b;
$es-controls-progress-states-default-icon-error-color: #ff2706;
$es-controls-progress-states-default-icon-attention-color: #ef6c00;
$es-controls-progress-states-default-icon-shades-color: #4b6f98;
$es-controls-file-upload-small-gap-horizontal-m-spacing: 4;
$es-controls-file-upload-small-gap-horizontal-l-spacing: 8;
$es-controls-file-upload-small-gap-horizontal-xl-spacing: 12;
$es-controls-file-upload-small-gap-vertical-m-spacing: 4;
$es-controls-file-upload-small-gap-vertical-l-spacing: 8;
$es-controls-file-upload-small-gap-vertical-xl-spacing: 12;
$es-controls-file-upload-small-top-m-spacing: 8;
$es-controls-file-upload-small-top-l-spacing: 12;
$es-controls-file-upload-small-bottom-m-spacing: 8;
$es-controls-file-upload-small-bottom-l-spacing: 12;
$es-controls-file-upload-small-left-m-spacing: 12;
$es-controls-file-upload-small-left-l-spacing: 16;
$es-controls-file-upload-small-right-m-spacing: 12;
$es-controls-file-upload-small-right-l-spacing: 16;
$es-controls-file-upload-small-height-m-sizing: 48;
$es-controls-file-upload-large-gap-horizontal-m-spacing: 4;
$es-controls-file-upload-large-gap-horizontal-l-spacing: 8;
$es-controls-file-upload-large-gap-horizontal-xl-spacing: 12;
$es-controls-file-upload-large-gap-vertical-m-spacing: 4;
$es-controls-file-upload-large-gap-vertical-l-spacing: 8;
$es-controls-file-upload-large-gap-vertical-xl-spacing: 12;
$es-controls-file-upload-large-bottom-m-spacing: 8;
$es-controls-file-upload-large-bottom-l-spacing: 12;
$es-controls-file-upload-large-left-m-spacing: 12;
$es-controls-file-upload-large-left-l-spacing: 16;
$es-controls-file-upload-large-right-m-spacing: 12;
$es-controls-file-upload-large-right-l-spacing: 16;
$es-controls-file-upload-large-top-m-spacing: 8;
$es-controls-file-upload-large-top-l-spacing: 12;
$es-controls-file-upload-large-height-m-sizing: 96;
$es-controls-file-upload-medium-gap-horizontal-m-spacing: 4;
$es-controls-file-upload-medium-gap-horizontal-l-spacing: 8;
$es-controls-file-upload-medium-gap-horizontal-xl-spacing: 12;
$es-controls-file-upload-medium-gap-vertical-m-spacing: 4;
$es-controls-file-upload-medium-gap-vertical-l-spacing: 8;
$es-controls-file-upload-medium-gap-vertical-xl-spacing: 12;
$es-controls-file-upload-medium-bottom-m-spacing: 8;
$es-controls-file-upload-medium-bottom-l-spacing: 12;
$es-controls-file-upload-medium-left-m-spacing: 12;
$es-controls-file-upload-medium-left-l-spacing: 16;
$es-controls-file-upload-medium-right-m-spacing: 12;
$es-controls-file-upload-medium-right-l-spacing: 16;
$es-controls-file-upload-medium-top-m-spacing: 8;
$es-controls-file-upload-medium-top-l-spacing: 12;
$es-controls-file-upload-medium-height-m-sizing: 64;
$es-controls-file-upload-states-default-background-primary-color: #eff0f0;
$es-controls-file-upload-states-default-background-secondary-color: #ffffff;
$es-controls-file-upload-states-default-border-color: #c1c2c3;
$es-controls-file-upload-states-default-divider-color: #c1c2c3;
$es-controls-file-upload-states-default-text-primary-color: #1a1c1e;
$es-controls-file-upload-states-default-text-secondary-color: #828282;
$es-controls-file-upload-states-default-text-attention-color: #ef6c00;
$es-controls-file-upload-states-default-text-error-color: #ff4d4d;
$es-controls-file-upload-states-default-text-shades-color: #d5d6d8;
$es-controls-file-upload-states-default-icon-primary-color: #b72913;
$es-controls-header-divider-m-border-width: 1;
$es-controls-header-medium-left-margin-desctop-spacing: 360;
$es-controls-header-medium-right-margin-desctop-spacing: 360;
$es-controls-header-medium-left-margin-mobile-spacing: 32;
$es-controls-header-medium-right-margin-mobile-spacing: 32;
$es-controls-header-medium-top-xxs-spacing: 2;
$es-controls-header-medium-top-xs-spacing: 4;
$es-controls-header-medium-top-s-spacing: 8;
$es-controls-header-medium-top-m-spacing: 12;
$es-controls-header-medium-top-l-spacing: 16;
$es-controls-header-medium-top-xl-spacing: 32;
$es-controls-header-medium-bottom-xxs-spacing: 2;
$es-controls-header-medium-bottom-xs-spacing: 4;
$es-controls-header-medium-bottom-s-spacing: 8;
$es-controls-header-medium-bottom-m-spacing: 12;
$es-controls-header-medium-bottom-l-spacing: 16;
$es-controls-header-medium-bottom-xl-spacing: 20;
$es-controls-header-medium-left-l-spacing: 32;
$es-controls-header-medium-left-m-spacing: 28;
$es-controls-header-medium-left-s-spacing: 24;
$es-controls-header-medium-left-xs-spacing: 20;
$es-controls-header-medium-left-xxs-spacing: 16;
$es-controls-header-medium-left-xxxs-spacing: 12;
$es-controls-header-medium-left-xxxxs-spacing: 8;
$es-controls-header-medium-right-l-spacing: 32;
$es-controls-header-medium-right-m-spacing: 28;
$es-controls-header-medium-right-s-spacing: 24;
$es-controls-header-medium-right-xs-spacing: 20;
$es-controls-header-medium-right-xxs-spacing: 16;
$es-controls-header-medium-right-xxxs-spacing: 12;
$es-controls-header-medium-right-xxxxs-spacing: 8;
$es-controls-header-medium-right-5-xs-spacing: 4;
$es-controls-header-medium-gap-vertical-m-spacing: 16;
$es-controls-header-medium-gap-vertical-xxs-spacing: 4;
$es-controls-header-medium-gap-horizontal-xxxxl-spacing: 32;
$es-controls-header-medium-gap-horizontal-xxxl-spacing: 28;
$es-controls-header-medium-gap-horizontal-xxl-spacing: 24;
$es-controls-header-medium-gap-horizontal-xl-spacing: 20;
$es-controls-header-medium-gap-horizontal-l-spacing: 16;
$es-controls-header-medium-gap-horizontal-m-spacing: 12;
$es-controls-header-medium-gap-horizontal-s-spacing: 8;
$es-controls-header-medium-height-5-xs-sizing: 28;
$es-controls-header-medium-height-s-sizing: 40;
$es-controls-header-medium-height-m-sizing: 48;
$es-controls-header-medium-all-xxs-border-radius: 4;
$es-controls-header-medium-top-left-xxs-border-radius: 4;
$es-controls-header-medium-top-right-xxs-border-radius: 4;
$es-controls-header-medium-bottom-right-xxs-border-radius: 4;
$es-controls-header-medium-bottom-left-xxs-border-radius: 4;
$es-controls-header-states-default-background-primary-color: #ffffff;
$es-controls-header-states-default-background-secondary-color: #e5e5e6;
$es-controls-header-states-default-divider-primary-color: #eff0f0;
$es-controls-header-states-default-text-primary-color: #1a1c1e;
$es-controls-header-states-default-text-secondary-color: #828282;
$es-controls-header-states-default-text-tertiary-color: #c1c2c3;
$es-controls-header-states-default-indicator-color: #b72913;
$es-controls-header-states-default-icon-primary-color: #b72913;
$es-controls-header-states-default-icon-secondary-color: #0f4692;
$es-controls-header-states-default-icon-tertiary-color: #828282;
$es-controls-header-states-default-icon-quaternary-color: #1f2123;
$es-controls-header-states-default-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-badge-large-top-m-spacing: 8;
$es-controls-badge-large-bottom-m-spacing: 8;
$es-controls-badge-large-gap-m-spacing: 4;
$es-controls-badge-large-left-m-spacing: 12;
$es-controls-badge-large-right-m-spacing: 12;
$es-controls-badge-large-height-m-spacing: 32;
$es-controls-badge-large-all-m-border-radius: 16;
$es-controls-badge-medium-top-m-spacing: 4;
$es-controls-badge-medium-bottom-m-spacing: 4;
$es-controls-badge-medium-gap-m-spacing: 4;
$es-controls-badge-medium-left-m-spacing: 8;
$es-controls-badge-medium-right-m-spacing: 8;
$es-controls-badge-medium-height-m-spacing: 24;
$es-controls-badge-medium-all-m-border-radius: 12;
$es-controls-badge-small-top-m-spacing: 2;
$es-controls-badge-small-bottom-m-spacing: 2;
$es-controls-badge-small-gap-m-spacing: 4;
$es-controls-badge-small-left-m-spacing: 4;
$es-controls-badge-small-right-m-spacing: 4;
$es-controls-badge-small-height-m-spacing: 16;
$es-controls-badge-small-all-m-border-radius: 8;
$es-controls-badge-states-default-background-primary-color: #b72913;
$es-controls-badge-states-default-background-error-color: #ff4d4d;
$es-controls-badge-states-default-background-on-brand-color: #ffffff;
$es-controls-badge-states-default-background-gray-color: #828282;
$es-controls-badge-states-default-background-success-color: #58975b;
$es-controls-badge-states-default-background-secondary-color: #0f4692;
$es-controls-badge-states-default-background-shades-color: #4b6f98;
$es-controls-badge-states-default-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-badge-states-default-background-secondary-lvl-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-controls-badge-states-default-background-shades50-color: rgba(
  75,
  111,
  152,
  0.5
);
$es-controls-badge-states-default-text-primary-color: #1a1c1e;
$es-controls-badge-states-default-text-secondary-color: #828282;
$es-controls-badge-states-default-text-tertiary-color: #ffffff;
$es-controls-badge-states-default-text-on-primary-color: #ffffff;
$es-controls-badge-states-default-text-on-secondary-color: #ffffff;
$es-controls-badge-states-default-icon-primary-color: #121416;
$es-controls-badge-states-default-icon-secondary-color: #828282;
$es-controls-badge-states-default-icon-tertiary-color: #ffffff;
$es-controls-badge-states-default-icon-on-primary-color: #ffffff;
$es-controls-badge-states-default-icon-on-secondary-color: #ffffff;
$es-controls-tag-medium-top-m-spacing: 12;
$es-controls-tag-medium-bottom-m-spacing: 12;
$es-controls-tag-medium-top-s-spacing: 8;
$es-controls-tag-medium-gap-m-spacing: 4;
$es-controls-tag-medium-bottom-s-spacing: 8;
$es-controls-tag-medium-left-m-spacing: 20;
$es-controls-tag-medium-right-m-spacing: 20;
$es-controls-tag-medium-left-s-spacing: 8;
$es-controls-tag-medium-right-s-spacing: 8;
$es-controls-tag-medium-all-m-border-radius: 8;
$es-controls-tag-small-top-m-spacing: 4;
$es-controls-tag-small-bottom-m-spacing: 4;
$es-controls-tag-small-gap-m-spacing: 4;
$es-controls-tag-small-left-m-spacing: 8;
$es-controls-tag-small-right-m-spacing: 8;
$es-controls-tag-small-left-s-spacing: 4;
$es-controls-tag-small-right-s-spacing: 4;
$es-controls-tag-small-all-m-border-radius: 8;
$es-controls-tag-states-default-background-primary-color: #b72913;
$es-controls-tag-states-default-background-success-color: #58975b;
$es-controls-tag-states-default-backgroun-error-color: #ff4d4d;
$es-controls-tag-states-default-backgroun-error50-color: rgba(255, 39, 6, 0.5);
$es-controls-tag-states-default-background-attention-color: #ef6c00;
$es-controls-tag-states-default-background-attention50-color: rgba(
  239,
  108,
  0,
  0.5
);
$es-controls-tag-states-default-background-secondary-color: #0f4692;
$es-controls-tag-states-default-background-shades-color: #4b6f98;
$es-controls-tag-states-default-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-tag-states-default-background-secondary-lvl-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-controls-tag-states-default-background-shades50-color: rgba(
  75,
  111,
  152,
  0.5
);
$es-controls-tag-states-default-text-primary-color: #1a1c1e;
$es-controls-tag-states-default-text-secondary-color: #828282;
$es-controls-tag-states-default-text-tertiary-color: #ffffff;
$es-controls-tag-states-default-text-on-primary-color: #ffffff;
$es-controls-tag-states-default-text-on-secondary-color: #ffffff;
$es-controls-tag-states-default-icon-on-primary-color: #ffffff;
$es-controls-tag-states-default-icon-on-secondary-color: #ffffff;
$es-controls-tag-states-default-icon-primary-color: #121416;
$es-controls-tag-states-default-icon-secondary-color: #828282;
$es-controls-tag-states-default-icon-tertiary-color: #ffffff;
$es-controls-sidepanel-medium-left-xxs-spacing: 8;
$es-controls-sidepanel-medium-left-xs-spacing: 12;
$es-controls-sidepanel-medium-left-s-spacing: 16;
$es-controls-sidepanel-medium-left-m-spacing: 20;
$es-controls-sidepanel-medium-right-xxs-spacing: 8;
$es-controls-sidepanel-medium-right-xs-spacing: 12;
$es-controls-sidepanel-medium-right-s-spacing: 16;
$es-controls-sidepanel-medium-right-m-spacing: 20;
$es-controls-sidepanel-medium-top-xs-spacing: 12;
$es-controls-sidepanel-medium-top-s-spacing: 16;
$es-controls-sidepanel-medium-top-m-spacing: 20;
$es-controls-sidepanel-medium-top-l-spacing: 24;
$es-controls-sidepanel-medium-bottom-xs-spacing: 12;
$es-controls-sidepanel-medium-bottom-s-spacing: 16;
$es-controls-sidepanel-medium-bottom-m-spacing: 20;
$es-controls-sidepanel-medium-bottom-l-spacing: 24;
$es-controls-sidepanel-medium-gap-horizontal-xs-spacing: 8;
$es-controls-sidepanel-medium-gap-horizontal-s-spacing: 12;
$es-controls-sidepanel-medium-gap-horizontal-m-spacing: 16;
$es-controls-sidepanel-medium-gap-vertical-xs-spacing: 8;
$es-controls-sidepanel-medium-gap-vertical-s-spacing: 12;
$es-controls-sidepanel-medium-gap-vertical-m-spacing: 16;
$es-controls-sidepanel-medium-gap-vertical-l-spacing: 20;
$es-controls-sidepanel-medium-gap-vertical-xxxl-spacing: 32;
$es-controls-sidepanel-states-default-icon-primary-color: #b72913;
$es-controls-sidepanel-states-default-icon-secondary-color: #828282;
$es-controls-sidepanel-states-default-text-primary-color: #1a1c1e;
$es-controls-sidepanel-states-default-background-primary-color: #f7f7f7;
$es-controls-sidepanel-states-default-background-secondary-color: #ffffff;
$es-controls-sidepanel-states-default-background-tertiary-color: #eff0f0;
$es-controls-sidepanel-states-default-divider-primary-color: #d5d6d8;
$es-controls-container-medium-top-xxxxs-spacing: 1;
$es-controls-container-medium-top-xxxs-spacing: 2;
$es-controls-container-medium-top-xxs-spacing: 4;
$es-controls-container-medium-top-xs-spacing: 8;
$es-controls-container-medium-top-s-spacing: 12;
$es-controls-container-medium-top-m-spacing: 16;
$es-controls-container-medium-top-l-spacing: 20;
$es-controls-container-medium-top-xl-spacing: 24;
$es-controls-container-medium-top-xxl-spacing: 28;
$es-controls-container-medium-top-xxxl-spacing: 32;
$es-controls-container-medium-top-xxxxl-spacing: 36;
$es-controls-container-medium-top-5-xl-spacing: 40;
$es-controls-container-medium-top-6-xl-spacing: 44;
$es-controls-container-medium-top-7-xl-spacing: 48;
$es-controls-container-medium-top-8-xl-spacing: 52;
$es-controls-container-medium-top-9-xl-spacing: 56;
$es-controls-container-medium-top-10-xl-spacing: 60;
$es-controls-container-medium-top-11-xl-spacing: 64;
$es-controls-container-medium-top-12-xl-spacing: 68;
$es-controls-container-medium-top-13-xl-spacing: 72;
$es-controls-container-medium-top-14-xl-spacing: 76;
$es-controls-container-medium-top-15-xl-spacing: 80;
$es-controls-container-medium-top-16-xl-spacing: 84;
$es-controls-container-medium-top-17-xl-spacing: 88;
$es-controls-container-medium-top-18-xl-spacing: 92;
$es-controls-container-medium-top-19-xl-spacing: 96;
$es-controls-container-medium-top-20-xl-spacing: 100;
$es-controls-container-medium-bottom-xxxxs-spacing: 1;
$es-controls-container-medium-bottom-xxxs-spacing: 2;
$es-controls-container-medium-bottom-xxs-spacing: 4;
$es-controls-container-medium-bottom-xs-spacing: 8;
$es-controls-container-medium-bottom-s-spacing: 12;
$es-controls-container-medium-bottom-m-spacing: 16;
$es-controls-container-medium-bottom-l-spacing: 20;
$es-controls-container-medium-bottom-xl-spacing: 24;
$es-controls-container-medium-bottom-xxl-spacing: 28;
$es-controls-container-medium-bottom-xxxl-spacing: 32;
$es-controls-container-medium-bottom-xxxxl-spacing: 36;
$es-controls-container-medium-bottom-5-xl-spacing: 40;
$es-controls-container-medium-bottom-6-xl-spacing: 44;
$es-controls-container-medium-bottom-7-xl-spacing: 48;
$es-controls-container-medium-bottom-8-xl-spacing: 52;
$es-controls-container-medium-bottom-9-xl-spacing: 56;
$es-controls-container-medium-bottom-10-xl-spacing: 60;
$es-controls-container-medium-bottom-11-xl-spacing: 64;
$es-controls-container-medium-bottom-12-xl-spacing: 68;
$es-controls-container-medium-bottom-13-xl-spacing: 72;
$es-controls-container-medium-bottom-14-xl-spacing: 76;
$es-controls-container-medium-bottom-15-xl-spacing: 80;
$es-controls-container-medium-bottom-16-xl-spacing: 84;
$es-controls-container-medium-bottom-17-xl-spacing: 88;
$es-controls-container-medium-bottom-18-xl-spacing: 92;
$es-controls-container-medium-bottom-19-xl-spacing: 96;
$es-controls-container-medium-bottom-20-xl-spacing: 100;
$es-controls-container-medium-left-xxxxs-spacing: 1;
$es-controls-container-medium-left-xxxs-spacing: 2;
$es-controls-container-medium-left-xxs-spacing: 4;
$es-controls-container-medium-left-xs-spacing: 8;
$es-controls-container-medium-left-s-spacing: 12;
$es-controls-container-medium-left-m-spacing: 16;
$es-controls-container-medium-left-l-spacing: 20;
$es-controls-container-medium-left-xl-spacing: 24;
$es-controls-container-medium-left-xxl-spacing: 28;
$es-controls-container-medium-left-xxxl-spacing: 32;
$es-controls-container-medium-left-xxxxl-spacing: 36;
$es-controls-container-medium-left-5-xl-spacing: 40;
$es-controls-container-medium-left-6-xl-spacing: 44;
$es-controls-container-medium-left-7-xl-spacing: 48;
$es-controls-container-medium-left-8-xl-spacing: 52;
$es-controls-container-medium-left-9-xl-spacing: 56;
$es-controls-container-medium-left-10-xl-spacing: 60;
$es-controls-container-medium-left-11-xl-spacing: 64;
$es-controls-container-medium-left-12-xl-spacing: 68;
$es-controls-container-medium-left-13-xl-spacing: 72;
$es-controls-container-medium-left-14-xl-spacing: 76;
$es-controls-container-medium-left-15-xl-spacing: 80;
$es-controls-container-medium-left-16-xl-spacing: 84;
$es-controls-container-medium-left-17-xl-spacing: 88;
$es-controls-container-medium-left-18-xl-spacing: 92;
$es-controls-container-medium-left-19-xl-spacing: 96;
$es-controls-container-medium-left-20-xl-spacing: 100;
$es-controls-container-medium-left-margin-spacing: 360;
$es-controls-container-medium-right-xxxxs-spacing: 1;
$es-controls-container-medium-right-xxxs-spacing: 2;
$es-controls-container-medium-right-xxs-spacing: 4;
$es-controls-container-medium-right-xs-spacing: 8;
$es-controls-container-medium-right-s-spacing: 12;
$es-controls-container-medium-right-m-spacing: 16;
$es-controls-container-medium-right-l-spacing: 20;
$es-controls-container-medium-right-xl-spacing: 24;
$es-controls-container-medium-right-xxl-spacing: 28;
$es-controls-container-medium-right-xxxl-spacing: 32;
$es-controls-container-medium-right-xxxxl-spacing: 36;
$es-controls-container-medium-right-5-xl-spacing: 40;
$es-controls-container-medium-right-6-xl-spacing: 44;
$es-controls-container-medium-right-7-xl-spacing: 48;
$es-controls-container-medium-right-8-xl-spacing: 52;
$es-controls-container-medium-right-9-xl-spacing: 56;
$es-controls-container-medium-right-10-xl-spacing: 60;
$es-controls-container-medium-right-11-xl-spacing: 64;
$es-controls-container-medium-right-12-xl-spacing: 68;
$es-controls-container-medium-right-13-xl-spacing: 72;
$es-controls-container-medium-right-14-xl-spacing: 76;
$es-controls-container-medium-right-15-xl-spacing: 80;
$es-controls-container-medium-right-16-xl-spacing: 84;
$es-controls-container-medium-right-17-xl-spacing: 88;
$es-controls-container-medium-right-18-xl-spacing: 92;
$es-controls-container-medium-right-19-xl-spacing: 96;
$es-controls-container-medium-right-20-xl-spacing: 100;
$es-controls-container-medium-right-margin-spacing: 360;
$es-controls-container-medium-gap-vertical-xxxxs-spacing: 1;
$es-controls-container-medium-gap-vertical-xxxs-spacing: 2;
$es-controls-container-medium-gap-vertical-xxs-spacing: 4;
$es-controls-container-medium-gap-vertical-xs-spacing: 8;
$es-controls-container-medium-gap-vertical-s-spacing: 12;
$es-controls-container-medium-gap-vertical-m-spacing: 16;
$es-controls-container-medium-gap-vertical-l-spacing: 20;
$es-controls-container-medium-gap-vertical-xl-spacing: 24;
$es-controls-container-medium-gap-vertical-xxl-spacing: 28;
$es-controls-container-medium-gap-vertical-xxxl-spacing: 32;
$es-controls-container-medium-gap-vertical-xxxxl-spacing: 36;
$es-controls-container-medium-gap-vertical-5-xl-spacing: 40;
$es-controls-container-medium-gap-vertical-6-xl-spacing: 44;
$es-controls-container-medium-gap-vertical-7-xl-spacing: 48;
$es-controls-container-medium-gap-vertical-8-xl-spacing: 52;
$es-controls-container-medium-gap-vertical-9-xl-spacing: 56;
$es-controls-container-medium-gap-vertical-10-xl-spacing: 60;
$es-controls-container-medium-gap-vertical-11-xl-spacing: 64;
$es-controls-container-medium-gap-vertical-12-xl-spacing: 68;
$es-controls-container-medium-gap-vertical-13-xl-spacing: 72;
$es-controls-container-medium-gap-vertical-14-xl-spacing: 76;
$es-controls-container-medium-gap-vertical-15-xl-spacing: 80;
$es-controls-container-medium-gap-vertical-16-xl-spacing: 84;
$es-controls-container-medium-gap-vertical-17-xl-spacing: 88;
$es-controls-container-medium-gap-vertical-18-xl-spacing: 92;
$es-controls-container-medium-gap-vertical-19-xl-spacing: 96;
$es-controls-container-medium-gap-vertical-20-xl-spacing: 100;
$es-controls-container-medium-gap-horizontal-xxxxs-spacing: 1;
$es-controls-container-medium-gap-horizontal-xxxs-spacing: 2;
$es-controls-container-medium-gap-horizontal-xxs-spacing: 4;
$es-controls-container-medium-gap-horizontal-xs-spacing: 8;
$es-controls-container-medium-gap-horizontal-s-spacing: 12;
$es-controls-container-medium-gap-horizontal-m-spacing: 16;
$es-controls-container-medium-gap-horizontal-l-spacing: 20;
$es-controls-container-medium-gap-horizontal-xl-spacing: 24;
$es-controls-container-medium-gap-horizontal-xxl-spacing: 28;
$es-controls-container-medium-gap-horizontal-xxxl-spacing: 32;
$es-controls-container-medium-gap-horizontal-xxxxl-spacing: 36;
$es-controls-container-medium-gap-horizontal-5-xl-spacing: 40;
$es-controls-container-medium-gap-horizontal-6-xl-spacing: 44;
$es-controls-container-medium-gap-horizontal-7-xl-spacing: 48;
$es-controls-container-medium-gap-horizontal-8-xl-spacing: 52;
$es-controls-container-medium-gap-horizontal-9-xl-spacing: 56;
$es-controls-container-medium-gap-horizontal-10-xl-spacing: 60;
$es-controls-container-medium-gap-horizontal-11-xl-spacing: 64;
$es-controls-container-medium-gap-horizontal-12-xl-spacing: 68;
$es-controls-container-medium-gap-horizontal-13-xl-spacing: 72;
$es-controls-container-medium-gap-horizontal-14-xl-spacing: 76;
$es-controls-container-medium-gap-horizontal-15-xl-spacing: 80;
$es-controls-container-medium-gap-horizontal-16-xl-spacing: 84;
$es-controls-container-medium-gap-horizontal-17-xl-spacing: 88;
$es-controls-container-medium-gap-horizontal-18-xl-spacing: 92;
$es-controls-container-medium-gap-horizontal-19-xl-spacing: 96;
$es-controls-container-medium-gap-horizontal-20-xl-spacing: 100;
$es-controls-container-medium-corner-all-none-border-radius: 0;
$es-controls-container-medium-corner-all-xxxs-border-radius: 2;
$es-controls-container-medium-corner-all-xxs-border-radius: 4;
$es-controls-container-medium-corner-all-xs-border-radius: 6;
$es-controls-container-medium-corner-all-s-border-radius: 8;
$es-controls-container-medium-corner-all-m-border-radius: 12;
$es-controls-container-medium-corner-all-l-border-radius: 16;
$es-controls-container-medium-corner-top-left-none-border-radius: 0;
$es-controls-container-medium-corner-top-left-xxxs-border-radius: 2;
$es-controls-container-medium-corner-top-left-xxs-border-radius: 4;
$es-controls-container-medium-corner-top-left-xs-border-radius: 6;
$es-controls-container-medium-corner-top-left-s-border-radius: 8;
$es-controls-container-medium-corner-top-left-m-border-radius: 12;
$es-controls-container-medium-corner-top-left-l-border-radius: 16;
$es-controls-container-medium-corner-bottom-left-none-border-radius: 0;
$es-controls-container-medium-corner-bottom-left-xxxs-border-radius: 2;
$es-controls-container-medium-corner-bottom-left-xxs-border-radius: 4;
$es-controls-container-medium-corner-bottom-left-xs-border-radius: 6;
$es-controls-container-medium-corner-bottom-left-s-border-radius: 8;
$es-controls-container-medium-corner-bottom-left-m-border-radius: 12;
$es-controls-container-medium-corner-bottom-left-l-border-radius: 16;
$es-controls-container-medium-corner-top-right-none-border-radius: 0;
$es-controls-container-medium-corner-top-right-xxxs-border-radius: 2;
$es-controls-container-medium-corner-top-right-xxs-border-radius: 4;
$es-controls-container-medium-corner-top-right-xs-border-radius: 6;
$es-controls-container-medium-corner-top-right-s-border-radius: 8;
$es-controls-container-medium-corner-top-right-m-border-radius: 12;
$es-controls-container-medium-corner-top-right-l-border-radius: 16;
$es-controls-container-medium-corner-bottom-right-none-border-radius: 0;
$es-controls-container-medium-corner-bottom-right-xxxs-border-radius: 2;
$es-controls-container-medium-corner-bottom-right-xxs-border-radius: 4;
$es-controls-container-medium-corner-bottom-right-xs-border-radius: 6;
$es-controls-container-medium-corner-bottom-right-s-border-radius: 8;
$es-controls-container-medium-corner-bottom-right-m-border-radius: 12;
$es-controls-container-medium-corner-bottom-right-l-border-radius: 16;
$es-controls-container-medium-border-s-border-width: 1;
$es-controls-container-medium-border-m-border-width: 2;
$es-controls-container-medium-border-l-border-width: 4;
$es-controls-container-medium-border-xl-border-width: 8;
$es-controls-alert-states-default-icon-primary-color: #ffffff;
$es-controls-alert-states-default-icon-secondary-color: #1f2123;
$es-controls-alert-states-default-icon-error-color: #ff2706;
$es-controls-alert-states-default-icon-attention-color: #ef6c00;
$es-controls-alert-states-default-icon-succes-color: #58975b;
$es-controls-alert-states-default-icon-shades-color: #4b6f98;
$es-controls-alert-states-default-text-primary-color: #ffffff;
$es-controls-alert-states-default-text-secondary-color: #1a1c1e;
$es-controls-alert-states-default-text-error-color: #ff4d4d;
$es-controls-alert-states-default-text-attention-color: #ef6c00;
$es-controls-alert-states-default-text-succes-color: #58975b;
$es-controls-alert-states-default-text-shades-color: #4b6f98;
$es-controls-alert-states-default-background-primary-color: #ffffff;
$es-controls-alert-states-default-background-gray-color: #828282;
$es-controls-alert-states-default-background-inverted-color: #313335;
$es-controls-alert-states-default-background-error-primary-color: #ff4d4d;
$es-controls-alert-states-default-background-attention-primary-color: #ef6c00;
$es-controls-alert-states-default-background-succes-primary-color: #58975b;
$es-controls-alert-states-default-background-shades-primary-color: #4b6f98;
$es-controls-alert-states-default-background-error50-color: rgba(
  255,
  39,
  6,
  0.5
);
$es-controls-alert-states-default-background-error30-color: rgba(
  255,
  39,
  6,
  0.3
);
$es-controls-alert-states-default-background-attention50-color: rgba(
  239,
  108,
  0,
  0.5
);
$es-controls-alert-states-default-background-succes30-color: rgba(
  46,
  125,
  50,
  0.3
);
$es-controls-alert-states-default-background-shades50-color: rgba(
  75,
  111,
  152,
  0.5
);
$es-controls-alert-states-default-background-shades20-color: rgba(
  75,
  111,
  152,
  0.2
);
$es-controls-alert-medium-top-s-spacing: 8;
$es-controls-alert-medium-top-m-spacing: 12;
$es-controls-alert-medium-top-l-spacing: 16;
$es-controls-alert-medium-top-xl-spacing: 20;
$es-controls-alert-medium-bottom-s-spacing: 8;
$es-controls-alert-medium-bottom-m-spacing: 12;
$es-controls-alert-medium-bottom-l-spacing: 16;
$es-controls-alert-medium-bottom-xl-spacing: 20;
$es-controls-alert-medium-left-s-spacing: 8;
$es-controls-alert-medium-left-m-spacing: 12;
$es-controls-alert-medium-left-l-spacing: 16;
$es-controls-alert-medium-left-xl-spacing: 20;
$es-controls-alert-medium-right-s-spacing: 8;
$es-controls-alert-medium-right-m-spacing: 12;
$es-controls-alert-medium-right-l-spacing: 16;
$es-controls-alert-medium-right-xl-spacing: 20;
$es-controls-alert-medium-gap-horizontal-m-spacing: 4;
$es-controls-alert-medium-gap-vertical-m-spacing: 4;
$es-controls-alert-medium-all-m-border-radius: 4;
$es-controls-alert-medium-all-l-border-radius: 12;
$es-controls-alert-medium-all-xxxl-border-radius: 32;
$es-controls-tooltip-medium-top-m-spacing: 8;
$es-controls-tooltip-medium-bottom-m-spacing: 8;
$es-controls-tooltip-medium-left-m-spacing: 12;
$es-controls-tooltip-medium-left-s-spacing: 8;
$es-controls-tooltip-medium-right-m-spacing: 12;
$es-controls-tooltip-medium-right-s-spacing: 8;
$es-controls-tooltip-medium-all-m-border-radius: 8;
$es-controls-tooltip-medium-width-m-sizing: 280;
$es-controls-tooltip-states-default-background-primary-color: #ffffff;
$es-controls-tooltip-states-default-background-secondary-color: #000000;
$es-controls-tooltip-states-default-text-primary-color: #000000;
$es-controls-tooltip-states-default-text-secondary-color: #ffffff;
$es-controls-tooltip-states-default-icon-primary-color: #ffffff;
$es-controls-tooltip-states-default-icon-secondary-color: #121416;
$es-controls-footer-medium-left-margin-desctop-spacing: 360;
$es-controls-footer-medium-left-margin-mobile-spacing: 32;
$es-controls-footer-medium-right-margin-desctop-spacing: 360;
$es-controls-footer-medium-right-margin-mobile-spacing: 32;
$es-controls-footer-medium-top-m-spacing: 12;
$es-controls-footer-medium-top-l-spacing: 16;
$es-controls-footer-medium-top-xl-spacing: 32;
$es-controls-footer-medium-bottom-m-spacing: 12;
$es-controls-footer-medium-bottom-l-spacing: 32;
$es-controls-footer-medium-left-l-spacing: 32;
$es-controls-footer-medium-right-l-spacing: 32;
$es-controls-footer-medium-gap-vertical-m-spacing: 16;
$es-controls-footer-medium-gap-vertical-xxs-spacing: 4;
$es-controls-footer-medium-gap-horizontal-xl-spacing: 20;
$es-controls-footer-medium-gap-horizontal-s-spacing: 8;
$es-controls-footer-states-default-background-primary-color: #ffffff;
$es-controls-footer-states-default-background-dark-color: #242628;
$es-controls-footer-states-default-background-secondary-color: #f7f7f7;
$es-controls-footer-states-default-background-tertiary-color: #e5e5e6;
$es-controls-footer-states-default-backgroun-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-footer-states-default-text-primary-color: #1a1c1e;
$es-controls-footer-states-default-text-secondary-color: #828282;
$es-controls-footer-states-default-text-tertiary-color: #c1c2c3;
$es-controls-footer-states-default-icon-primary-color: #b72913;
$es-controls-footer-states-default-icon-secondary-color: #0f4692;
$es-controls-stepper-stroke-m-border-width: 1;
$es-controls-stepper-medium-all-m-border-radius: 4;
$es-controls-stepper-medium-gap-horizontal-m-spacing: 8;
$es-controls-stepper-medium-gap-horizontal-l-spacing: 12;
$es-controls-stepper-medium-gap-horizontal-xl-spacing: 16;
$es-controls-stepper-medium-gap-horizontal-xxl-spacing: 20;
$es-controls-stepper-medium-gap-horizontal-xxxl-spacing: 24;
$es-controls-stepper-medium-gap-vertical-m-spacing: 8;
$es-controls-stepper-medium-gap-horizontal-s-spacing: 4;
$es-controls-stepper-medium-top-m-spacing: 4;
$es-controls-stepper-medium-top-l-spacing: 8;
$es-controls-stepper-medium-top-xl-spacing: 12;
$es-controls-stepper-medium-top-xxl-spacing: 16;
$es-controls-stepper-medium-top-xxxl-spacing: 20;
$es-controls-stepper-medium-top-xxxxl-spacing: 24;
$es-controls-stepper-medium-bottom-m-spacing: 4;
$es-controls-stepper-medium-bottom-l-spacing: 8;
$es-controls-stepper-medium-bottom-xl-spacing: 12;
$es-controls-stepper-medium-bottom-xxl-spacing: 16;
$es-controls-stepper-medium-bottom-xxxl-spacing: 20;
$es-controls-stepper-medium-bottom-xxxxl-spacing: 24;
$es-controls-stepper-medium-left-s-spacing: 4;
$es-controls-stepper-medium-left-m-spacing: 8;
$es-controls-stepper-medium-left-l-spacing: 12;
$es-controls-stepper-medium-left-xl-spacing: 16;
$es-controls-stepper-medium-left-xxl-spacing: 20;
$es-controls-stepper-medium-left-xxxl-spacing: 24;
$es-controls-stepper-medium-left-xxxxl-spacing: 28;
$es-controls-stepper-medium-right-s-spacing: 4;
$es-controls-stepper-medium-right-m-spacing: 8;
$es-controls-stepper-medium-right-l-spacing: 12;
$es-controls-stepper-medium-right-xl-spacing: 16;
$es-controls-stepper-medium-right-xxl-spacing: 20;
$es-controls-stepper-medium-right-xxxl-spacing: 24;
$es-controls-stepper-medium-right-xxxxl-spacing: 28;
$es-controls-stepper-medium-height-m-sizing: 40;
$es-controls-stepper-states-default-background-primary-color: #b72913;
$es-controls-stepper-states-default-background-secondary-color: #0f4692;
$es-controls-stepper-states-default-background-tertiary-color: #c1c2c3;
$es-controls-stepper-states-default-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-stepper-states-default-background-error-color: #ff4d4d;
$es-controls-stepper-states-default-border-primary-color: #b72913;
$es-controls-stepper-states-default-border-secondary-color: #0f4692;
$es-controls-stepper-states-default-border-tertiary-color: #828282;
$es-controls-stepper-states-default-border-error-color: #ff2706;
$es-controls-stepper-states-default-divider-primary-color: #b72913;
$es-controls-stepper-states-default-divider-secondary-color: #c1c2c3;
$es-controls-stepper-states-default-icon-primary-color: #b72913;
$es-controls-stepper-states-default-icon-error-color: #ff2706;
$es-controls-stepper-states-default-icon-on-primary-color: #ffffff;
$es-controls-stepper-states-default-icon-secondary-color: #0f4692;
$es-controls-stepper-states-default-icon-on-secondary-color: #ffffff;
$es-controls-stepper-states-default-icon-tertiary-color: #c1c2c3;
$es-controls-stepper-states-default-icon-color: #1f2123;
$es-controls-stepper-states-default-icon-success-color: #58975b;
$es-controls-stepper-states-default-icon-attention-color: #ef6c00;
$es-controls-stepper-states-default-text-main-color: #1a1c1e;
$es-controls-stepper-states-default-text-primary-color: #b72913;
$es-controls-stepper-states-default-text-secondary-color: #0f4692;
$es-controls-stepper-states-default-text-attention-color: #ef6c00;
$es-controls-stepper-states-default-text-on-primary-color: #ffffff;
$es-controls-stepper-states-default-text-on-secondary-color: #ffffff;
$es-controls-stepper-states-default-text-tertiary-color: #828282;
$es-controls-stepper-states-default-text-success-color: #58975b;
$es-controls-stepper-states-default-text-error-color: #ff4d4d;
$es-controls-card-medium-top-xxxs-spacing: 2;
$es-controls-card-medium-top-xxs-spacing: 4;
$es-controls-card-medium-top-xs-spacing: 8;
$es-controls-card-medium-top-s-spacing: 12;
$es-controls-card-medium-top-m-spacing: 16;
$es-controls-card-medium-top-l-spacing: 20;
$es-controls-card-medium-top-xl-spacing: 24;
$es-controls-card-medium-top-xxl-spacing: 28;
$es-controls-card-medium-top-xxxl-spacing: 32;
$es-controls-card-medium-top-xxxxl-spacing: 36;
$es-controls-card-medium-bottom-xxxs-spacing: 2;
$es-controls-card-medium-bottom-xxs-spacing: 4;
$es-controls-card-medium-bottom-xs-spacing: 8;
$es-controls-card-medium-bottom-s-spacing: 12;
$es-controls-card-medium-bottom-m-spacing: 16;
$es-controls-card-medium-bottom-l-spacing: 20;
$es-controls-card-medium-bottom-xl-spacing: 24;
$es-controls-card-medium-bottom-xxl-spacing: 28;
$es-controls-card-medium-bottom-xxxl-spacing: 32;
$es-controls-card-medium-bottom-xxxxl-spacing: 36;
$es-controls-card-medium-left-xxxs-spacing: 2;
$es-controls-card-medium-left-xxs-spacing: 4;
$es-controls-card-medium-left-xs-spacing: 8;
$es-controls-card-medium-left-s-spacing: 12;
$es-controls-card-medium-left-m-spacing: 16;
$es-controls-card-medium-left-l-spacing: 20;
$es-controls-card-medium-left-xl-spacing: 24;
$es-controls-card-medium-left-xxl-spacing: 28;
$es-controls-card-medium-left-xxxl-spacing: 32;
$es-controls-card-medium-left-xxxxl-spacing: 36;
$es-controls-card-medium-right-xxxs-spacing: 2;
$es-controls-card-medium-right-xxs-spacing: 4;
$es-controls-card-medium-right-xs-spacing: 8;
$es-controls-card-medium-right-s-spacing: 12;
$es-controls-card-medium-right-m-spacing: 16;
$es-controls-card-medium-right-l-spacing: 20;
$es-controls-card-medium-right-xl-spacing: 24;
$es-controls-card-medium-right-xxl-spacing: 28;
$es-controls-card-medium-right-xxxl-spacing: 32;
$es-controls-card-medium-right-xxxxl-spacing: 36;
$es-controls-card-medium-gap-horizontal-xxs-spacing: 4;
$es-controls-card-medium-gap-horizontal-xs-spacing: 8;
$es-controls-card-medium-gap-horizontal-s-spacing: 12;
$es-controls-card-medium-gap-horizontal-m-spacing: 16;
$es-controls-card-medium-gap-horizontal-l-spacing: 20;
$es-controls-card-medium-gap-horizontal-xl-spacing: 24;
$es-controls-card-medium-gap-horizontal-xxl-spacing: 28;
$es-controls-card-medium-gap-horizontal-xxxl-spacing: 32;
$es-controls-card-medium-gap-horizontal-xxxxl-spacing: 36;
$es-controls-card-medium-gap-vertical-xxs-spacing: 4;
$es-controls-card-medium-gap-vertical-xs-spacing: 8;
$es-controls-card-medium-gap-vertical-s-spacing: 12;
$es-controls-card-medium-gap-vertical-m-spacing: 16;
$es-controls-card-medium-gap-vertical-l-spacing: 20;
$es-controls-card-medium-gap-vertical-xl-spacing: 24;
$es-controls-card-medium-gap-vertical-xxl-spacing: 28;
$es-controls-card-medium-gap-vertical-xxxl-spacing: 32;
$es-controls-card-medium-gap-vertical-xxxxl-spacing: 36;
$es-controls-card-medium-all-l-border-radius: 14;
$es-controls-card-medium-all-m-border-radius: 8;
$es-controls-card-medium-all-s-border-radius: 4;
$es-controls-card-states-default-icon-quaternary-color: #828282;
$es-controls-card-states-default-icon-tertiary-color: #1f2123;
$es-controls-card-states-default-icon-secondary-color: #4b6f98;
$es-controls-card-states-default-icon-primary-color: #b72913;
$es-controls-card-states-default-icon-error-color: #ff2706;
$es-controls-card-states-default-divider-secondary-color: #c1c2c3;
$es-controls-card-states-default-divider-pimary-color: #eff0f0;
$es-controls-card-states-default-text-quaternary-color: #464749;
$es-controls-card-states-default-text-tertiary-color: #1a1c1e;
$es-controls-card-states-default-text-secondary-color: #828282;
$es-controls-card-states-default-text-primary-color: #1a1c1e;
$es-controls-card-states-default-background-tertiary-color: #ffffff;
$es-controls-card-states-default-background-secondary-color: #ffffff;
$es-controls-card-states-default-background-primary-color: #eff0f0;
$es-controls-card-states-default-background-quaternary-color: rgba(0, 0, 0, 0);
$es-controls-card-states-default-background-brand-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-card-states-hover-icon-quaternary-color: #828282;
$es-controls-card-states-hover-icon-tertiary-color: #1f2123;
$es-controls-card-states-hover-icon-secondary-color: #4b6f98;
$es-controls-card-states-hover-icon-primary-color: #b72913;
$es-controls-card-states-hover-icon-error-color: #ff4d4d;
$es-controls-card-states-hover-divider-secondary-color: #c1c2c3;
$es-controls-card-states-hover-divider-primary-color: #eff0f0;
$es-controls-card-states-hover-text-quaternary-color: #1a1c1e;
$es-controls-card-states-hover-text-tertiary-color: #1a1c1e;
$es-controls-card-states-hover-text-secondary-color: #828282;
$es-controls-card-states-hover-text-primary-color: #1a1c1e;
$es-controls-card-states-hover-background-tertiary-color: #ffffff;
$es-controls-card-states-hover-background-secondary-color: #e5e5e6;
$es-controls-card-states-hover-background-primary-color: #e5e5e6;
$es-controls-card-states-hover-background-quaternary-color: rgba(0, 0, 0, 0);
$es-controls-card-states-hover-background-brand-color: rgba(183, 41, 19, 0.2);
$es-controls-card-states-selected-icon-quaternary-color: #828282;
$es-controls-card-states-selected-icon-tertiary-color: #ffffff;
$es-controls-card-states-selected-icon-secondary-color: #b72913;
$es-controls-card-states-selected-icon-primary-color: #b72913;
$es-controls-card-states-selected-icon-error-color: #e24e36;
$es-controls-card-states-selected-divider-secondary-color: #b72913;
$es-controls-card-states-selected-text-quaternary-color: #1a1c1e;
$es-controls-card-states-selected-text-tertiary-color: #ffffff;
$es-controls-card-states-selected-text-secondary-color: #1a1c1e;
$es-controls-card-states-selected-text-primary-color: #1a1c1e;
$es-controls-card-states-selected-divider-primary-color: #eff0f0;
$es-controls-card-states-selected-background-tertiary-color: #b72913;
$es-controls-card-states-selected-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-card-states-selected-background-primary-color: #ffffff;
$es-controls-card-states-selected-background-quaternary-color: rgba(0, 0, 0, 0);
$es-controls-card-states-selected-background-brand-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-card-states-disabled-icon-quaternary-color: #c1c2c3;
$es-controls-card-states-disabled-icon-tertiary-color: #c1c2c3;
$es-controls-card-states-disabled-icon-secondary-color: #c1c2c3;
$es-controls-card-states-disabled-icon-primary-color: rgba(183, 41, 19, 0.3);
$es-controls-card-states-disabled-icon-error-color: rgba(255, 39, 6, 0.2);
$es-controls-card-states-disabled-divider-secondary-color: #c1c2c3;
$es-controls-card-states-disabled-divider-primary-color: #f7f7f7;
$es-controls-card-states-disabled-text-quaternary-color: #828282;
$es-controls-card-states-disabled-text-tertiary-color: #828282;
$es-controls-card-states-disabled-text-secondary-color: #828282;
$es-controls-card-states-disabled-text-primary-color: #828282;
$es-controls-card-states-disabled-background-tertiary-color: #eff0f0;
$es-controls-card-states-disabled-background-secondary-color: #e5e5e6;
$es-controls-card-states-disabled-background-primary-color: #f7f7f7;
$es-controls-card-states-disabled-background-quaternary-color: rgba(0, 0, 0, 0);
$es-controls-card-states-disabled-background-brand-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-card-stroke-m-border-width: 1;
$es-controls-input-states-default-icon-attention-color: #ef6c00;
$es-controls-input-states-default-icon-error-color: #ff2706;
$es-controls-input-states-default-icon-success-color: #58975b;
$es-controls-input-states-default-icon-tertiary-color: #1f2123;
$es-controls-input-states-default-icon-secondary-color: #828282;
$es-controls-input-states-default-icon-primary-color: #b72913;
$es-controls-input-states-default-divider-color: #c1c2c3;
$es-controls-input-states-default-divider-secondary-color: #828282;
$es-controls-input-states-default-border-color: #d5d6d8;
$es-controls-input-states-default-text-helper-color: #828282;
$es-controls-input-states-default-text-placeholder-color: #828282;
$es-controls-input-states-default-text-input-color: #1a1c1e;
$es-controls-input-states-default-text-label-primary-color: #464749;
$es-controls-input-states-default-text-label-secondary-color: #1a1c1e;
$es-controls-input-states-default-background-secondary-color: #ffffff;
$es-controls-input-states-default-background-primary-color: #ffffff;
$es-controls-input-states-default-border-m-border-width: 1;
$es-controls-input-states-hover-border-m-border-width: 1;
$es-controls-input-states-hover-icon-attention-color: #ef6c00;
$es-controls-input-states-hover-icon-error-color: #ff2706;
$es-controls-input-states-hover-icon-success-color: #58975b;
$es-controls-input-states-hover-icon-tertiary-color: #1f2123;
$es-controls-input-states-hover-icon-secondary-color: #1f2123;
$es-controls-input-states-hover-icon-primary-color: #b72913;
$es-controls-input-states-hover-divider-color: #828282;
$es-controls-input-states-hover-divider-secondary-color: #1a1c1e;
$es-controls-input-states-hover-border-color: #828282;
$es-controls-input-states-hover-text-helper-color: #828282;
$es-controls-input-states-hover-text-placeholder-color: #828282;
$es-controls-input-states-hover-text-input-color: #1a1c1e;
$es-controls-input-states-hover-text-label-primary-color: #464749;
$es-controls-input-states-hover-text-label-secondary-color: #1a1c1e;
$es-controls-input-states-hover-background-secondary-color: #ebebeb;
$es-controls-input-states-hover-background-primary-color: #ffffff;
$es-controls-input-states-focused-border-m-border-width: 1;
$es-controls-input-states-focused-icon-attention-color: #ef6c00;
$es-controls-input-states-focused-icon-error-color: #ff2706;
$es-controls-input-states-focused-icon-success-color: #58975b;
$es-controls-input-states-focused-icon-tertiary-color: #1f2123;
$es-controls-input-states-focused-icon-secondary-color: #d5341c;
$es-controls-input-states-focused-icon-primary-color: #b72913;
$es-controls-input-states-focused-divider-color: #58975b;
$es-controls-input-states-focused-divider-secondary-color: #58975b;
$es-controls-input-states-focused-border-color: #58975b;
$es-controls-input-states-focused-text-helper-color: #828282;
$es-controls-input-states-focused-text-placeholder-color: #828282;
$es-controls-input-states-focused-text-input-color: #1a1c1e;
$es-controls-input-states-focused-text-label-primary-color: #58975b;
$es-controls-input-states-focused-text-label-secondary-color: #58975b;
$es-controls-input-states-focused-background-secondary-color: #c1c2c3;
$es-controls-input-states-focused-background-primary-color: #ffffff;
$es-controls-input-states-error-border-m-border-width: 1;
$es-controls-input-states-error-icon-attention-color: #ef6c00;
$es-controls-input-states-error-icon-error-color: #ff2706;
$es-controls-input-states-error-icon-success-color: #58975b;
$es-controls-input-states-error-icon-tertiary-color: #1f2123;
$es-controls-input-states-error-icon-secondary-color: #ff2706;
$es-controls-input-states-error-icon-primary-color: #b72913;
$es-controls-input-states-error-divider-color: #ff2706;
$es-controls-input-states-error-divider-secondary-color: #ff2706;
$es-controls-input-states-error-border-color: #ff4d4d;
$es-controls-input-states-error-text-helper-color: #ff4d4d;
$es-controls-input-states-error-text-placeholder-color: #ff4d4d;
$es-controls-input-states-error-text-input-color: #1a1c1e;
$es-controls-input-states-error-text-label-primary-color: #464749;
$es-controls-input-states-error-text-label-secondary-color: #1a1c1e;
$es-controls-input-states-error-background-secondary-color: #eff0f0;
$es-controls-input-states-error-background-primary-color: #ffffff;
$es-controls-input-states-disabled-border-m-border-width: 1;
$es-controls-input-states-disabled-icon-attention-color: #ef6c00;
$es-controls-input-states-disabled-icon-error-color: #ff2706;
$es-controls-input-states-disabled-icon-success-color: #58975b;
$es-controls-input-states-disabled-icon-tertiary-color: #1f2123;
$es-controls-input-states-disabled-icon-secondary-color: #c1c2c3;
$es-controls-input-states-disabled-icon-primary-color: #c1c2c3;
$es-controls-input-states-disabled-divider-color: #c1c2c3;
$es-controls-input-states-disabled-divider-secondary-color: #e5e5e6;
$es-controls-input-states-disabled-border-color: #e5e5e6;
$es-controls-input-states-disabled-text-helper-color: #828282;
$es-controls-input-states-disabled-text-placeholder-color: #828282;
$es-controls-input-states-disabled-text-input-color: #1a1c1e;
$es-controls-input-states-disabled-text-label-primary-color: #828282;
$es-controls-input-states-disabled-text-label-secondary-color: #1a1c1e;
$es-controls-input-states-disabled-background-secondary-color: #e5e5e6;
$es-controls-input-states-disabled-background-primary-color: #ebebeb;
$es-controls-input-stroke-m-border-width: 1;
$es-controls-input-large-label-gap-m-spacing: 4;
$es-controls-input-large-label-bottom-m-spacing: 8;
$es-controls-input-large-label-height-m-sizing: 28;
$es-controls-input-large-body-gap-m-spacing: 4;
$es-controls-input-large-body-bottom-m-spacing: 16;
$es-controls-input-large-body-top-s-spacing: 12;
$es-controls-input-large-body-top-xs-spacing: 8;
$es-controls-input-large-body-top-m-spacing: 16;
$es-controls-input-large-body-left-m-spacing: 16;
$es-controls-input-large-body-left-l-spacing: 20;
$es-controls-input-large-body-left-xl-spacing: 24;
$es-controls-input-large-body-right-m-spacing: 16;
$es-controls-input-large-body-right-l-spacing: 20;
$es-controls-input-large-body-right-xl-spacing: 24;
$es-controls-input-large-body-height-m-sizing: 64;
$es-controls-input-large-body-all-m-border-radius: 4;
$es-controls-input-large-body-all-rounded-border-radius: 32;
$es-controls-input-large-helper-gap-m-spacing: 4;
$es-controls-input-large-helper-bottom-m-spacing: 4;
$es-controls-input-large-helper-top-m-spacing: 4;
$es-controls-input-large-helper-top-s-spacing: 2;
$es-controls-input-large-helper-height-m-sizing: 20;
$es-controls-input-medium-label-gap-m-spacing: 4;
$es-controls-input-medium-label-bottom-m-spacing: 4;
$es-controls-input-medium-label-height-m-sizing: 20;
$es-controls-input-medium-body-gap-m-spacing: 4;
$es-controls-input-medium-body-bottom-m-spacing: 4;
$es-controls-input-medium-body-top-m-spacing: 4;
$es-controls-input-medium-body-top-l-spacing: 8;
$es-controls-input-medium-body-left-m-spacing: 8;
$es-controls-input-medium-body-left-l-spacing: 12;
$es-controls-input-medium-body-left-xl-spacing: 16;
$es-controls-input-medium-body-left-xxl-spacing: 20;
$es-controls-input-medium-body-right-m-spacing: 8;
$es-controls-input-medium-body-right-l-spacing: 12;
$es-controls-input-medium-body-right-xl-spacing: 16;
$es-controls-input-medium-body-right-xxl-spacing: 20;
$es-controls-input-medium-body-height-m-sizing: 40;
$es-controls-input-medium-body-all-m-border-radius: 4;
$es-controls-input-medium-body-all-rounded-border-radius: 20;
$es-controls-input-medium-helper-gap-m-spacing: 4;
$es-controls-input-medium-helper-bottom-m-spacing: 4;
$es-controls-input-medium-helper-top-m-spacing: 4;
$es-controls-input-medium-helper-top-s-spacing: 2;
$es-controls-input-medium-helper-height-m-sizing: 20;
$es-controls-input-small-label-gap-m-spacing: 4;
$es-controls-input-small-label-bottom-m-spacing: 4;
$es-controls-input-small-label-height-m-sizing: 20;
$es-controls-input-small-body-gap-m-spacing: 4;
$es-controls-input-small-body-bottom-m-spacing: 4;
$es-controls-input-small-body-top-m-spacing: 4;
$es-controls-input-small-body-left-s-spacing: 4;
$es-controls-input-small-body-left-m-spacing: 8;
$es-controls-input-small-body-left-l-spacing: 12;
$es-controls-input-small-body-left-xl-spacing: 16;
$es-controls-input-small-body-right-s-spacing: 4;
$es-controls-input-small-body-right-m-spacing: 8;
$es-controls-input-small-body-right-l-spacing: 12;
$es-controls-input-small-body-right-xl-spacing: 16;
$es-controls-input-small-body-height-m-sizing: 28;
$es-controls-input-small-body-all-m-border-radius: 4;
$es-controls-input-small-body-all-rounded-border-radius: 14;
$es-controls-input-small-helper-gap-m-spacing: 4;
$es-controls-input-small-helper-bottom-m-spacing: 4;
$es-controls-input-small-helper-top-m-spacing: 4;
$es-controls-input-small-helper-top-s-spacing: 2;
$es-controls-input-small-helper-height-m-sizing: 20;
$es-controls-sidebar-divider-m-border-width: 1;
$es-controls-sidebar-medium-header-gap-horizonta-s-spacing: 4;
$es-controls-sidebar-medium-header-gap-horizontal-m-spacing: 8;
$es-controls-sidebar-medium-header-gap-horizontal-l-spacing: 12;
$es-controls-sidebar-medium-header-gap-horizontal-xl-spacing: 16;
$es-controls-sidebar-medium-header-gap-horizontal-xxl-spacing: 20;
$es-controls-sidebar-medium-header-gap-vertical-s-spacing: 4;
$es-controls-sidebar-medium-header-gap-vertical-m-spacing: 8;
$es-controls-sidebar-medium-header-gap-vertical-l-spacing: 12;
$es-controls-sidebar-medium-header-gap-vertical-xl-spacing: 16;
$es-controls-sidebar-medium-header-gap-vertical-xxl-spacing: 20;
$es-controls-sidebar-medium-header-top-m-spacing: 8;
$es-controls-sidebar-medium-header-top-l-spacing: 12;
$es-controls-sidebar-medium-header-top-xl-spacing: 16;
$es-controls-sidebar-medium-header-top-xxl-spacing: 20;
$es-controls-sidebar-medium-header-top-xxxl-spacing: 24;
$es-controls-sidebar-medium-header-bottom-m-spacing: 8;
$es-controls-sidebar-medium-header-bottom-l-spacing: 12;
$es-controls-sidebar-medium-header-bottom-xl-spacing: 16;
$es-controls-sidebar-medium-header-bottom-xxl-spacing: 20;
$es-controls-sidebar-medium-header-left-s-spacing: 8;
$es-controls-sidebar-medium-header-left-m-spacing: 12;
$es-controls-sidebar-medium-header-left-l-spacing: 16;
$es-controls-sidebar-medium-header-left-xl-spacing: 32;
$es-controls-sidebar-medium-header-left-xxl-spacing: 44;
$es-controls-sidebar-medium-header-right-l-spacing: 16;
$es-controls-sidebar-medium-header-right-m-spacing: 12;
$es-controls-sidebar-medium-header-right-s-spacing: 8;
$es-controls-sidebar-medium-body-gap-horizonta-s-spacing: 4;
$es-controls-sidebar-medium-body-gap-horizontal-m-spacing: 8;
$es-controls-sidebar-medium-body-gap-horizontal-l-spacing: 12;
$es-controls-sidebar-medium-body-gap-horizontal-xl-spacing: 16;
$es-controls-sidebar-medium-body-gap-horizontal-xxl-spacing: 20;
$es-controls-sidebar-medium-body-gap-vertical-s-spacing: 4;
$es-controls-sidebar-medium-body-gap-vertical-m-spacing: 8;
$es-controls-sidebar-medium-body-gap-vertical-l-spacing: 12;
$es-controls-sidebar-medium-body-gap-vertical-xl-spacing: 16;
$es-controls-sidebar-medium-body-gap-vertical-xxl-spacing: 20;
$es-controls-sidebar-medium-body-top-m-spacing: 8;
$es-controls-sidebar-medium-body-top-l-spacing: 12;
$es-controls-sidebar-medium-body-top-xl-spacing: 16;
$es-controls-sidebar-medium-body-top-xxl-spacing: 20;
$es-controls-sidebar-medium-body-top-xxxl-spacing: 24;
$es-controls-sidebar-medium-body-bottom-m-spacing: 8;
$es-controls-sidebar-medium-body-bottom-l-spacing: 12;
$es-controls-sidebar-medium-body-bottom-xl-spacing: 16;
$es-controls-sidebar-medium-body-bottom-xxl-spacing: 20;
$es-controls-sidebar-medium-body-left-s-spacing: 8;
$es-controls-sidebar-medium-body-left-m-spacing: 12;
$es-controls-sidebar-medium-body-left-l-spacing: 16;
$es-controls-sidebar-medium-body-left-xl-spacing: 32;
$es-controls-sidebar-medium-body-left-xxl-spacing: 44;
$es-controls-sidebar-medium-body-right-l-spacing: 16;
$es-controls-sidebar-medium-body-right-m-spacing: 12;
$es-controls-sidebar-medium-body-right-s-spacing: 8;
$es-controls-sidebar-medium-body-height-m-sizing: 48;
$es-controls-sidebar-medium-body-width-m-sizing: 48;
$es-controls-sidebar-medium-body-all-m-border-radius: 8;
$es-controls-sidebar-states-closed-default-icon-primary-color: #b72913;
$es-controls-sidebar-states-closed-default-icon-secondary-color: #828282;
$es-controls-sidebar-states-closed-default-icon-tertiary-color: #828282;
$es-controls-sidebar-states-closed-default-icon-quaternary-color: #b72913;
$es-controls-sidebar-states-closed-default-text-primary-color: #1a1c1e;
$es-controls-sidebar-states-closed-default-text-secondary-color: #828282;
$es-controls-sidebar-states-closed-default-text-tertiary-color: #1a1c1e;
$es-controls-sidebar-states-closed-default-background-primary-color: #ffffff;
$es-controls-sidebar-states-closed-default-background-secondary-color: #ebebeb;
$es-controls-sidebar-states-closed-default-background-tertiary-color: #eff0f0;
$es-controls-sidebar-states-closed-default-background-quaternary-color: #ffffff;
$es-controls-sidebar-states-closed-default-stroke-color: #d5d6d8;
$es-controls-sidebar-states-closed-hover-icon-primary-color: #b72913;
$es-controls-sidebar-states-closed-hover-icon-secondary-color: #1f2123;
$es-controls-sidebar-states-closed-hover-icon-tertiary-color: #828282;
$es-controls-sidebar-states-closed-hover-icon-quaternary-color: #b72913;
$es-controls-sidebar-states-closed-hover-text-primary-color: #1a1c1e;
$es-controls-sidebar-states-closed-hover-text-secondary-color: #828282;
$es-controls-sidebar-states-closed-hover-text-tertiary-color: #1a1c1e;
$es-controls-sidebar-states-closed-hover-background-primary-color: #eff0f0;
$es-controls-sidebar-states-closed-hover-background-secondary-color: #ffffff;
$es-controls-sidebar-states-closed-hover-background-tertiary-color: #eff0f0;
$es-controls-sidebar-states-closed-hover-background-quaternary-color: #eff0f0;
$es-controls-sidebar-states-closed-hover-stroke-color: #d5d6d8;
$es-controls-sidebar-states-closed-active-icon-primary-color: #b72913;
$es-controls-sidebar-states-closed-active-icon-secondary-color: #b72913;
$es-controls-sidebar-states-closed-active-icon-tertiary-color: #828282;
$es-controls-sidebar-states-closed-active-icon-quaternary-color: #ffffff;
$es-controls-sidebar-states-closed-active-text-primary-color: #1a1c1e;
$es-controls-sidebar-states-closed-active-text-secondary-color: #828282;
$es-controls-sidebar-states-closed-active-text-tertiary-color: #ffffff;
$es-controls-sidebar-states-closed-active-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-sidebar-states-closed-active-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-sidebar-states-closed-active-background-tertiary-color: #eff0f0;
$es-controls-sidebar-states-closed-active-background-quaternary-color: #b72913;
$es-controls-sidebar-states-closed-active-stroke-color: #c1c2c3;
$es-controls-sidebar-states-open-default-icon-primary-color: #b72913;
$es-controls-sidebar-states-open-default-icon-secondary-color: #828282;
$es-controls-sidebar-states-open-default-icon-tertiary-color: #828282;
$es-controls-sidebar-states-open-default-icon-quaternary-color: #b72913;
$es-controls-sidebar-states-open-default-text-primary-color: #1a1c1e;
$es-controls-sidebar-states-open-default-text-secondary-color: #828282;
$es-controls-sidebar-states-open-default-text-tertiary-color: #1a1c1e;
$es-controls-sidebar-states-open-default-background-primary-color: #eff0f0;
$es-controls-sidebar-states-open-default-background-secondary-color: #ebebeb;
$es-controls-sidebar-states-open-default-background-tertiary-color: #eff0f0;
$es-controls-sidebar-states-open-default-background-quaternary-color: #ffffff;
$es-controls-sidebar-states-open-default-stroke-color: #d5d6d8;
$es-controls-sidebar-states-open-hover-icon-primary-color: #b72913;
$es-controls-sidebar-states-open-hover-icon-secondary-color: #1f2123;
$es-controls-sidebar-states-open-hover-icon-tertiary-color: #828282;
$es-controls-sidebar-states-open-hover-icon-quaternary-color: #b72913;
$es-controls-sidebar-states-open-hover-text-primary-color: #1a1c1e;
$es-controls-sidebar-states-open-hover-text-secondary-color: #828282;
$es-controls-sidebar-states-open-hover-text-tertiary-color: #1a1c1e;
$es-controls-sidebar-states-open-hover-background-primary-color: #e5e5e6;
$es-controls-sidebar-states-open-hover-background-secondary-color: #ffffff;
$es-controls-sidebar-states-open-hover-background-tertiary-color: #eff0f0;
$es-controls-sidebar-states-open-hover-background-quaternary-color: #eff0f0;
$es-controls-sidebar-states-open-hover-stroke-color: #d5d6d8;
$es-controls-sidebar-states-open-active-icon-primary-color: #b72913;
$es-controls-sidebar-states-open-active-icon-secondary-color: #b72913;
$es-controls-sidebar-states-open-active-icon-tertiary-color: #828282;
$es-controls-sidebar-states-open-active-icon-quaternary-color: #ffffff;
$es-controls-sidebar-states-open-active-text-primary-color: #1a1c1e;
$es-controls-sidebar-states-open-active-text-secondary-color: #828282;
$es-controls-sidebar-states-open-active-text-tertiary-color: #ffffff;
$es-controls-sidebar-states-open-active-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-sidebar-states-open-active-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-sidebar-states-open-active-background-tertiary-color: #eff0f0;
$es-controls-sidebar-states-open-active-background-quaternary-color: #b72913;
$es-controls-sidebar-states-open-active-stroke-color: #c1c2c3;
$es-controls-breadcrumb-large-gap-m-spacing: 8;
$es-controls-breadcrumb-large-gap-s-spacing: 4;
$es-controls-breadcrumb-large-left-s-spacing: 4;
$es-controls-breadcrumb-large-right-s-spacing: 4;
$es-controls-breadcrumb-large-height-m-sizing: 32;
$es-controls-breadcrumb-medium-gap-m-spacing: 8;
$es-controls-breadcrumb-medium-gap-s-spacing: 4;
$es-controls-breadcrumb-medium-left-s-spacing: 4;
$es-controls-breadcrumb-medium-right-s-spacing: 4;
$es-controls-breadcrumb-medium-height-m-sizing: 28;
$es-controls-breadcrumb-small-gap-m-spacing: 8;
$es-controls-breadcrumb-small-gap-s-spacing: 4;
$es-controls-breadcrumb-small-left-s-spacing: 4;
$es-controls-breadcrumb-small-right-s-spacing: 4;
$es-controls-breadcrumb-small-height-m-sizing: 20;
$es-controls-breadcrumb-states-default-icon-secondary-color: #1f2123;
$es-controls-breadcrumb-states-default-icon-primary-color: #b72913;
$es-controls-breadcrumb-states-default-text-primary-color: #828282;
$es-controls-breadcrumb-states-default-background-secondary-color: #ffffff;
$es-controls-breadcrumb-states-default-background-primary-color: #f7f7f7;
$es-controls-breadcrumb-states-hover-icon-secondary-color: #1f2123;
$es-controls-breadcrumb-states-hover-icon-primary-color: #b72913;
$es-controls-breadcrumb-states-hover-text-primary-color: #464749;
$es-controls-breadcrumb-states-hover-background-secondary-color: #ffffff;
$es-controls-breadcrumb-states-hover-background-primary-color: #f7f7f7;
$es-controls-breadcrumb-states-disabled-icon-secondary-color: #828282;
$es-controls-breadcrumb-states-disabled-icon-primary-color: #828282;
$es-controls-breadcrumb-states-disabled-text-primary-color: #c1c2c3;
$es-controls-breadcrumb-states-disabled-background-secondary-color: #ffffff;
$es-controls-breadcrumb-states-disabled-background-primary-color: #f7f7f7;
$es-controls-data-table-thead-small-gap-m-spacing: 8;
$es-controls-data-table-thead-small-gap-s-spacing: 4;
$es-controls-data-table-thead-small-bottom-m-spacing: 8;
$es-controls-data-table-thead-small-top-m-spacing: 8;
$es-controls-data-table-thead-small-bottom-s-spacing: 4;
$es-controls-data-table-thead-small-top-s-spacing: 4;
$es-controls-data-table-thead-small-bottom-xs-spacing: 2;
$es-controls-data-table-thead-small-top-xs-spacing: 2;
$es-controls-data-table-thead-small-left-l-spacing: 16;
$es-controls-data-table-thead-small-left-m-spacing: 12;
$es-controls-data-table-thead-small-right-l-spacing: 16;
$es-controls-data-table-thead-small-right-m-spacing: 12;
$es-controls-data-table-thead-small-right-s-spacing: 8;
$es-controls-data-table-thead-small-right-xs-spacing: 4;
$es-controls-data-table-thead-small-height-m-sizing: 32;
$es-controls-data-table-thead-small-height-s-sizing: 28;
$es-controls-data-table-thead-medium-bottom-s-spacing: 4;
$es-controls-data-table-thead-medium-top-s-spacing: 4;
$es-controls-data-table-thead-medium-gap-m-spacing: 8;
$es-controls-data-table-thead-medium-gap-s-spacing: 4;
$es-controls-data-table-thead-medium-bottom-m-spacing: 8;
$es-controls-data-table-thead-medium-top-m-spacing: 8;
$es-controls-data-table-thead-medium-left-l-spacing: 16;
$es-controls-data-table-thead-medium-left-m-spacing: 12;
$es-controls-data-table-thead-medium-left-s-spacing: 8;
$es-controls-data-table-thead-medium-left-xs-spacing: 4;
$es-controls-data-table-thead-medium-right-l-spacing: 16;
$es-controls-data-table-thead-medium-right-m-spacing: 12;
$es-controls-data-table-thead-medium-right-s-spacing: 8;
$es-controls-data-table-thead-medium-right-xs-spacing: 4;
$es-controls-data-table-thead-medium-height-m-sizing: 36;
$es-controls-data-table-thead-large-bottom-s-spacing: 4;
$es-controls-data-table-thead-large-top-s-spacing: 4;
$es-controls-data-table-thead-large-gap-m-spacing: 8;
$es-controls-data-table-thead-large-gap-s-spacing: 4;
$es-controls-data-table-thead-large-bottom-m-spacing: 8;
$es-controls-data-table-thead-large-top-m-spacing: 8;
$es-controls-data-table-thead-large-left-l-spacing: 16;
$es-controls-data-table-thead-large-left-m-spacing: 12;
$es-controls-data-table-thead-large-right-l-spacing: 16;
$es-controls-data-table-thead-large-right-m-spacing: 12;
$es-controls-data-table-thead-large-right-s-spacing: 8;
$es-controls-data-table-thead-large-right-xs-spacing: 4;
$es-controls-data-table-thead-large-height-m-sizing: 44;
$es-controls-data-table-thead-states-default-icon-color: #828282;
$es-controls-data-table-thead-states-default-icon-primary-color: #d5341c;
$es-controls-data-table-thead-states-default-divider-primary-color: #eff0f0;
$es-controls-data-table-thead-states-default-text-color: #1a1c1e;
$es-controls-data-table-thead-states-default-background-secondary-color: #eff0f0;
$es-controls-data-table-thead-states-default-background-primary-color: #ffffff;
$es-controls-data-table-thead-states-hover-icon-color: #828282;
$es-controls-data-table-thead-states-hover-icon-primary-color: #d5341c;
$es-controls-data-table-thead-states-hover-divider-primary-color: #eff0f0;
$es-controls-data-table-thead-states-hover-text-color: #1a1c1e;
$es-controls-data-table-thead-states-hover-background-secondary-color: #ebebeb;
$es-controls-data-table-thead-states-hover-background-primary-color: #eff0f0;
$es-controls-data-table-thead-states-active-icon-color: #828282;
$es-controls-data-table-thead-states-active-icon-primary-color: #d5341c;
$es-controls-data-table-thead-states-active-divider-primary-color: #eff0f0;
$es-controls-data-table-thead-states-active-text-color: #1a1c1e;
$es-controls-data-table-thead-states-active-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-data-table-thead-states-active-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-data-table-tbody-large-bottom-s-spacing: 4;
$es-controls-data-table-tbody-large-top-s-spacing: 4;
$es-controls-data-table-tbody-large-gap-m-spacing: 8;
$es-controls-data-table-tbody-large-gap-s-spacing: 4;
$es-controls-data-table-tbody-large-bottom-m-spacing: 8;
$es-controls-data-table-tbody-large-top-m-spacing: 8;
$es-controls-data-table-tbody-large-left-xxxl-spacing: 28;
$es-controls-data-table-tbody-large-left-xxl-spacing: 24;
$es-controls-data-table-tbody-large-left-xl-spacing: 20;
$es-controls-data-table-tbody-large-left-l-spacing: 16;
$es-controls-data-table-tbody-large-left-m-spacing: 12;
$es-controls-data-table-tbody-large-right-xxxl-spacing: 28;
$es-controls-data-table-tbody-large-right-xxl-spacing: 24;
$es-controls-data-table-tbody-large-right-xl-spacing: 20;
$es-controls-data-table-tbody-large-right-l-spacing: 16;
$es-controls-data-table-tbody-large-right-m-spacing: 12;
$es-controls-data-table-tbody-large-height-m-sizing: 44;
$es-controls-data-table-tbody-medium-bottom-s-spacing: 4;
$es-controls-data-table-tbody-medium-top-s-spacing: 4;
$es-controls-data-table-tbody-medium-right-xxxl-spacing: 28;
$es-controls-data-table-tbody-medium-right-xxl-spacing: 24;
$es-controls-data-table-tbody-medium-right-xl-spacing: 20;
$es-controls-data-table-tbody-medium-left-xxxl-spacing: 28;
$es-controls-data-table-tbody-medium-left-xxl-spacing: 24;
$es-controls-data-table-tbody-medium-left-xl-spacing: 20;
$es-controls-data-table-tbody-medium-gap-m-spacing: 8;
$es-controls-data-table-tbody-medium-gap-s-spacing: 4;
$es-controls-data-table-tbody-medium-bottom-m-spacing: 8;
$es-controls-data-table-tbody-medium-top-m-spacing: 8;
$es-controls-data-table-tbody-medium-left-l-spacing: 16;
$es-controls-data-table-tbody-medium-left-m-spacing: 12;
$es-controls-data-table-tbody-medium-right-l-spacing: 16;
$es-controls-data-table-tbody-medium-right-m-spacing: 12;
$es-controls-data-table-tbody-medium-height-m-sizing: 36;
$es-controls-data-table-tbody-small-bottom-s-spacing: 4;
$es-controls-data-table-tbody-small-top-s-spacing: 4;
$es-controls-data-table-tbody-small-bottom-xs-spacing: 2;
$es-controls-data-table-tbody-small-top-xs-spacing: 2;
$es-controls-data-table-tbody-small-right-xxxl-spacing: 28;
$es-controls-data-table-tbody-small-right-xxl-spacing: 24;
$es-controls-data-table-tbody-small-right-xl-spacing: 20;
$es-controls-data-table-tbody-small-left-xxxl-spacing: 28;
$es-controls-data-table-tbody-small-left-xxl-spacing: 24;
$es-controls-data-table-tbody-small-left-xl-spacing: 20;
$es-controls-data-table-tbody-small-gap-m-spacing: 8;
$es-controls-data-table-tbody-small-gap-s-spacing: 4;
$es-controls-data-table-tbody-small-bottom-m-spacing: 8;
$es-controls-data-table-tbody-small-top-m-spacing: 8;
$es-controls-data-table-tbody-small-left-l-spacing: 16;
$es-controls-data-table-tbody-small-left-m-spacing: 12;
$es-controls-data-table-tbody-small-right-l-spacing: 16;
$es-controls-data-table-tbody-small-right-m-spacing: 12;
$es-controls-data-table-tbody-small-height-m-sizing: 32;
$es-controls-data-table-tbody-small-height-s-sizing: 28;
$es-controls-data-table-tbody-states-default-icon-error-color: #ff2706;
$es-controls-data-table-tbody-states-default-icon-success-color: #58975b;
$es-controls-data-table-tbody-states-default-icon-primary-color: #b72913;
$es-controls-data-table-tbody-states-default-icon-secondary-color: #0f4692;
$es-controls-data-table-tbody-states-default-icon-gray-color: #828282;
$es-controls-data-table-tbody-states-default-icon-gray-light-color: #c1c2c3;
$es-controls-data-table-tbody-states-default-divider-primary-color: #eff0f0;
$es-controls-data-table-tbody-states-default-text-tertiary-color: #c1c2c3;
$es-controls-data-table-tbody-states-default-text-secondary-color: #464749;
$es-controls-data-table-tbody-states-default-text-primary-color: #1a1c1e;
$es-controls-data-table-tbody-states-default-text-brand-color: #b72913;
$es-controls-data-table-tbody-states-default-text-error-color: #ff4d4d;
$es-controls-data-table-tbody-states-default-text-attention-color: #ef6c00;
$es-controls-data-table-tbody-states-default-background-tertiary-color: #ebebeb;
$es-controls-data-table-tbody-states-default-background-secondary-color: #f7f7f7;
$es-controls-data-table-tbody-states-default-background-primary-color: #ffffff;
$es-controls-data-table-tbody-states-default-background-preloader-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-data-table-tbody-states-hover-icon-error-color: #ff4d4d;
$es-controls-data-table-tbody-states-hover-icon-success-color: #58975b;
$es-controls-data-table-tbody-states-hover-icon-primary-color: #d5341c;
$es-controls-data-table-tbody-states-hover-icon-secondary-color: #0f4692;
$es-controls-data-table-tbody-states-hover-icon-gray-color: #828282;
$es-controls-data-table-tbody-states-hover-icon-gray-light-color: #c1c2c3;
$es-controls-data-table-tbody-states-hover-divider-primary-color: #eff0f0;
$es-controls-data-table-tbody-states-hover-text-tertiary-color: #c1c2c3;
$es-controls-data-table-tbody-states-hover-text-secondary-color: #828282;
$es-controls-data-table-tbody-states-hover-text-primary-color: #1a1c1e;
$es-controls-data-table-tbody-states-hover-text-brand-color: #b72913;
$es-controls-data-table-tbody-states-hover-text-error-color: #ff2706;
$es-controls-data-table-tbody-states-hover-text-attention-color: #ef6c00;
$es-controls-data-table-tbody-states-hover-background-secondary-color: #ebebeb;
$es-controls-data-table-tbody-states-hover-background-primary-color: #eff0f0;
$es-controls-data-table-tbody-states-active-icon-error-color: #e24e36;
$es-controls-data-table-tbody-states-active-icon-success-color: #58975b;
$es-controls-data-table-tbody-states-active-icon-primary-color: #821d0d;
$es-controls-data-table-tbody-states-active-icon-secondary-color: #0f4692;
$es-controls-data-table-tbody-states-active-icon-gray-color: #828282;
$es-controls-data-table-tbody-states-active-icon-gray-light-color: #c1c2c3;
$es-controls-data-table-tbody-states-active-divider-primary-color: #eff0f0;
$es-controls-data-table-tbody-states-active-text-tertiary-color: #c1c2c3;
$es-controls-data-table-tbody-states-active-text-secondary-color: #828282;
$es-controls-data-table-tbody-states-active-text-primary-color: #1a1c1e;
$es-controls-data-table-tbody-states-active-text-brand-color: #b72913;
$es-controls-data-table-tbody-states-active-text-error-color: #e24e36;
$es-controls-data-table-tbody-states-active-text-attention-color: #ef6c00;
$es-controls-data-table-tbody-states-active-background-secondary-color: #eff0f0;
$es-controls-data-table-tbody-states-active-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-data-table-tbody-states-select-icon-error-color: #e24e36;
$es-controls-data-table-tbody-states-select-icon-success-color: #58975b;
$es-controls-data-table-tbody-states-select-icon-primary-color: #821d0d;
$es-controls-data-table-tbody-states-select-icon-secondary-color: #0f4692;
$es-controls-data-table-tbody-states-select-icon-gray-color: #828282;
$es-controls-data-table-tbody-states-select-icon-gray-light-color: #c1c2c3;
$es-controls-data-table-tbody-states-select-divider-primary-color: #eff0f0;
$es-controls-data-table-tbody-states-select-text-tertiary-color: #c1c2c3;
$es-controls-data-table-tbody-states-select-text-secondary-color: #828282;
$es-controls-data-table-tbody-states-select-text-primary-color: #1a1c1e;
$es-controls-data-table-tbody-states-select-text-brand-color: #b72913;
$es-controls-data-table-tbody-states-select-text-error-color: #e24e36;
$es-controls-data-table-tbody-states-select-text-attention-color: #ef6c00;
$es-controls-data-table-tbody-states-select-background-secondary-color: #eff0f0;
$es-controls-data-table-tbody-states-select-background-primary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-data-table-all-m-border-radius: 4;
$es-controls-data-table-stroke-m-border-width: 1;
$es-controls-data-table-tfoot-medium-gap-m-spacing: 8;
$es-controls-data-table-tfoot-medium-gap-l-spacing: 12;
$es-controls-data-table-tfoot-medium-gap-xl-spacing: 16;
$es-controls-data-table-tfoot-medium-gap-xxl-spacing: 20;
$es-controls-data-table-tfoot-medium-gap-xxxl-spacing: 24;
$es-controls-data-table-tfoot-medium-gap-s-spacing: 4;
$es-controls-data-table-tfoot-medium-top-xxs-spacing: 4;
$es-controls-data-table-tfoot-medium-bottom-xxs-spacing: 4;
$es-controls-data-table-tfoot-medium-top-xs-spacing: 8;
$es-controls-data-table-tfoot-medium-bottom-xs-spacing: 8;
$es-controls-data-table-tfoot-medium-top-s-spacing: 12;
$es-controls-data-table-tfoot-medium-bottom-s-spacing: 12;
$es-controls-data-table-tfoot-medium-left-xxs-spacing: 4;
$es-controls-data-table-tfoot-medium-left-xs-spacing: 8;
$es-controls-data-table-tfoot-medium-left-s-spacing: 12;
$es-controls-data-table-tfoot-medium-left-m-spacing: 16;
$es-controls-data-table-tfoot-medium-right-xxs-spacing: 4;
$es-controls-data-table-tfoot-medium-right-xs-spacing: 8;
$es-controls-data-table-tfoot-medium-right-s-spacing: 12;
$es-controls-data-table-tfoot-medium-right-m-spacing: 16;
$es-controls-data-table-tfoot-medium-height-m-sizing: 52;
$es-controls-data-table-tfoot-medium-height-xxxs-sizing: 12;
$es-controls-data-table-tfoot-medium-height-l-sizing: 64;
$es-controls-data-table-tfoot-states-default-background-primary-color: #ffffff;
$es-controls-data-table-tfoot-states-default-background-secondary-color: #eff0f0;
$es-controls-data-table-tfoot-states-default-text-primary-color: #1a1c1e;
$es-controls-data-table-tfoot-states-default-text-secondary-color: #828282;
$es-controls-data-table-tfoot-states-default-text-tertiary-color: #c1c2c3;
$es-controls-data-table-tfoot-states-default-icon-primary-color: #b72913;
$es-controls-data-table-tfoot-states-default-icon-gray-color: #828282;
$es-controls-data-table-tfoot-states-default-icon-error-color: #ff2706;
$es-controls-data-table-tfoot-states-default-divider-primary-color: #eff0f0;
$es-controls-button-action-header-all-m-border-radius: 4;
$es-controls-button-action-header-medium-height-m-sizing: 28;
$es-controls-button-action-header-medium-top-m-spacing: 4;
$es-controls-button-action-header-medium-bottom-m-spacing: 4;
$es-controls-button-action-header-medium-left-xl-spacing: 16;
$es-controls-button-action-header-medium-left-l-spacing: 12;
$es-controls-button-action-header-medium-left-m-spacing: 8;
$es-controls-button-action-header-medium-left-s-spacing: 4;
$es-controls-button-action-header-medium-right-xl-spacing: 16;
$es-controls-button-action-header-medium-right-l-spacing: 12;
$es-controls-button-action-header-medium-right-m-spacing: 8;
$es-controls-button-action-header-medium-right-s-spacing: 4;
$es-controls-button-action-header-medium-gap-m-spacing: 4;
$es-controls-button-action-header-states-default-icon-primary-color: #eb7665;
$es-controls-button-action-header-states-default-icon-secondary-color: #b72913;
$es-controls-button-action-header-states-default-text-primary-color: #b72913;
$es-controls-button-action-header-states-default-background-primary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-action-header-states-hover-icon-primary-color: #eb7665;
$es-controls-button-action-header-states-hover-icon-secondary-color: #b72913;
$es-controls-button-action-header-states-hover-text-primary-color: #b72913;
$es-controls-button-action-header-states-hover-background-primary-color: #ffffff;
$es-controls-button-action-header-states-pressed-icon-primary-color: #1f2123;
$es-controls-button-action-header-states-pressed-icon-secondary-color: #b72913;
$es-controls-button-action-header-states-pressed-text-primary-color: #1a1c1e;
$es-controls-button-action-header-states-pressed-background-primary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-action-header-states-active-icon-primary-color: #eb7665;
$es-controls-button-action-header-states-active-icon-secondary-color: #b72913;
$es-controls-button-action-header-states-active-text-primary-color: #b72913;
$es-controls-button-action-header-states-active-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-button-action-header-states-disabled-icon-primary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-button-action-header-states-disabled-icon-secondary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-button-action-header-states-disabled-text-primary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-button-action-header-states-disabled-background-primary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-primary-filled-default-icon-color: #ffffff;
$es-controls-button-main-states-primary-filled-default-text-color: #ffffff;
$es-controls-button-main-states-primary-filled-default-background-color: #b72913;
$es-controls-button-main-states-primary-filled-hover-icon-color: #ffffff;
$es-controls-button-main-states-primary-filled-hover-text-color: #ffffff;
$es-controls-button-main-states-primary-filled-hover-background-color: #d5341c;
$es-controls-button-main-states-primary-filled-pressed-icon-color: #ffffff;
$es-controls-button-main-states-primary-filled-pressed-text-color: #ffffff;
$es-controls-button-main-states-primary-filled-pressed-background-color: #821d0d;
$es-controls-button-main-states-primary-filled-disabled-icon-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-button-main-states-primary-filled-disabled-text-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-button-main-states-primary-filled-disabled-background-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-button-main-states-primary-outlined-default-icon-color: #b72913;
$es-controls-button-main-states-primary-outlined-default-text-color: #b72913;
$es-controls-button-main-states-primary-outlined-default-stroke-color: #b72913;
$es-controls-button-main-states-primary-outlined-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-primary-outlined-hover-icon-color: #ffffff;
$es-controls-button-main-states-primary-outlined-hover-text-color: #ffffff;
$es-controls-button-main-states-primary-outlined-hover-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-primary-outlined-hover-background-color: #b72913;
$es-controls-button-main-states-primary-outlined-pressed-icon-color: #ffffff;
$es-controls-button-main-states-primary-outlined-pressed-text-color: #ffffff;
$es-controls-button-main-states-primary-outlined-pressed-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-primary-outlined-pressed-background-color: #821d0d;
$es-controls-button-main-states-primary-outlined-disabled-icon-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-button-main-states-primary-outlined-disabled-text-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-button-main-states-primary-outlined-disabled-stroke-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-button-main-states-primary-outlined-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-primary-textual-disabled-icon-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-button-main-states-primary-textual-disabled-text-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-button-main-states-primary-textual-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-primary-textual-pressed-icon-color: #ffffff;
$es-controls-button-main-states-primary-textual-pressed-text-color: #ffffff;
$es-controls-button-main-states-primary-textual-pressed-background-color: #821d0d;
$es-controls-button-main-states-primary-textual-hover-icon-color: #ffffff;
$es-controls-button-main-states-primary-textual-hover-text-color: #ffffff;
$es-controls-button-main-states-primary-textual-hover-background-color: #b72913;
$es-controls-button-main-states-primary-textual-default-icon-color: #b72913;
$es-controls-button-main-states-primary-textual-default-text-color: #b72913;
$es-controls-button-main-states-primary-textual-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-secondary-filled-default-icon-color: #ffffff;
$es-controls-button-main-states-secondary-filled-default-text-color: #ffffff;
$es-controls-button-main-states-secondary-filled-default-background-color: #0f4692;
$es-controls-button-main-states-secondary-filled-hover-icon-color: #ffffff;
$es-controls-button-main-states-secondary-filled-hover-text-color: #ffffff;
$es-controls-button-main-states-secondary-filled-hover-background-color: #5a78a5;
$es-controls-button-main-states-secondary-filled-pressed-icon-color: #ffffff;
$es-controls-button-main-states-secondary-filled-pressed-text-color: #ffffff;
$es-controls-button-main-states-secondary-filled-pressed-background-color: #173f7b;
$es-controls-button-main-states-secondary-filled-disabled-icon-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-button-main-states-secondary-filled-disabled-text-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-button-main-states-secondary-filled-disabled-background-color: rgba(
  15,
  70,
  146,
  0.3
);
$es-controls-button-main-states-secondary-outlined-default-icon-color: #0f4692;
$es-controls-button-main-states-secondary-outlined-default-text-color: #0f4692;
$es-controls-button-main-states-secondary-outlined-default-stroke-color: #0f4692;
$es-controls-button-main-states-secondary-outlined-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-secondary-outlined-hover-icon-color: #ffffff;
$es-controls-button-main-states-secondary-outlined-hover-text-color: #ffffff;
$es-controls-button-main-states-secondary-outlined-hover-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-secondary-outlined-hover-background-color: #0f4692;
$es-controls-button-main-states-secondary-outlined-pressed-icon-color: #ffffff;
$es-controls-button-main-states-secondary-outlined-pressed-text-color: #ffffff;
$es-controls-button-main-states-secondary-outlined-pressed-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-secondary-outlined-pressed-background-color: #173f7b;
$es-controls-button-main-states-secondary-outlined-disabled-icon-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-controls-button-main-states-secondary-outlined-disabled-text-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-controls-button-main-states-secondary-outlined-disabled-stroke-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-controls-button-main-states-secondary-outlined-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-secondary-textual-default-icon-color: #0f4692;
$es-controls-button-main-states-secondary-textual-default-text-color: #0f4692;
$es-controls-button-main-states-secondary-textual-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-secondary-textual-hover-icon-color: #ffffff;
$es-controls-button-main-states-secondary-textual-hover-text-color: #ffffff;
$es-controls-button-main-states-secondary-textual-hover-background-color: #0f4692;
$es-controls-button-main-states-secondary-textual-pressed-icon-color: #ffffff;
$es-controls-button-main-states-secondary-textual-pressed-text-color: #ffffff;
$es-controls-button-main-states-secondary-textual-pressed-background-color: #173f7b;
$es-controls-button-main-states-secondary-textual-disabled-icon-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-controls-button-main-states-secondary-textual-disabled-text-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-controls-button-main-states-secondary-textual-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-error-filled-default-icon-color: #ffffff;
$es-controls-button-main-states-error-filled-default-text-color: #ffffff;
$es-controls-button-main-states-error-filled-default-background-color: #ff4d4d;
$es-controls-button-main-states-error-filled-hover-icon-color: #ffffff;
$es-controls-button-main-states-error-filled-hover-text-color: #ffffff;
$es-controls-button-main-states-error-filled-hover-background-color: #ff2706;
$es-controls-button-main-states-error-filled-pressed-icon-color: #ffffff;
$es-controls-button-main-states-error-filled-pressed-text-color: #ffffff;
$es-controls-button-main-states-error-filled-pressed-background-color: #aa2828;
$es-controls-button-main-states-error-filled-disabled-icon-color: #ffffff;
$es-controls-button-main-states-error-filled-disabled-text-color: #ffffff;
$es-controls-button-main-states-error-filled-disabled-background-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-button-main-states-error-outlined-default-icon-color: #ff2706;
$es-controls-button-main-states-error-outlined-default-text-color: #ff4d4d;
$es-controls-button-main-states-error-outlined-default-stroke-color: #ff2706;
$es-controls-button-main-states-error-outlined-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-error-outlined-hover-icon-color: #ffffff;
$es-controls-button-main-states-error-outlined-hover-text-color: #ffffff;
$es-controls-button-main-states-error-outlined-hover-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-error-outlined-hover-background-color: #ff4d4d;
$es-controls-button-main-states-error-outlined-pressed-icon-color: #ffffff;
$es-controls-button-main-states-error-outlined-pressed-text-color: #ffffff;
$es-controls-button-main-states-error-outlined-pressed-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-error-outlined-pressed-background-color: #aa2828;
$es-controls-button-main-states-error-outlined-disabled-icon-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-button-main-states-error-outlined-disabled-text-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-button-main-states-error-outlined-disabled-stroke-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-button-main-states-error-outlined-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-error-textual-default-icon-color: #ff2706;
$es-controls-button-main-states-error-textual-default-text-color: #ff4d4d;
$es-controls-button-main-states-error-textual-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-error-textual-hover-icon-color: #ffffff;
$es-controls-button-main-states-error-textual-hover-text-color: #ffffff;
$es-controls-button-main-states-error-textual-hover-background-color: #ff4d4d;
$es-controls-button-main-states-error-textual-pressed-icon-color: #ffffff;
$es-controls-button-main-states-error-textual-pressed-text-color: #ffffff;
$es-controls-button-main-states-error-textual-pressed-background-color: #aa2828;
$es-controls-button-main-states-error-textual-disabled-icon-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-button-main-states-error-textual-disabled-text-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-button-main-states-error-textual-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-grayscale-filled-default-icon-color: #ffffff;
$es-controls-button-main-states-grayscale-filled-default-text-color: #ffffff;
$es-controls-button-main-states-grayscale-filled-default-background-color: #464749;
$es-controls-button-main-states-grayscale-filled-hover-icon-color: #ffffff;
$es-controls-button-main-states-grayscale-filled-hover-text-color: #ffffff;
$es-controls-button-main-states-grayscale-filled-hover-background-color: #828282;
$es-controls-button-main-states-grayscale-filled-pressed-icon-color: #ffffff;
$es-controls-button-main-states-grayscale-filled-pressed-text-color: #ffffff;
$es-controls-button-main-states-grayscale-filled-pressed-background-color: #464749;
$es-controls-button-main-states-grayscale-filled-disabled-icon-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-button-main-states-grayscale-filled-disabled-text-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-button-main-states-grayscale-filled-disabled-background-color: #c1c2c3;
$es-controls-button-main-states-grayscale-outlined-default-icon-color: #464749;
$es-controls-button-main-states-grayscale-outlined-default-text-color: #464749;
$es-controls-button-main-states-grayscale-outlined-default-stroke-color: #464749;
$es-controls-button-main-states-grayscale-outlined-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-grayscale-outlined-hover-icon-color: #ffffff;
$es-controls-button-main-states-grayscale-outlined-hover-text-color: #ffffff;
$es-controls-button-main-states-grayscale-outlined-hover-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-grayscale-outlined-hover-background-color: #464749;
$es-controls-button-main-states-grayscale-outlined-pressed-icon-color: #ffffff;
$es-controls-button-main-states-grayscale-outlined-pressed-text-color: #ffffff;
$es-controls-button-main-states-grayscale-outlined-pressed-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-grayscale-outlined-pressed-background-color: #464749;
$es-controls-button-main-states-grayscale-outlined-disabled-icon-color: rgba(
  0,
  0,
  0,
  0.2
);
$es-controls-button-main-states-grayscale-outlined-disabled-text-color: rgba(
  0,
  0,
  0,
  0.2
);
$es-controls-button-main-states-grayscale-outlined-disabled-stroke-color: rgba(
  0,
  0,
  0,
  0.2
);
$es-controls-button-main-states-grayscale-outlined-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-grayscale-textual-default-icon-color: #464749;
$es-controls-button-main-states-grayscale-textual-default-text-color: #464749;
$es-controls-button-main-states-grayscale-textual-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-grayscale-textual-hover-icon-color: #ffffff;
$es-controls-button-main-states-grayscale-textual-hover-text-color: #ffffff;
$es-controls-button-main-states-grayscale-textual-hover-background-color: #464749;
$es-controls-button-main-states-grayscale-textual-pressed-icon-color: #ffffff;
$es-controls-button-main-states-grayscale-textual-pressed-text-color: #ffffff;
$es-controls-button-main-states-grayscale-textual-pressed-background-color: #464749;
$es-controls-button-main-states-grayscale-textual-disabled-icon-color: rgba(
  0,
  0,
  0,
  0.2
);
$es-controls-button-main-states-grayscale-textual-disabled-text-color: rgba(
  0,
  0,
  0,
  0.2
);
$es-controls-button-main-states-grayscale-textual-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-light-filled-default-icon-color: #121416;
$es-controls-button-main-states-light-filled-default-text-color: #000000;
$es-controls-button-main-states-light-filled-default-background-color: #ffffff;
$es-controls-button-main-states-light-filled-hover-icon-color: #121416;
$es-controls-button-main-states-light-filled-hover-text-color: #000000;
$es-controls-button-main-states-light-filled-hover-background-color: #ffffff;
$es-controls-button-main-states-light-filled-pressed-icon-color: #464749;
$es-controls-button-main-states-light-filled-pressed-text-color: #464749;
$es-controls-button-main-states-light-filled-pressed-background-color: #ffffff;
$es-controls-button-main-states-light-filled-disabled-icon-color: #828282;
$es-controls-button-main-states-light-filled-disabled-text-color: #828282;
$es-controls-button-main-states-light-filled-disabled-background-color: rgba(
  255,
  255,
  255,
  0.2
);
$es-controls-button-main-states-light-outlined-default-icon-color: #ffffff;
$es-controls-button-main-states-light-outlined-default-text-color: #ffffff;
$es-controls-button-main-states-light-outlined-default-stroke-color: #ffffff;
$es-controls-button-main-states-light-outlined-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-light-outlined-hover-icon-color: #121416;
$es-controls-button-main-states-light-outlined-hover-text-color: #000000;
$es-controls-button-main-states-light-outlined-hover-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-light-outlined-hover-background-color: #ffffff;
$es-controls-button-main-states-light-outlined-pressed-icon-color: #121416;
$es-controls-button-main-states-light-outlined-pressed-text-color: #000000;
$es-controls-button-main-states-light-outlined-pressed-stroke-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-light-outlined-pressed-background-color: #ffffff;
$es-controls-button-main-states-light-outlined-disabled-icon-color: #828282;
$es-controls-button-main-states-light-outlined-disabled-text-color: #828282;
$es-controls-button-main-states-light-outlined-disabled-stroke-color: #828282;
$es-controls-button-main-states-light-outlined-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-light-textual-default-icon-color: #ffffff;
$es-controls-button-main-states-light-textual-default-text-color: #ffffff;
$es-controls-button-main-states-light-textual-default-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-light-textual-hover-icon-color: #ffffff;
$es-controls-button-main-states-light-textual-hover-text-color: #ffffff;
$es-controls-button-main-states-light-textual-hover-background-color: rgba(
  255,
  255,
  255,
  0.2
);
$es-controls-button-main-states-light-textual-pressed-icon-color: #ffffff;
$es-controls-button-main-states-light-textual-pressed-text-color: #ffffff;
$es-controls-button-main-states-light-textual-pressed-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-states-light-textual-disabled-icon-color: #828282;
$es-controls-button-main-states-light-textual-disabled-text-color: #828282;
$es-controls-button-main-states-light-textual-disabled-background-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-stroke-m-border-width: 1;
$es-controls-button-main-large-height-m-sizing: 52;
$es-controls-button-main-large-height-l-sizing: 100;
$es-controls-button-main-large-width-m-sizing: 52;
$es-controls-button-main-large-width-xl-sizing: 320;
$es-controls-button-main-large-width-xxl-sizing: 440;
$es-controls-button-main-large-all-rounded-border-radius: 32;
$es-controls-button-main-large-all-xs-border-radius: 4;
$es-controls-button-main-large-right-xxl-spacing: 48;
$es-controls-button-main-large-left-xxl-spacing: 48;
$es-controls-button-main-large-right-l-spacing: 24;
$es-controls-button-main-large-left-l-spacing: 24;
$es-controls-button-main-large-right-m-spacing: 16;
$es-controls-button-main-large-left-m-spacing: 16;
$es-controls-button-main-large-right-s-spacing: 12;
$es-controls-button-main-large-left-s-spacing: 12;
$es-controls-button-main-large-right-xs-spacing: 8;
$es-controls-button-main-large-left-xs-spacing: 8;
$es-controls-button-main-large-gap-m-spacing: 8;
$es-controls-button-main-large-gap-l-spacing: 12;
$es-controls-button-main-medium-height-m-sizing: 40;
$es-controls-button-main-medium-width-m-sizing: 40;
$es-controls-button-main-medium-all-rounded-border-radius: 20;
$es-controls-button-main-medium-all-xs-border-radius: 4;
$es-controls-button-main-medium-left-l-spacing: 32;
$es-controls-button-main-medium-right-l-spacing: 32;
$es-controls-button-main-medium-left-m-spacing: 16;
$es-controls-button-main-medium-right-m-spacing: 16;
$es-controls-button-main-medium-left-s-spacing: 12;
$es-controls-button-main-medium-right-s-spacing: 12;
$es-controls-button-main-medium-left-xs-spacing: 8;
$es-controls-button-main-medium-right-xs-spacing: 8;
$es-controls-button-main-medium-gap-m-spacing: 8;
$es-controls-button-main-medium-gap-s-spacing: 4;
$es-controls-button-main-small-height-m-sizing: 28;
$es-controls-button-main-small-width-m-sizing: 28;
$es-controls-button-main-small-all-rounded-border-radius: 16;
$es-controls-button-main-small-all-xs-border-radius: 4;
$es-controls-button-main-small-left-xxxl-spacing: 24;
$es-controls-button-main-small-left-xxl-spacing: 20;
$es-controls-button-main-small-left-xl-spacing: 16;
$es-controls-button-main-small-left-l-spacing: 12;
$es-controls-button-main-small-left-m-spacing: 8;
$es-controls-button-main-small-left-s-spacing: 4;
$es-controls-button-main-small-right-xxxl-spacing: 24;
$es-controls-button-main-small-right-m-spacing: 8;
$es-controls-button-main-small-right-xl-spacing: 16;
$es-controls-button-main-small-right-xxl-spacing: 20;
$es-controls-button-main-small-right-l-spacing: 12;
$es-controls-button-main-small-right-s-spacing: 4;
$es-controls-button-main-small-gap-m-spacing: 4;
$es-controls-button-admin-large-height-m-sizing: 60;
$es-controls-button-admin-large-width-m-sizing: 60;
$es-controls-button-admin-large-all-rounded-border-radius: 32;
$es-controls-button-admin-large-all-xs-border-radius: 4;
$es-controls-button-admin-large-right-m-spacing: 16;
$es-controls-button-admin-large-right-l-spacing: 20;
$es-controls-button-admin-large-left-m-spacing: 20;
$es-controls-button-admin-large-left-l-spacing: 16;
$es-controls-button-admin-large-gap-m-spacing: 8;
$es-controls-button-admin-large-gap-l-spacing: 12;
$es-controls-button-admin-large-gap-xl-spacing: 16;
$es-controls-button-admin-medium-height-m-sizing: 40;
$es-controls-button-admin-medium-width-m-sizing: 40;
$es-controls-button-admin-medium-all-rounded-border-radius: 20;
$es-controls-button-admin-medium-all-xs-border-radius: 4;
$es-controls-button-admin-medium-left-m-spacing: 16;
$es-controls-button-admin-medium-left-s-spacing: 8;
$es-controls-button-admin-medium-right-m-spacing: 16;
$es-controls-button-admin-medium-right-s-spacing: 8;
$es-controls-button-admin-medium-gap-m-spacing: 4;
$es-controls-button-admin-medium-gap-l-spacing: 8;
$es-controls-button-admin-medium-gap-xl-spacing: 12;
$es-controls-button-admin-medium-gap-xxl-spacing: 16;
$es-controls-button-admin-small-height-m-sizing: 28;
$es-controls-button-admin-small-width-m-sizing: 28;
$es-controls-button-admin-small-all-rounded-border-radius: 16;
$es-controls-button-admin-small-all-xs-border-radius: 4;
$es-controls-button-admin-small-left-m-spacing: 12;
$es-controls-button-admin-small-right-m-spacing: 12;
$es-controls-button-admin-small-left-s-spacing: 8;
$es-controls-button-admin-small-right-s-spacing: 8;
$es-controls-button-admin-small-left-xs-spacing: 4;
$es-controls-button-admin-small-right-xs-spacing: 4;
$es-controls-button-admin-small-gap-m-spacing: 4;
$es-controls-button-admin-small-gap-l-spacing: 8;
$es-controls-button-admin-small-gap-xl-spacing: 12;
$es-controls-button-admin-xsmall-height-m-sizing: 24;
$es-controls-button-admin-xsmall-width-m-sizing: 24;
$es-controls-button-admin-xsmall-all-rounded-border-radius: 16;
$es-controls-button-admin-xsmall-all-xs-border-radius: 4;
$es-controls-button-admin-xsmall-left-m-spacing: 12;
$es-controls-button-admin-xsmall-right-m-spacing: 12;
$es-controls-button-admin-xsmall-gap-m-spacing: 4;
$es-controls-button-admin-states-default-background-primary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-button-admin-states-default-icon-color: #b72913;
$es-controls-button-admin-states-default-text-color: #1a1c1e;
$es-controls-button-admin-states-hover-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-button-admin-states-hover-icon-color: #d5341c;
$es-controls-button-admin-states-hover-text-color: #1a1c1e;
$es-controls-button-admin-states-pressed-background-primary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-button-admin-states-pressed-icon-color: #821d0d;
$es-controls-button-admin-states-pressed-text-color: #1a1c1e;
$es-controls-button-admin-states-disabled-background-primary-color: #f7f7f7;
$es-controls-button-admin-states-disabled-icon-color: rgba(183, 41, 19, 0.3);
$es-controls-button-admin-states-disabled-text-color: #c1c2c3;
$es-controls-button-up-header-medium-height-m-sizing: 28;
$es-controls-button-up-header-medium-top-m-spacing: 8;
$es-controls-button-up-header-medium-bottom-m-spacing: 8;
$es-controls-button-up-header-medium-left-m-spacing: 8;
$es-controls-button-up-header-medium-left-s-spacing: 4;
$es-controls-button-up-header-medium-right-m-spacing: 8;
$es-controls-button-up-header-medium-right-s-spacing: 4;
$es-controls-button-up-header-medium-gap-m-spacing: 4;
$es-controls-button-up-header-states-default-icon-primary-color: #828282;
$es-controls-button-up-header-states-default-text-primary-color: #1a1c1e;
$es-controls-button-up-header-states-default-background-primary-color: #e5e5e6;
$es-controls-button-up-header-states-hover-icon-primary-color: #828282;
$es-controls-button-up-header-states-hover-text-primary-color: #1a1c1e;
$es-controls-button-up-header-states-hover-background-primary-color: #ffffff;
$es-controls-button-up-header-states-pressed-icon-primary-color: #828282;
$es-controls-button-up-header-states-pressed-text-primary-color: #1a1c1e;
$es-controls-button-up-header-states-pressed-background-primary-color: #c1c2c3;
$es-controls-button-up-header-states-select-icon-primary-color: #828282;
$es-controls-button-up-header-states-select-text-primary-color: #ffffff;
$es-controls-button-up-header-states-select-background-primary-color: #464749;
$es-controls-button-up-header-states-disabled-icon-primary-color: #828282;
$es-controls-button-up-header-states-disabled-text-primary-color: #828282;
$es-controls-button-up-header-states-disabled-background-primary-color: #e5e5e6;
$es-controls-button-main-header-line-m-border-width: 2;
$es-controls-button-main-header-medium-height-m-sizing: 48;
$es-controls-button-main-header-medium-top-m-spacing: 8;
$es-controls-button-main-header-medium-bottom-m-spacing: 8;
$es-controls-button-main-header-medium-left-m-spacing: 16;
$es-controls-button-main-header-medium-left-s-spacing: 8;
$es-controls-button-main-header-medium-right-m-spacing: 16;
$es-controls-button-main-header-medium-right-s-spacing: 8;
$es-controls-button-main-header-medium-gap-m-spacing: 8;
$es-controls-button-main-header-states-default-icon-primary-color: #b72913;
$es-controls-button-main-header-states-default-text-primary-color: #1a1c1e;
$es-controls-button-main-header-states-default-background-primary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-header-states-default-line-color: rgba(0, 0, 0, 0);
$es-controls-button-main-header-states-hover-icon-primary-color: #b72913;
$es-controls-button-main-header-states-hover-text-primary-color: #1a1c1e;
$es-controls-button-main-header-states-hover-background-primary-color: #eff0f0;
$es-controls-button-main-header-states-hover-line-color: rgba(0, 0, 0, 0);
$es-controls-button-main-header-states-pressed-icon-primary-color: #b72913;
$es-controls-button-main-header-states-pressed-text-primary-color: #1a1c1e;
$es-controls-button-main-header-states-pressed-background-primary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-button-main-header-states-pressed-line-color: rgba(0, 0, 0, 0);
$es-controls-button-main-header-states-select-icon-primary-color: #b72913;
$es-controls-button-main-header-states-select-text-primary-color: #1a1c1e;
$es-controls-button-main-header-states-select-background-primary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-button-main-header-states-select-line-color: #b72913;
$es-controls-button-main-header-states-disabled-icon-primary-color: #c1c2c3;
$es-controls-button-main-header-states-disabled-text-primary-color: #828282;
$es-controls-button-main-header-states-disabled-background-primary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-button-main-header-states-disabled-line-color: rgba(0, 0, 0, 0);
$es-controls-tabs-all-m-border-radius: 4;
$es-controls-tabs-states-selected-default-background-primary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-selected-default-background-secondary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-selected-default-background-error-color: rgba(
  255,
  39,
  6,
  0.3
);
$es-controls-tabs-states-selected-default-icon-primary-color: #b72913;
$es-controls-tabs-states-selected-default-icon-error-color: #ff2706;
$es-controls-tabs-states-selected-default-icon-success-color: #58975b;
$es-controls-tabs-states-selected-default-icon-secondary-color: #828282;
$es-controls-tabs-states-selected-default-line-primary-color: #b72913;
$es-controls-tabs-states-selected-default-line-secondary-color: #eff0f0;
$es-controls-tabs-states-selected-default-line-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-default-line-quaternary-color: #c1c2c3;
$es-controls-tabs-states-selected-default-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-default-text-primary-color: #b72913;
$es-controls-tabs-states-selected-default-text-secondary-color: #1a1c1e;
$es-controls-tabs-states-selected-default-text-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-hovered-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-tabs-states-selected-hovered-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-tabs-states-selected-hovered-background-error-color: rgba(
  255,
  39,
  6,
  0.3
);
$es-controls-tabs-states-selected-hovered-icon-primary-color: #d5341c;
$es-controls-tabs-states-selected-hovered-icon-error-color: #ff4d4d;
$es-controls-tabs-states-selected-hovered-icon-success-color: #58975b;
$es-controls-tabs-states-selected-hovered-icon-secondary-color: #828282;
$es-controls-tabs-states-selected-hovered-icon-tertiary-color: #1f2123;
$es-controls-tabs-states-selected-hovered-line-primary-color: #d5341c;
$es-controls-tabs-states-selected-hovered-line-secondary-color: #828282;
$es-controls-tabs-states-selected-hovered-line-tertiary-color: #d5341c;
$es-controls-tabs-states-selected-hovered-text-primary-color: #d5341c;
$es-controls-tabs-states-selected-hovered-text-secondary-color: #1a1c1e;
$es-controls-tabs-states-selected-hovered-text-tertiary-color: #1a1c1e;
$es-controls-tabs-states-selected-focused-background-primary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-tabs-states-selected-focused-background-secondary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-tabs-states-selected-focused-background-error-color: rgba(
  255,
  39,
  6,
  0.3
);
$es-controls-tabs-states-selected-focused-icon-primary-color: #d5341c;
$es-controls-tabs-states-selected-focused-icon-error-color: #e24e36;
$es-controls-tabs-states-selected-focused-icon-secondary-color: #828282;
$es-controls-tabs-states-selected-focused-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-focused-line-primary-color: #d5341c;
$es-controls-tabs-states-selected-focused-line-secondary-color: #d5341c;
$es-controls-tabs-states-selected-focused-line-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-focused-text-primary-color: #d5341c;
$es-controls-tabs-states-selected-focused-text-secondary-color: #828282;
$es-controls-tabs-states-selected-focused-text-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-pressed-background-primary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-tabs-states-selected-pressed-background-secondary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-tabs-states-selected-pressed-background-error-color: rgba(
  255,
  39,
  6,
  0.3
);
$es-controls-tabs-states-selected-pressed-icon-primary-color: #821d0d;
$es-controls-tabs-states-selected-pressed-icon-error-color: #e24e36;
$es-controls-tabs-states-selected-pressed-icon-success-color: #58975b;
$es-controls-tabs-states-selected-pressed-icon-secondary-color: #828282;
$es-controls-tabs-states-selected-pressed-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-pressed-line-primary-color: #821d0d;
$es-controls-tabs-states-selected-pressed-line-secondary-color: #821d0d;
$es-controls-tabs-states-selected-pressed-line-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-pressed-text-primary-color: #821d0d;
$es-controls-tabs-states-selected-pressed-text-secondary-color: #828282;
$es-controls-tabs-states-selected-pressed-text-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-disabled-background-primary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-selected-disabled-background-secondary-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-selected-disabled-background-error-color: rgba(
  255,
  39,
  6,
  0.3
);
$es-controls-tabs-states-selected-disabled-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-disabled-icon-secondary-color: #c1c2c3;
$es-controls-tabs-states-selected-disabled-icon-primary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-tabs-states-selected-disabled-icon-error-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-tabs-states-selected-disabled-icon-success-color: #58975b;
$es-controls-tabs-states-selected-disabled-line-primary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-tabs-states-selected-disabled-line-secondary-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-tabs-states-selected-disabled-line-tertiary-color: #ffffff;
$es-controls-tabs-states-selected-disabled-text-primary-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-tabs-states-selected-disabled-text-secondary-color: #c1c2c3;
$es-controls-tabs-states-selected-disabled-text-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-default-background-primary-color: #ffffff;
$es-controls-tabs-states-unselected-default-background-secondary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-tabs-states-unselected-default-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-unselected-default-background-error-color: rgba(
  255,
  39,
  6,
  0.3
);
$es-controls-tabs-states-unselected-default-icon-primary-color: #1f2123;
$es-controls-tabs-states-unselected-default-icon-error-color: #ff2706;
$es-controls-tabs-states-unselected-default-icon-success-color: #58975b;
$es-controls-tabs-states-unselected-default-icon-secondary-color: #828282;
$es-controls-tabs-states-unselected-default-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-default-line-primary-color: #b72913;
$es-controls-tabs-states-unselected-default-line-secondary-color: #ffffff;
$es-controls-tabs-states-unselected-default-line-tertiary-color: rgba(
  255,
  255,
  255,
  0.2
);
$es-controls-tabs-states-unselected-default-text-primary-color: #1a1c1e;
$es-controls-tabs-states-unselected-default-text-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-default-text-secondary-color: #1a1c1e;
$es-controls-tabs-states-unselected-hovered-background-primary-color: #eff0f0;
$es-controls-tabs-states-unselected-hovered-background-secondary-color: #ffffff;
$es-controls-tabs-states-unselected-hovered-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-unselected-hovered-icon-tertiary-color: #1f2123;
$es-controls-tabs-states-unselected-hovered-icon-secondary-color: #828282;
$es-controls-tabs-states-unselected-hovered-icon-primary-color: #1f2123;
$es-controls-tabs-states-unselected-hovered-icon-error-color: #ff4d4d;
$es-controls-tabs-states-unselected-hovered-icon-success-color: #58975b;
$es-controls-tabs-states-unselected-hovered-line-tertiary-color: #d5341c;
$es-controls-tabs-states-unselected-hovered-line-secondary-color: #828282;
$es-controls-tabs-states-unselected-hovered-line-primary-color: #d5341c;
$es-controls-tabs-states-unselected-hovered-text-tertiary-color: #1a1c1e;
$es-controls-tabs-states-unselected-hovered-text-secondary-color: #1a1c1e;
$es-controls-tabs-states-unselected-hovered-text-primary-color: #1a1c1e;
$es-controls-tabs-states-unselected-focused-background-primary-color: #ebebeb;
$es-controls-tabs-states-unselected-focused-background-secondary-color: #e5e5e6;
$es-controls-tabs-states-unselected-focused-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-unselected-focused-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-focused-icon-secondary-color: #828282;
$es-controls-tabs-states-unselected-focused-icon-primary-color: #1f2123;
$es-controls-tabs-states-unselected-focused-icon-error-color: #e24e36;
$es-controls-tabs-states-unselected-focused-icon-success-color: #58975b;
$es-controls-tabs-states-unselected-focused-line-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-focused-line-secondary-color: #d5341c;
$es-controls-tabs-states-unselected-focused-line-primary-color: #d5341c;
$es-controls-tabs-states-unselected-focused-text-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-focused-text-secondary-color: #1a1c1e;
$es-controls-tabs-states-unselected-focused-text-primary-color: #1a1c1e;
$es-controls-tabs-states-unselected-pressed-background-primary-color: #e5e5e6;
$es-controls-tabs-states-unselected-pressed-background-secondary-color: #e5e5e6;
$es-controls-tabs-states-unselected-pressed-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-unselected-pressed-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-pressed-icon-secondary-color: #828282;
$es-controls-tabs-states-unselected-pressed-icon-primary-color: #1f2123;
$es-controls-tabs-states-unselected-pressed-icon-error-color: #e24e36;
$es-controls-tabs-states-unselected-pressed-icon-success-color: #58975b;
$es-controls-tabs-states-unselected-pressed-line-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-pressed-line-secondary-color: #821d0d;
$es-controls-tabs-states-unselected-pressed-line-primary-color: #821d0d;
$es-controls-tabs-states-unselected-pressed-text-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-pressed-text-secondary-color: #828282;
$es-controls-tabs-states-unselected-pressed-text-primary-color: #1a1c1e;
$es-controls-tabs-states-unselected-disabled-background-primary-color: #ffffff;
$es-controls-tabs-states-unselected-disabled-background-secondary-color: rgba(
  0,
  0,
  0,
  0
);
$es-controls-tabs-states-unselected-disabled-background-primary-lvl-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-tabs-states-unselected-disabled-icon-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-disabled-icon-secondary-color: #c1c2c3;
$es-controls-tabs-states-unselected-disabled-icon-primary-color: #c1c2c3;
$es-controls-tabs-states-unselected-disabled-icon-error-color: rgba(
  255,
  39,
  6,
  0.2
);
$es-controls-tabs-states-unselected-disabled-icon-success-color: #58975b;
$es-controls-tabs-states-unselected-disabled-line-tertiary-color: rgba(
  255,
  255,
  255,
  0.2
);
$es-controls-tabs-states-unselected-disabled-line-secondary-color: #ffffff;
$es-controls-tabs-states-unselected-disabled-line-primary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-tabs-states-unselected-disabled-text-tertiary-color: #ffffff;
$es-controls-tabs-states-unselected-disabled-text-secondary-color: #c1c2c3;
$es-controls-tabs-states-unselected-disabled-text-primary-color: #c1c2c3;
$es-controls-tabs-medium-gap-m-spacing: 8;
$es-controls-tabs-medium-gap-s-spacing: 4;
$es-controls-tabs-medium-bottom-m-spacing: 12;
$es-controls-tabs-medium-bottom-s-spacing: 8;
$es-controls-tabs-medium-bottom-xs-spacing: 4;
$es-controls-tabs-medium-top-s-spacing: 8;
$es-controls-tabs-medium-top-xs-spacing: 4;
$es-controls-tabs-medium-top-m-spacing: 12;
$es-controls-tabs-medium-left-m-spacing: 12;
$es-controls-tabs-medium-left-l-spacing: 16;
$es-controls-tabs-medium-right-m-spacing: 12;
$es-controls-tabs-medium-height-m-sizing: 40;
$es-controls-tabs-medium-border-bottom-m-sizing: 2;
$es-controls-tabs-medium-border-left-m-sizing: 2;
$es-controls-tabs-large-gap-m-spacing: 8;
$es-controls-tabs-large-bottom-m-spacing: 16;
$es-controls-tabs-large-bottom-s-spacing: 12;
$es-controls-tabs-large-bottom-xs-spacing: 8;
$es-controls-tabs-large-top-m-spacing: 16;
$es-controls-tabs-large-top-s-spacing: 12;
$es-controls-tabs-large-top-xs-spacing: 8;
$es-controls-tabs-large-left-m-spacing: 16;
$es-controls-tabs-large-right-m-spacing: 16;
$es-controls-tabs-large-height-m-sizing: 60;
$es-controls-tabs-large-border-left-m-sizing: 2;
$es-controls-tabs-large-border-bottom-m-sizing: 2;
$es-controls-tabs-small-gap-m-spacing: 4;
$es-controls-tabs-small-top-s-spacing: 2;
$es-controls-tabs-small-top-m-spacing: 4;
$es-controls-tabs-small-top-l-spacing: 8;
$es-controls-tabs-small-bottom-s-spacing: 2;
$es-controls-tabs-small-bottom-m-spacing: 4;
$es-controls-tabs-small-bottom-l-spacing: 8;
$es-controls-tabs-small-left-m-spacing: 8;
$es-controls-tabs-small-right-m-spacing: 8;
$es-controls-tabs-small-height-m-sizing: 28;
$es-controls-tabs-small-border-left-m-sizing: 2;
$es-controls-tabs-small-border-bottom-m-sizing: 2;
$es-controls-pagination-large-gap-m-spacing: 4;
$es-controls-pagination-large-height-m-sizing: 64;
$es-controls-pagination-large-width-m-sizing: 64;
$es-controls-pagination-large-all-m-border-radius: 8;
$es-controls-pagination-medium-gap-m-spacing: 4;
$es-controls-pagination-medium-height-m-sizing: 40;
$es-controls-pagination-medium-width-m-sizing: 40;
$es-controls-pagination-medium-all-m-border-radius: 4;
$es-controls-pagination-small-gap-m-spacing: 2;
$es-controls-pagination-small-height-m-sizing: 28;
$es-controls-pagination-small-width-m-sizing: 28;
$es-controls-pagination-small-all-m-border-radius: 4;
$es-controls-pagination-states-default-background-primary-color: #f7f7f7;
$es-controls-pagination-states-default-text-color: #1a1c1e;
$es-controls-pagination-states-default-icon-color: #b72913;
$es-controls-pagination-states-hover-background-primary-color: #d5341c;
$es-controls-pagination-states-hover-text-color: #ffffff;
$es-controls-pagination-states-hover-icon-color: #ffffff;
$es-controls-pagination-states-disabled-background-primary-color: #eff0f0;
$es-controls-pagination-states-disabled-text-color: #c1c2c3;
$es-controls-pagination-states-disabled-icon-color: #c1c2c3;
$es-controls-pagination-states-pressed-background-primary-color: #d5341c;
$es-controls-pagination-states-pressed-text-color: #ffffff;
$es-controls-pagination-states-pressed-icon-color: #ffffff;
$es-controls-chips-states-selected-default-background-primary-color: #ffffff;
$es-controls-chips-states-selected-default-background-secondary-color: #e5e5e6;
$es-controls-chips-states-selected-default-background-brand-color: #5d1509;
$es-controls-chips-states-selected-default-icon-secondary-color: #828282;
$es-controls-chips-states-selected-default-icon-primary-color: #b72913;
$es-controls-chips-states-selected-default-icon-on-brand-color: #ffffff;
$es-controls-chips-states-selected-default-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-selected-default-text-main-color: #1a1c1e;
$es-controls-chips-states-selected-default-text-on-brand-color: #ffffff;
$es-controls-chips-states-selected-hover-background-primary-color: #ffffff;
$es-controls-chips-states-selected-hover-background-secondary-color: #e5e5e6;
$es-controls-chips-states-selected-hover-background-brand-color: #5d1509;
$es-controls-chips-states-selected-hover-icon-primary-color: #d5341c;
$es-controls-chips-states-selected-hover-icon-secondary-color: #828282;
$es-controls-chips-states-selected-hover-icon-on-brand-color: #ffffff;
$es-controls-chips-states-selected-hover-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-selected-hover-text-main-color: #1a1c1e;
$es-controls-chips-states-selected-hover-text-on-brand-color: #ffffff;
$es-controls-chips-states-selected-pressed-background-primary-color: #c1c2c3;
$es-controls-chips-states-selected-pressed-background-secondary-color: #c1c2c3;
$es-controls-chips-states-selected-pressed-background-brand-color: #821d0d;
$es-controls-chips-states-selected-pressed-icon-secondary-color: #828282;
$es-controls-chips-states-selected-pressed-icon-primary-color: #821d0d;
$es-controls-chips-states-selected-pressed-icon-on-brand-color: #ffffff;
$es-controls-chips-states-selected-pressed-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-selected-pressed-text-main-color: #1a1c1e;
$es-controls-chips-states-selected-pressed-text-on-brand-color: #ffffff;
$es-controls-chips-states-selected-focused-background-primary-color: #ffffff;
$es-controls-chips-states-selected-focused-background-secondary-color: #e5e5e6;
$es-controls-chips-states-selected-focused-background-brand-color: #b72913;
$es-controls-chips-states-selected-focused-icon-secondary-color: #828282;
$es-controls-chips-states-selected-focused-icon-primary-color: #d5341c;
$es-controls-chips-states-selected-focused-icon-on-brand-color: #ffffff;
$es-controls-chips-states-selected-focused-border-color: #2264bf;
$es-controls-chips-states-selected-focused-text-main-color: #1a1c1e;
$es-controls-chips-states-selected-focused-text-on-brand-color: #ffffff;
$es-controls-chips-states-selected-dragged-background-primary-color: #ffffff;
$es-controls-chips-states-selected-dragged-background-secondary-color: #e5e5e6;
$es-controls-chips-states-selected-dragged-background-brand-color: #b72913;
$es-controls-chips-states-selected-dragged-icon-secondary-color: #828282;
$es-controls-chips-states-selected-dragged-icon-primary-color: #b72913;
$es-controls-chips-states-selected-dragged-icon-on-brand-color: #ffffff;
$es-controls-chips-states-selected-dragged-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-selected-dragged-text-main-color: #1a1c1e;
$es-controls-chips-states-selected-dragged-text-on-brand-color: #ffffff;
$es-controls-chips-states-selected-disabled-background-primary-color: #ebebeb;
$es-controls-chips-states-selected-disabled-background-secondary-color: #ebebeb;
$es-controls-chips-states-selected-disabled-background-brand-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-chips-states-selected-disabled-icon-secondary-color: #c1c2c3;
$es-controls-chips-states-selected-disabled-icon-primary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-chips-states-selected-disabled-icon-on-brand-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-chips-states-selected-disabled-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-selected-disabled-text-main-color: #c1c2c3;
$es-controls-chips-states-selected-disabled-text-on-brand-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-chips-states-unselected-default-background-primary-color: #e5e5e6;
$es-controls-chips-states-unselected-default-background-secondary-color: #ffffff;
$es-controls-chips-states-unselected-default-background-brand-color: #b72913;
$es-controls-chips-states-unselected-default-icon-secondary-color: #828282;
$es-controls-chips-states-unselected-default-icon-primary-color: #b72913;
$es-controls-chips-states-unselected-default-icon-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-default-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-unselected-default-text-main-color: #1a1c1e;
$es-controls-chips-states-unselected-default-text-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-hover-background-primary-color: #e5e5e6;
$es-controls-chips-states-unselected-hover-background-secondary-color: #ffffff;
$es-controls-chips-states-unselected-hover-background-brand-color: #d5341c;
$es-controls-chips-states-unselected-hover-icon-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-hover-icon-secondary-color: #828282;
$es-controls-chips-states-unselected-hover-icon-primary-color: #d5341c;
$es-controls-chips-states-unselected-hover-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-unselected-hover-text-main-color: #1a1c1e;
$es-controls-chips-states-unselected-hover-text-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-pressed-background-primary-color: #c1c2c3;
$es-controls-chips-states-unselected-pressed-background-secondary-color: #c1c2c3;
$es-controls-chips-states-unselected-pressed-background-brand-color: #821d0d;
$es-controls-chips-states-unselected-pressed-icon-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-pressed-icon-secondary-color: #828282;
$es-controls-chips-states-unselected-pressed-icon-primary-color: #821d0d;
$es-controls-chips-states-unselected-pressed-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-unselected-pressed-text-main-color: #1a1c1e;
$es-controls-chips-states-unselected-pressed-text-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-focused-background-primary-color: #e5e5e6;
$es-controls-chips-states-unselected-focused-background-secondary-color: #ffffff;
$es-controls-chips-states-unselected-focused-background-brand-color: #b72913;
$es-controls-chips-states-unselected-focused-icon-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-focused-icon-secondary-color: #828282;
$es-controls-chips-states-unselected-focused-icon-primary-color: #d5341c;
$es-controls-chips-states-unselected-focused-border-color: #2264bf;
$es-controls-chips-states-unselected-focused-text-main-color: #1a1c1e;
$es-controls-chips-states-unselected-focused-text-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-dragged-background-primary-color: #e5e5e6;
$es-controls-chips-states-unselected-dragged-background-secondary-color: #ffffff;
$es-controls-chips-states-unselected-dragged-background-brand-color: #b72913;
$es-controls-chips-states-unselected-dragged-icon-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-dragged-icon-secondary-color: #828282;
$es-controls-chips-states-unselected-dragged-icon-primary-color: #b72913;
$es-controls-chips-states-unselected-dragged-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-unselected-dragged-text-main-color: #1a1c1e;
$es-controls-chips-states-unselected-dragged-text-on-brand-color: #ffffff;
$es-controls-chips-states-unselected-disabled-background-primary-color: #ebebeb;
$es-controls-chips-states-unselected-disabled-background-secondary-color: #ebebeb;
$es-controls-chips-states-unselected-disabled-background-brand-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-chips-states-unselected-disabled-icon-on-brand-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-chips-states-unselected-disabled-icon-secondary-color: #c1c2c3;
$es-controls-chips-states-unselected-disabled-icon-primary-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-chips-states-unselected-disabled-border-color: rgba(0, 0, 0, 0);
$es-controls-chips-states-unselected-disabled-text-main-color: #c1c2c3;
$es-controls-chips-states-unselected-disabled-text-on-brand-color: rgba(
  255,
  255,
  255,
  0.6
);
$es-controls-chips-large-gap-m-spacing: 4;
$es-controls-chips-large-all-m-border-radius: 8;
$es-controls-chips-large-all-rounded-border-radius: 20;
$es-controls-chips-large-bottom-m-spacing: 8;
$es-controls-chips-large-top-m-spacing: 8;
$es-controls-chips-large-left-l-spacing: 12;
$es-controls-chips-large-left-m-spacing: 8;
$es-controls-chips-large-left-s-spacing: 4;
$es-controls-chips-large-right-l-spacing: 12;
$es-controls-chips-large-right-m-spacing: 8;
$es-controls-chips-large-right-s-spacing: 4;
$es-controls-chips-large-height-m-sizing: 40;
$es-controls-chips-medium-gap-m-spacing: 4;
$es-controls-chips-medium-all-m-border-radius: 8;
$es-controls-chips-medium-all-rounded-border-radius: 16;
$es-controls-chips-medium-bottom-m-spacing: 4;
$es-controls-chips-medium-top-m-spacing: 4;
$es-controls-chips-medium-left-m-spacing: 8;
$es-controls-chips-medium-left-s-spacing: 4;
$es-controls-chips-medium-right-m-spacing: 8;
$es-controls-chips-medium-right-s-spacing: 4;
$es-controls-chips-medium-height-m-sizing: 32;
$es-controls-chips-small-gap-m-spacing: 4;
$es-controls-chips-small-all-m-border-radius: 8;
$es-controls-chips-small-all-rounded-border-radius: 12;
$es-controls-chips-small-bottom-m-spacing: 4;
$es-controls-chips-small-top-m-spacing: 4;
$es-controls-chips-small-left-m-spacing: 4;
$es-controls-chips-small-left-l-spacing: 8;
$es-controls-chips-small-right-m-spacing: 4;
$es-controls-chips-small-right-l-spacing: 8;
$es-controls-chips-small-height-m-sizing: 24;
$es-controls-chips-border-m-border-width: 1;
$es-controls-list-large-gap-s-spacing: 4;
$es-controls-list-large-gap-m-spacing: 8;
$es-controls-list-large-all-m-border-radius: 4;
$es-controls-list-large-all-none-border-radius: 0;
$es-controls-list-large-bottom-s-spacing: 4;
$es-controls-list-large-bottom-m-spacing: 8;
$es-controls-list-large-bottom-l-spacing: 12;
$es-controls-list-large-bottom-xl-spacing: 16;
$es-controls-list-large-bottom-xxl-spacing: 20;
$es-controls-list-large-top-s-spacing: 4;
$es-controls-list-large-top-m-spacing: 8;
$es-controls-list-large-top-l-spacing: 12;
$es-controls-list-large-top-xl-spacing: 16;
$es-controls-list-large-top-xxl-spacing: 20;
$es-controls-list-large-left-s-spacing: 8;
$es-controls-list-large-left-m-spacing: 16;
$es-controls-list-large-left-l-spacing: 20;
$es-controls-list-large-left-xl-spacing: 24;
$es-controls-list-large-right-s-spacing: 8;
$es-controls-list-large-right-m-spacing: 16;
$es-controls-list-large-right-l-spacing: 20;
$es-controls-list-large-right-xl-spacing: 24;
$es-controls-list-large-height-m-sizing: 60;
$es-controls-list-medium-gap-m-spacing: 8;
$es-controls-list-medium-gap-s-spacing: 4;
$es-controls-list-medium-all-m-border-radius: 4;
$es-controls-list-medium-all-none-border-radius: 0;
$es-controls-list-medium-bottom-s-spacing: 4;
$es-controls-list-medium-bottom-m-spacing: 8;
$es-controls-list-medium-bottom-l-spacing: 12;
$es-controls-list-medium-bottom-xl-spacing: 16;
$es-controls-list-medium-top-s-spacing: 4;
$es-controls-list-medium-top-m-spacing: 8;
$es-controls-list-medium-top-l-spacing: 12;
$es-controls-list-medium-top-xl-spacing: 16;
$es-controls-list-medium-left-s-spacing: 4;
$es-controls-list-medium-left-m-spacing: 8;
$es-controls-list-medium-left-l-spacing: 12;
$es-controls-list-medium-left-xl-spacing: 16;
$es-controls-list-medium-right-s-spacing: 8;
$es-controls-list-medium-right-m-spacing: 8;
$es-controls-list-medium-right-l-spacing: 12;
$es-controls-list-medium-right-xl-spacing: 16;
$es-controls-list-medium-height-m-sizing: 44;
$es-controls-list-divider-m-border-width: 1;
$es-controls-list-divider-0-border-width: 1;
$es-controls-list-divider-l-border-width: 4;
$es-controls-list-small-gap-m-spacing: 8;
$es-controls-list-small-gap-s-spacing: 4;
$es-controls-list-small-all-m-border-radius: 4;
$es-controls-list-small-all-none-border-radius: 0;
$es-controls-list-small-bottom-s-spacing: 4;
$es-controls-list-small-bottom-m-spacing: 8;
$es-controls-list-small-bottom-l-spacing: 12;
$es-controls-list-small-bottom-xl-spacing: 16;
$es-controls-list-small-top-s-spacing: 4;
$es-controls-list-small-top-m-spacing: 8;
$es-controls-list-small-top-l-spacing: 12;
$es-controls-list-small-top-xl-spacing: 16;
$es-controls-list-small-left-s-spacing: 4;
$es-controls-list-small-left-m-spacing: 8;
$es-controls-list-small-left-l-spacing: 12;
$es-controls-list-small-left-xl-spacing: 16;
$es-controls-list-small-right-s-spacing: 4;
$es-controls-list-small-right-m-spacing: 8;
$es-controls-list-small-right-l-spacing: 12;
$es-controls-list-small-right-xl-spacing: 16;
$es-controls-list-small-height-m-sizing: 28;
$es-controls-list-small-height-l-sizing: 40;
$es-controls-list-states-default-icon-secondary-color: #828282;
$es-controls-list-states-default-icon-primary-color: #b72913;
$es-controls-list-states-default-divider-secondary-color: #c1c2c3;
$es-controls-list-states-default-divider-pimary-color: #eff0f0;
$es-controls-list-states-default-text-tertiary-color: #58975b;
$es-controls-list-states-default-text-quaternary-color: #ef6c00;
$es-controls-list-states-default-text-secondary-color: #828282;
$es-controls-list-states-default-text-primary-color: #1a1c1e;
$es-controls-list-states-default-background-tertiary-color: #f7f7f7;
$es-controls-list-states-default-background-secondary-color: #eff0f0;
$es-controls-list-states-default-background-primary-color: #ffffff;
$es-controls-list-states-hover-text-quaternary-color: #ef6c00;
$es-controls-list-states-hover-icon-secondary-color: #828282;
$es-controls-list-states-hover-icon-primary-color: #d5341c;
$es-controls-list-states-hover-divider-secondary-color: #c1c2c3;
$es-controls-list-states-hover-divider-primary-color: #eff0f0;
$es-controls-list-states-hover-text-tertiary-color: #58975b;
$es-controls-list-states-hover-text-secondary-color: #828282;
$es-controls-list-states-hover-text-primary-color: #1a1c1e;
$es-controls-list-states-hover-background-tertiary-color: #ebebeb;
$es-controls-list-states-hover-background-secondary-color: #d5d6d8;
$es-controls-list-states-hover-background-primary-color: #eff0f0;
$es-controls-list-states-selected-text-quaternary-color: #ef6c00;
$es-controls-list-states-selected-icon-secondary-color: #828282;
$es-controls-list-states-selected-icon-primary-color: #b72913;
$es-controls-list-states-selected-divider-secondary-color: #c1c2c3;
$es-controls-list-states-selected-divider-primary-color: #eff0f0;
$es-controls-list-states-selected-text-tertiary-color: #58975b;
$es-controls-list-states-selected-text-secondary-color: #828282;
$es-controls-list-states-selected-text-primary-color: #1a1c1e;
$es-controls-list-states-selected-background-tertiary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-list-states-selected-background-secondary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-list-states-selected-background-primary-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-list-states-dragged-text-quaternary-color: #ef6c00;
$es-controls-list-states-dragged-icon-secondary-color: #828282;
$es-controls-list-states-dragged-icon-primary-color: #b72913;
$es-controls-list-states-dragged-divider-secondary-color: #c1c2c3;
$es-controls-list-states-dragged-divider-primary-color: #eff0f0;
$es-controls-list-states-dragged-text-tertiary-color: #58975b;
$es-controls-list-states-dragged-text-secondary-color: #828282;
$es-controls-list-states-dragged-text-primary-color: #1a1c1e;
$es-controls-list-states-dragged-background-tertiary-color: #ffffff;
$es-controls-list-states-dragged-background-secondary-color: #e5e5e6;
$es-controls-list-states-dragged-background-primary-color: #ffffff;
$es-controls-list-states-disabled-text-quaternary-color: #ef6c00;
$es-controls-list-states-disabled-icon-secondary-color: #d5d6d8;
$es-controls-list-states-disabled-icon-primary-color: #c1c2c3;
$es-controls-list-states-disabled-divider-secondary-color: #c1c2c3;
$es-controls-list-states-disabled-divider-primary-color: #f7f7f7;
$es-controls-list-states-disabled-text-tertiary-color: #828282;
$es-controls-list-states-disabled-text-secondary-color: #828282;
$es-controls-list-states-disabled-text-primary-color: #828282;
$es-controls-list-states-disabled-background-tertiary-color: #f7f7f7;
$es-controls-list-states-disabled-background-secondary-color: #f7f7f7;
$es-controls-list-states-disabled-background-primary-color: #f7f7f7;
$es-controls-expansion-panel-large-block-left-bottom-m-spacing: 8;
$es-controls-expansion-panel-large-block-left-top-m-spacing: 8;
$es-controls-expansion-panel-large-block-left-left-m-spacing: 16;
$es-controls-expansion-panel-large-block-left-left-l-spacing: 32;
$es-controls-expansion-panel-large-block-left-right-m-spacing: 12;
$es-controls-expansion-panel-large-block-left-height-m-sizing: 100;
$es-controls-expansion-panel-large-block-left-gap-s-spacing: 4;
$es-controls-expansion-panel-large-block-left-gap-m-spacing: 12;
$es-controls-expansion-panel-large-block-right-bottom-m-spacing: 8;
$es-controls-expansion-panel-large-block-right-top-m-spacing: 8;
$es-controls-expansion-panel-large-block-right-left-m-spacing: 16;
$es-controls-expansion-panel-large-block-right-right-m-spacing: 12;
$es-controls-expansion-panel-large-block-right-height-m-sizing: 100;
$es-controls-expansion-panel-large-block-right-gap-s-spacing: 4;
$es-controls-expansion-panel-large-block-right-gap-m-spacing: 12;
$es-controls-expansion-panel-large-all-m-border-radius: 4;
$es-controls-expansion-panel-large-top-left-m-border-radius: 4;
$es-controls-expansion-panel-large-top-right-m-border-radius: 4;
$es-controls-expansion-panel-large-bottom-right-m-border-radius: 4;
$es-controls-expansion-panel-large-bottom-left-m-border-radius: 4;
$es-controls-expansion-panel-large-marker-weight-m-sizing: 12;
$es-controls-expansion-panel-medium-block-left-gap-m-spacing: 8;
$es-controls-expansion-panel-medium-block-left-gap-s-spacing: 4;
$es-controls-expansion-panel-medium-block-left-bottom-m-spacing: 4;
$es-controls-expansion-panel-medium-block-left-top-m-spacing: 4;
$es-controls-expansion-panel-medium-block-left-left-m-spacing: 12;
$es-controls-expansion-panel-medium-block-left-left-l-spacing: 20;
$es-controls-expansion-panel-medium-block-left-right-m-spacing: 8;
$es-controls-expansion-panel-medium-block-left-height-m-sizing: 76;
$es-controls-expansion-panel-medium-all-m-border-radius: 4;
$es-controls-expansion-panel-medium-top-left-m-border-radius-m-border-radius: 4;
$es-controls-expansion-panel-medium-top-right-m-border-radius: 4;
$es-controls-expansion-panel-medium-bottom-right-m-border-radius: 4;
$es-controls-expansion-panel-medium-bottom-left-m-border-radius: 4;
$es-controls-expansion-panel-medium-marker-weight-m-sizing: 8;
$es-controls-expansion-panel-medium-block-right-gap-m-spacing: 12;
$es-controls-expansion-panel-medium-block-right-gap-s-spacing: 4;
$es-controls-expansion-panel-medium-block-right-bottom-m-spacing: 4;
$es-controls-expansion-panel-medium-block-right-top-m-spacing: 4;
$es-controls-expansion-panel-medium-block-right-left-m-spacing: 8;
$es-controls-expansion-panel-medium-block-right-right-m-spacing: 16;
$es-controls-expansion-panel-medium-block-right-height-m-sizing: 76;
$es-controls-expansion-panel-small-block-left-gap-m-spacing: 8;
$es-controls-expansion-panel-small-block-left-gap-s-spacing: 4;
$es-controls-expansion-panel-small-block-left-bottom-m-spacing: 4;
$es-controls-expansion-panel-small-block-left-top-m-spacing: 4;
$es-controls-expansion-panel-small-block-left-left-m-spacing: 12;
$es-controls-expansion-panel-small-block-left-right-m-spacing: 8;
$es-controls-expansion-panel-small-block-left-height-m-sizing: 40;
$es-controls-expansion-panel-small-block-right-gap-m-spacing: 8;
$es-controls-expansion-panel-small-block-right-gap-s-spacing: 4;
$es-controls-expansion-panel-small-block-right-bottom-m-spacing: 4;
$es-controls-expansion-panel-small-block-right-top-m-spacing: 4;
$es-controls-expansion-panel-small-block-right-left-m-spacing: 4;
$es-controls-expansion-panel-small-block-right-right-m-spacing: 8;
$es-controls-expansion-panel-small-block-right-height-m-sizing: 40;
$es-controls-expansion-panel-small-all-m-border-radius: 4;
$es-controls-expansion-panel-small-top-left-m-border-radius: 4;
$es-controls-expansion-panel-small-top-right-m-border-radius: 4;
$es-controls-expansion-panel-small-bottom-right-m-border-radius: 4;
$es-controls-expansion-panel-small-bottom-left-m-border-radius: 4;
$es-controls-expansion-panel-small-marker-weight-m-sizing: 8;
$es-controls-expansion-panel-states-default-icon-primary-color: #b72913;
$es-controls-expansion-panel-states-default-icon-secondary-color: #1f2123;
$es-controls-expansion-panel-states-default-icon-tertiary-color: #828282;
$es-controls-expansion-panel-states-default-line-color: #d5d6d8;
$es-controls-expansion-panel-states-default-text-description-color: #828282;
$es-controls-expansion-panel-states-default-text-title-color: #1a1c1e;
$es-controls-expansion-panel-states-default-backgound-header-color: #ffffff;
$es-controls-expansion-panel-states-default-backgound-success-color: #58975b;
$es-controls-expansion-panel-states-default-backgound-body-primary-color: #ffffff;
$es-controls-expansion-panel-states-default-backgound-body-secondary-color: #eff0f0;
$es-controls-expansion-panel-states-hover-icon-primary-color: #d5341c;
$es-controls-expansion-panel-states-hover-icon-secondary-color: #1f2123;
$es-controls-expansion-panel-states-hover-icon-tertiary-color: #828282;
$es-controls-expansion-panel-states-hover-line-color: #d5d6d8;
$es-controls-expansion-panel-states-hover-text-description-color: #828282;
$es-controls-expansion-panel-states-hover-text-title-color: #1a1c1e;
$es-controls-expansion-panel-states-hover-backgound-header-color: #eff0f0;
$es-controls-expansion-panel-states-hover-backgound-success-color: #58975b;
$es-controls-expansion-panel-states-hover-backgound-body-primary-color: #ffffff;
$es-controls-expansion-panel-states-hover-backgound-body-secondary-color: #eff0f0;
$es-controls-expansion-panel-states-focused-icon-primary-color: #d5341c;
$es-controls-expansion-panel-states-focused-icon-secondary-color: #1f2123;
$es-controls-expansion-panel-states-focused-icon-tertiary-color: #828282;
$es-controls-expansion-panel-states-focused-line-color: #d5d6d8;
$es-controls-expansion-panel-states-focused-text-description-color: #828282;
$es-controls-expansion-panel-states-focused-text-title-color: #1a1c1e;
$es-controls-expansion-panel-states-focused-backgound-header-color: #e5e5e6;
$es-controls-expansion-panel-states-focused-backgound-success-color: rgba(
  46,
  125,
  50,
  0.3
);
$es-controls-expansion-panel-states-focused-backgound-body-primary-color: #ffffff;
$es-controls-expansion-panel-states-focused-backgound-body-secondary-color: #eff0f0;
$es-controls-expansion-panel-states-pressed-opened-icon-primary-color: #821d0d;
$es-controls-expansion-panel-states-pressed-opened-icon-secondary-color: #b72913;
$es-controls-expansion-panel-states-pressed-opened-icon-tertiary-color: #828282;
$es-controls-expansion-panel-states-pressed-opened-line-color: #d5d6d8;
$es-controls-expansion-panel-states-pressed-opened-text-description-color: #b72913;
$es-controls-expansion-panel-states-pressed-opened-text-title-color: #b72913;
$es-controls-expansion-panel-states-pressed-opened-backgound-header-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-expansion-panel-states-pressed-opened-backgound-success-color: #58975b;
$es-controls-expansion-panel-states-pressed-opened-backgound-body-primary-color: #ffffff;
$es-controls-expansion-panel-states-pressed-opened-backgound-body-secondary-color: #eff0f0;
$es-controls-expansion-panel-states-disabled-icon-primary-color: #c1c2c3;
$es-controls-expansion-panel-states-disabled-icon-secondary-color: #c1c2c3;
$es-controls-expansion-panel-states-disabled-icon-tertiary-color: #c1c2c3;
$es-controls-expansion-panel-states-disabled-line-color: #d5d6d8;
$es-controls-expansion-panel-states-disabled-text-description-color: #c1c2c3;
$es-controls-expansion-panel-states-disabled-text-title-color: #c1c2c3;
$es-controls-expansion-panel-states-disabled-backgound-header-color: #eff0f0;
$es-controls-expansion-panel-states-disabled-backgound-success-color: rgba(
  46,
  125,
  50,
  0.5
);
$es-controls-expansion-panel-states-disabled-backgound-body-primary-color: #ffffff;
$es-controls-expansion-panel-states-disabled-backgound-body-secondary-color: #ffffff;
$es-controls-scrollbar-medium-track-top-m-spacing: 4;
$es-controls-scrollbar-medium-track-left-m-spacing: 4;
$es-controls-scrollbar-medium-track-bottom-m-spacing: 4;
$es-controls-scrollbar-medium-track-right-m-spacing: 4;
$es-controls-scrollbar-medium-track-height-horizontal-m-sizing: 10;
$es-controls-scrollbar-medium-track-weight-vertical-m-sizing: 10;
$es-controls-scrollbar-medium-track-height-horizontal-l-sizing: 12;
$es-controls-scrollbar-medium-track-weight-vertical-l-sizing: 12;
$es-controls-scrollbar-medium-track-all-m-border-radius: 4;
$es-controls-scrollbar-medium-thumb-height-horizontal-m-sizing: 8;
$es-controls-scrollbar-medium-thumb-weight-vertical-m-sizing: 8;
$es-controls-scrollbar-medium-thumb-height-horizontal-l-sizing: 12;
$es-controls-scrollbar-medium-thumb-weight-vertical-l-sizing: 12;
$es-controls-scrollbar-medium-thumb-all-m-border-radius: 4;
$es-controls-scrollbar-medium-button-height-m-sizing: 12;
$es-controls-scrollbar-medium-button-weight-m-sizing: 12;
$es-controls-scrollbar-states-default-resizer-color: #828282;
$es-controls-scrollbar-states-default-thumb-brand-color: #b72913;
$es-controls-scrollbar-states-default-thumb-primary-color: #c1c2c3;
$es-controls-scrollbar-states-default-button-color: #c1c2c3;
$es-controls-scrollbar-states-default-track-secondary-color: #eff0f0;
$es-controls-scrollbar-states-default-track-color: #ffffff;
$es-controls-scrollbar-states-hover-resizer-color: #464749;
$es-controls-scrollbar-states-hover-thumb-brand-color: #d5341c;
$es-controls-scrollbar-states-hover-thumb-primary-color: #828282;
$es-controls-scrollbar-states-hover-button-color: #121416;
$es-controls-scrollbar-states-hover-track-secondary-color: #eff0f0;
$es-controls-scrollbar-states-hover-track-color: #eff0f0;
$es-controls-scrollbar-states-pressed-resizer-color: #1f2123;
$es-controls-scrollbar-states-pressed-thumb-brand-color: #821d0d;
$es-controls-scrollbar-states-pressed-thumb-primary-color: #121416;
$es-controls-scrollbar-states-pressed-button-color: #ffffff;
$es-controls-scrollbar-states-pressed-track-secondary-color: #121416;
$es-controls-scrollbar-states-pressed-track-color: #121416;
$es-controls-scrollbar-states-disabled-resizer-color: #d5d6d8;
$es-controls-scrollbar-states-disabled-thumb-brand-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-scrollbar-states-disabled-thumb-primary-color: #f7f7f7;
$es-controls-scrollbar-states-disabled-button-color: #ffffff;
$es-controls-scrollbar-states-disabled-track-secondary-color: #ffffff;
$es-controls-scrollbar-states-disabled-track-color: #ffffff;
$es-controls-radio-medium-container-height-m-sizing: 24;
$es-controls-radio-medium-container-weight-m-sizing: 24;
$es-controls-radio-medium-container-all-m-border-radius: 12;
$es-controls-radio-medium-btn-height-m-sizing: 16;
$es-controls-radio-medium-btn-weight-m-sizing: 16;
$es-controls-radio-medium-btn-all-m-border-radius: 8;
$es-controls-radio-medium-btn-height-s-sizing: 12;
$es-controls-radio-medium-btn-weight-s-sizing: 12;
$es-controls-radio-medium-btn-all-s-border-radius: 4;
$es-controls-radio-small-container-height-m-sizing: 16;
$es-controls-radio-small-container-weight-m-sizing: 16;
$es-controls-radio-small-container-all-m-border-radius: 8;
$es-controls-radio-small-btn-height-m-sizing: 8;
$es-controls-radio-small-btn-weight-m-sizing: 8;
$es-controls-radio-small-btn-all-m-border-radius: 4;
$es-controls-radio-states-default-icon-color: #b72913;
$es-controls-radio-states-default-border-color: #b72913;
$es-controls-radio-states-default-background-primary-color: #ffffff;
$es-controls-radio-states-hover-icon-color: #d5341c;
$es-controls-radio-states-hover-border-color: #d5341c;
$es-controls-radio-states-hover-background-primary-color: #ffffff;
$es-controls-radio-states-disabled-icon-color: rgba(183, 41, 19, 0.3);
$es-controls-radio-states-disabled-border-color: rgba(183, 41, 19, 0.3);
$es-controls-radio-states-disabled-background-primary-color: #ffffff;
$es-controls-radio-stroke-m-border-width: 1;
$es-controls-switch-states-active-default-background-btn-color: #b72913;
$es-controls-switch-states-active-default-border-container-color: #828282;
$es-controls-switch-states-active-default-background-container-color: #ffffff;
$es-controls-switch-states-active-hover-background-btn-color: #d5341c;
$es-controls-switch-states-active-hover-border-container-color: #d5341c;
$es-controls-switch-states-active-hover-background-container-color: #f7f7f7;
$es-controls-switch-states-active-disabled-background-btn-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-switch-states-active-disabled-border-container-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-controls-switch-states-active-disabled-background-container-color: #eff0f0;
$es-controls-switch-states-inactive-default-background-btn-color: #464749;
$es-controls-switch-states-inactive-default-border-container-color: #828282;
$es-controls-switch-states-inactive-default-background-container-color: #ffffff;
$es-controls-switch-states-inactive-hover-background-btn-color: #1f2123;
$es-controls-switch-states-inactive-hover-border-container-color: #1a1c1e;
$es-controls-switch-states-inactive-hover-background-container-color: #f7f7f7;
$es-controls-switch-states-inactive-disabled-background-btn-color: #c1c2c3;
$es-controls-switch-states-inactive-disabled-border-container-color: #c1c2c3;
$es-controls-switch-states-inactive-disabled-background-container-color: #eff0f0;
$es-controls-switch-medium-container-top-m-spacing: 4;
$es-controls-switch-medium-container-bottom-m-spacing: 4;
$es-controls-switch-medium-container-left-m-spacing: 4;
$es-controls-switch-medium-container-right-m-spacing: 4;
$es-controls-switch-medium-container-height-m-sizing: 24;
$es-controls-switch-medium-container-weight-m-sizing: 44;
$es-controls-switch-medium-container-all-m-border-radius: 12;
$es-controls-switch-medium-btn-height-m-sizing: 16;
$es-controls-switch-medium-btn-weight-m-sizing: 16;
$es-controls-switch-medium-btn-all-m-border-radius: 8;
$es-controls-switch-stroke-m-border-width: 1;
$es-controls-switch-large-container-top-m-spacing: 2;
$es-controls-switch-large-container-bottom-m-spacing: 2;
$es-controls-switch-large-container-left-m-spacing: 2;
$es-controls-switch-large-container-right-m-spacing: 2;
$es-controls-switch-large-container-height-m-sizing: 28;
$es-controls-switch-large-container-weight-m-sizing: 52;
$es-controls-switch-large-container-all-m-border-radius: 14;
$es-controls-switch-large-btn-height-m-sizing: 24;
$es-controls-switch-large-btn-weight-m-sizing: 24;
$es-controls-switch-large-btn-all-m-border-radius: 12;
$es-controls-switch-small-container-top-m-spacing: 2;
$es-controls-switch-small-container-bottom-m-spacing: 2;
$es-controls-switch-small-container-left-m-spacing: 2;
$es-controls-switch-small-container-right-m-spacing: 2;
$es-controls-switch-small-container-height-m-sizing: 12;
$es-controls-switch-small-container-weight-m-sizing: 24;
$es-controls-switch-small-container-all-m-border-radius: 6;
$es-controls-switch-small-btn-height-m-sizing: 8;
$es-controls-switch-small-btn-weight-m-sizing: 8;
$es-controls-switch-small-btn-all-m-border-radius: 4;
$es-controls-check-state-default-icon-color: #b72913;
$es-controls-check-state-default-icon-secondary-color: rgba(183, 41, 19, 0.4);
$es-controls-check-state-default-border-color: #b72913;
$es-controls-check-state-default-background-primary-color: #ffffff;
$es-controls-check-state-hover-icon-secondary-color: #d5341c;
$es-controls-check-state-hover-icon-color: #d5341c;
$es-controls-check-state-hover-border-color: #d5341c;
$es-controls-check-state-hover-background-primary-color: #ffffff;
$es-controls-check-state-disabled-icon-secondary-color: rgba(183, 41, 19, 0.08);
$es-controls-check-state-disabled-icon-color: rgba(183, 41, 19, 0.3);
$es-controls-check-state-disabled-border-color: rgba(183, 41, 19, 0.3);
$es-controls-check-state-disabled-background-primary-color: #ffffff;
$es-controls-check-medium-height-m-sizing: 20;
$es-controls-check-medium-weight-m-sizing: 20;
$es-controls-check-medium-all-m-border-radius: 2;
$es-controls-check-stroke-m-border-width: 2;
$es-controls-check-stroke-s-border-width: 1;
$es-controls-check-small-height-m-sizing: 16;
$es-controls-check-small-weight-m-sizing: 16;
$es-controls-check-small-all-m-border-radius: 2;
$es-controls-slider-medium-track-gap-xs-spacing: 4;
$es-controls-slider-medium-track-gap-s-spacing: 8;
$es-controls-slider-medium-track-gap-m-spacing: 12;
$es-controls-slider-medium-track-bottom-m-spacing: 4;
$es-controls-slider-medium-track-top-m-spacing: 4;
$es-controls-slider-medium-track-left-m-spacing: 4;
$es-controls-slider-medium-track-right-m-spacing: 4;
$es-controls-slider-medium-track-height-horizontal-m-sizing: 8;
$es-controls-slider-medium-track-weight-vertical-m-sizing: 8;
$es-controls-slider-medium-track-all-m-border-radius: 2;
$es-controls-slider-medium-thumb-height-horizontal-m-sizing: 8;
$es-controls-slider-medium-thumb-weight-vertical-m-sizing: 8;
$es-controls-slider-medium-thumb-height-horizontal-l-sizing: 12;
$es-controls-slider-medium-thumb-weight-vertical-l-sizing: 12;
$es-controls-slider-medium-thumb-all-m-border-radius: 4;
$es-controls-slider-medium-button-height-m-sizing: 20;
$es-controls-slider-medium-button-weight-m-sizing: 20;
$es-controls-slider-states-default-thumb-brand-color: #b72913;
$es-controls-slider-states-default-thumb-primary-color: #c1c2c3;
$es-controls-slider-states-default-icon-primary-color: #828282;
$es-controls-slider-states-default-button-color: #b72913;
$es-controls-slider-states-default-track-color: #e5e5e6;
$es-controls-slider-states-default-background-primary-color: #ffffff;
$es-controls-modal-medium-all-m-border-radius: 12;
$es-controls-modal-medium-top-xxxxs-spacing: 1;
$es-controls-modal-medium-top-xxxs-spacing: 2;
$es-controls-modal-medium-top-xxs-spacing: 4;
$es-controls-modal-medium-top-xs-spacing: 8;
$es-controls-modal-medium-top-s-spacing: 12;
$es-controls-modal-medium-top-m-spacing: 16;
$es-controls-modal-medium-top-l-spacing: 20;
$es-controls-modal-medium-top-xl-spacing: 24;
$es-controls-modal-medium-top-xxl-spacing: 28;
$es-controls-modal-medium-top-xxxl-spacing: 32;
$es-controls-modal-medium-top-xxxxl-spacing: 36;
$es-controls-modal-medium-top-5-xl-spacing: 40;
$es-controls-modal-medium-top-6-xl-spacing: 44;
$es-controls-modal-medium-top-7-xl-spacing: 48;
$es-controls-modal-medium-top-8-xl-spacing: 52;
$es-controls-modal-medium-top-9-xl-spacing: 56;
$es-controls-modal-medium-top-10-xl-spacing: 60;
$es-controls-modal-medium-top-11-xl-spacing: 64;
$es-controls-modal-medium-top-12-xl-spacing: 68;
$es-controls-modal-medium-top-13-xl-spacing: 72;
$es-controls-modal-medium-top-14-xl-spacing: 76;
$es-controls-modal-medium-top-15-xl-spacing: 80;
$es-controls-modal-medium-top-16-xl-spacing: 84;
$es-controls-modal-medium-top-17-xl-spacing: 88;
$es-controls-modal-medium-top-18-xl-spacing: 92;
$es-controls-modal-medium-top-19-xl-spacing: 96;
$es-controls-modal-medium-top-20-xl-spacing: 100;
$es-controls-modal-medium-bottom-xxxxs-spacing: 1;
$es-controls-modal-medium-bottom-xxxs-spacing: 2;
$es-controls-modal-medium-bottom-xxs-spacing: 4;
$es-controls-modal-medium-bottom-xs-spacing: 8;
$es-controls-modal-medium-bottom-s-spacing: 12;
$es-controls-modal-medium-bottom-m-spacing: 16;
$es-controls-modal-medium-bottom-l-spacing: 20;
$es-controls-modal-medium-bottom-xl-spacing: 24;
$es-controls-modal-medium-bottom-xxl-spacing: 28;
$es-controls-modal-medium-bottom-xxxl-spacing: 32;
$es-controls-modal-medium-bottom-xxxxl-spacing: 36;
$es-controls-modal-medium-bottom-5-xl-spacing: 40;
$es-controls-modal-medium-bottom-6-xl-spacing: 44;
$es-controls-modal-medium-bottom-7-xl-spacing: 48;
$es-controls-modal-medium-bottom-8-xl-spacing: 52;
$es-controls-modal-medium-bottom-9-xl-spacing: 56;
$es-controls-modal-medium-bottom-10-xl-spacing: 60;
$es-controls-modal-medium-bottom-11-xl-spacing: 64;
$es-controls-modal-medium-bottom-12-xl-spacing: 68;
$es-controls-modal-medium-bottom-13-xl-spacing: 72;
$es-controls-modal-medium-bottom-14-xl-spacing: 76;
$es-controls-modal-medium-bottom-15-xl-spacing: 80;
$es-controls-modal-medium-bottom-16-xl-spacing: 84;
$es-controls-modal-medium-bottom-17-xl-spacing: 88;
$es-controls-modal-medium-bottom-18-xl-spacing: 92;
$es-controls-modal-medium-bottom-19-xl-spacing: 96;
$es-controls-modal-medium-bottom-20-xl-spacing: 100;
$es-controls-modal-medium-left-xxxxs-spacing: 1;
$es-controls-modal-medium-left-xxxs-spacing: 2;
$es-controls-modal-medium-left-xxs-spacing: 4;
$es-controls-modal-medium-left-xs-spacing: 8;
$es-controls-modal-medium-left-s-spacing: 12;
$es-controls-modal-medium-left-m-spacing: 16;
$es-controls-modal-medium-left-l-spacing: 20;
$es-controls-modal-medium-left-xl-spacing: 24;
$es-controls-modal-medium-left-xxl-spacing: 28;
$es-controls-modal-medium-left-xxxl-spacing: 32;
$es-controls-modal-medium-left-xxxxl-spacing: 36;
$es-controls-modal-medium-left-5-xl-spacing: 40;
$es-controls-modal-medium-left-6-xl-spacing: 44;
$es-controls-modal-medium-left-7-xl-spacing: 48;
$es-controls-modal-medium-left-8-xl-spacing: 52;
$es-controls-modal-medium-left-9-xl-spacing: 56;
$es-controls-modal-medium-left-10-xl-spacing: 60;
$es-controls-modal-medium-left-11-xl-spacing: 64;
$es-controls-modal-medium-left-12-xl-spacing: 68;
$es-controls-modal-medium-left-13-xl-spacing: 72;
$es-controls-modal-medium-left-14-xl-spacing: 76;
$es-controls-modal-medium-left-15-xl-spacing: 80;
$es-controls-modal-medium-left-16-xl-spacing: 84;
$es-controls-modal-medium-left-17-xl-spacing: 88;
$es-controls-modal-medium-left-18-xl-spacing: 92;
$es-controls-modal-medium-left-19-xl-spacing: 96;
$es-controls-modal-medium-left-20-xl-spacing: 100;
$es-controls-modal-medium-left-margin-spacing: 360;
$es-controls-modal-medium-right-xxxxs-spacing: 1;
$es-controls-modal-medium-right-xxxs-spacing: 2;
$es-controls-modal-medium-right-xxs-spacing: 4;
$es-controls-modal-medium-right-xs-spacing: 8;
$es-controls-modal-medium-right-s-spacing: 12;
$es-controls-modal-medium-right-m-spacing: 16;
$es-controls-modal-medium-right-l-spacing: 20;
$es-controls-modal-medium-right-xl-spacing: 24;
$es-controls-modal-medium-right-xxl-spacing: 28;
$es-controls-modal-medium-right-xxxl-spacing: 32;
$es-controls-modal-medium-right-xxxxl-spacing: 36;
$es-controls-modal-medium-right-5-xl-spacing: 40;
$es-controls-modal-medium-right-6-xl-spacing: 44;
$es-controls-modal-medium-right-7-xl-spacing: 48;
$es-controls-modal-medium-right-8-xl-spacing: 52;
$es-controls-modal-medium-right-9-xl-spacing: 56;
$es-controls-modal-medium-right-10-xl-spacing: 60;
$es-controls-modal-medium-right-11-xl-spacing: 64;
$es-controls-modal-medium-right-12-xl-spacing: 68;
$es-controls-modal-medium-right-13-xl-spacing: 72;
$es-controls-modal-medium-right-14-xl-spacing: 76;
$es-controls-modal-medium-right-15-xl-spacing: 80;
$es-controls-modal-medium-right-16-xl-spacing: 84;
$es-controls-modal-medium-right-17-xl-spacing: 88;
$es-controls-modal-medium-right-18-xl-spacing: 92;
$es-controls-modal-medium-right-19-xl-spacing: 96;
$es-controls-modal-medium-right-20-xl-spacing: 100;
$es-controls-modal-medium-right-margin-spacing: 360;
$es-controls-modal-medium-gap-vertical-xxxxs-spacing: 1;
$es-controls-modal-medium-gap-vertical-xxxs-spacing: 2;
$es-controls-modal-medium-gap-vertical-xxs-spacing: 4;
$es-controls-modal-medium-gap-vertical-xs-spacing: 8;
$es-controls-modal-medium-gap-vertical-s-spacing: 12;
$es-controls-modal-medium-gap-vertical-m-spacing: 16;
$es-controls-modal-medium-gap-vertical-l-spacing: 20;
$es-controls-modal-medium-gap-vertical-xl-spacing: 24;
$es-controls-modal-medium-gap-vertical-xxl-spacing: 28;
$es-controls-modal-medium-gap-vertical-xxxl-spacing: 32;
$es-controls-modal-medium-gap-vertical-xxxxl-spacing: 36;
$es-controls-modal-medium-gap-vertical-5-xl-spacing: 40;
$es-controls-modal-medium-gap-vertical-6-xl-spacing: 44;
$es-controls-modal-medium-gap-vertical-7-xl-spacing: 48;
$es-controls-modal-medium-gap-vertical-8-xl-spacing: 52;
$es-controls-modal-medium-gap-vertical-9-xl-spacing: 56;
$es-controls-modal-medium-gap-vertical-10-xl-spacing: 60;
$es-controls-modal-medium-gap-vertical-11-xl-spacing: 64;
$es-controls-modal-medium-gap-vertical-12-xl-spacing: 68;
$es-controls-modal-medium-gap-vertical-13-xl-spacing: 72;
$es-controls-modal-medium-gap-vertical-14-xl-spacing: 76;
$es-controls-modal-medium-gap-vertical-15-xl-spacing: 80;
$es-controls-modal-medium-gap-vertical-16-xl-spacing: 84;
$es-controls-modal-medium-gap-vertical-17-xl-spacing: 88;
$es-controls-modal-medium-gap-vertical-18-xl-spacing: 92;
$es-controls-modal-medium-gap-vertical-19-xl-spacing: 96;
$es-controls-modal-medium-gap-vertical-20-xl-spacing: 100;
$es-controls-modal-medium-gap-horizontal-xxxxs-spacing: 1;
$es-controls-modal-medium-gap-horizontal-xxxs-spacing: 2;
$es-controls-modal-medium-gap-horizontal-xxs-spacing: 4;
$es-controls-modal-medium-gap-horizontal-xs-spacing: 8;
$es-controls-modal-medium-gap-horizontal-s-spacing: 12;
$es-controls-modal-medium-gap-horizontal-m-spacing: 16;
$es-controls-modal-medium-gap-horizontal-l-spacing: 20;
$es-controls-modal-medium-gap-horizontal-xl-spacing: 24;
$es-controls-modal-medium-gap-horizontal-xxl-spacing: 28;
$es-controls-modal-medium-gap-horizontal-xxxl-spacing: 32;
$es-controls-modal-medium-gap-horizontal-xxxxl-spacing: 36;
$es-controls-modal-medium-gap-horizontal-5-xl-spacing: 40;
$es-controls-modal-medium-gap-horizontal-6-xl-spacing: 44;
$es-controls-modal-medium-gap-horizontal-7-xl-spacing: 48;
$es-controls-modal-medium-gap-horizontal-8-xl-spacing: 52;
$es-controls-modal-medium-gap-horizontal-9-xl-spacing: 56;
$es-controls-modal-medium-gap-horizontal-10-xl-spacing: 60;
$es-controls-modal-medium-gap-horizontal-11-xl-spacing: 64;
$es-controls-modal-medium-gap-horizontal-12-xl-spacing: 68;
$es-controls-modal-medium-gap-horizontal-13-xl-spacing: 72;
$es-controls-modal-medium-gap-horizontal-14-xl-spacing: 76;
$es-controls-modal-medium-gap-horizontal-15-xl-spacing: 80;
$es-controls-modal-medium-gap-horizontal-16-xl-spacing: 84;
$es-controls-modal-medium-gap-horizontal-17-xl-spacing: 88;
$es-controls-modal-medium-gap-horizontal-18-xl-spacing: 92;
$es-controls-modal-medium-gap-horizontal-19-xl-spacing: 96;
$es-controls-modal-medium-gap-horizontal-20-xl-spacing: 100;
$es-controls-modal-medium-corner-all-none-border-radius: 0;
$es-controls-modal-medium-corner-all-xxxs-border-radius: 2;
$es-controls-modal-medium-corner-all-xxs-border-radius: 4;
$es-controls-modal-medium-corner-all-xs-border-radius: 6;
$es-controls-modal-medium-corner-all-s-border-radius: 8;
$es-controls-modal-medium-corner-all-m-border-radius: 12;
$es-controls-modal-medium-corner-all-l-border-radius: 16;
$es-controls-modal-medium-corner-top-left-none-border-radius: 0;
$es-controls-modal-medium-corner-top-left-xxxs-border-radius: 2;
$es-controls-modal-medium-corner-top-left-xxs-border-radius: 4;
$es-controls-modal-medium-corner-top-left-xs-border-radius: 6;
$es-controls-modal-medium-corner-top-left-s-border-radius: 8;
$es-controls-modal-medium-corner-top-left-m-border-radius: 12;
$es-controls-modal-medium-corner-top-left-l-border-radius: 16;
$es-controls-modal-medium-corner-bottom-left-none-border-radius: 0;
$es-controls-modal-medium-corner-bottom-left-xxxs-border-radius: 2;
$es-controls-modal-medium-corner-bottom-left-xxs-border-radius: 4;
$es-controls-modal-medium-corner-bottom-left-xs-border-radius: 6;
$es-controls-modal-medium-corner-bottom-left-s-border-radius: 8;
$es-controls-modal-medium-corner-bottom-left-m-border-radius: 12;
$es-controls-modal-medium-corner-bottom-left-l-border-radius: 16;
$es-controls-modal-medium-corner-top-right-none-border-radius: 0;
$es-controls-modal-medium-corner-top-right-xxxs-border-radius: 2;
$es-controls-modal-medium-corner-top-right-xxs-border-radius: 4;
$es-controls-modal-medium-corner-top-right-xs-border-radius: 6;
$es-controls-modal-medium-corner-top-right-s-border-radius: 8;
$es-controls-modal-medium-corner-top-right-m-border-radius: 12;
$es-controls-modal-medium-corner-top-right-l-border-radius: 16;
$es-controls-modal-medium-corner-bottom-right-none-border-radius: 0;
$es-controls-modal-medium-corner-bottom-right-xxxs-border-radius: 2;
$es-controls-modal-medium-corner-bottom-right-xxs-border-radius: 4;
$es-controls-modal-medium-corner-bottom-right-xs-border-radius: 6;
$es-controls-modal-medium-corner-bottom-right-s-border-radius: 8;
$es-controls-modal-medium-corner-bottom-right-m-border-radius: 12;
$es-controls-modal-medium-corner-bottom-right-l-border-radius: 16;
$es-controls-modal-medium-border-s-border-width: 1;
$es-controls-modal-medium-border-m-border-width: 2;
$es-controls-modal-medium-border-l-border-width: 4;
$es-controls-modal-medium-border-xl-border-width: 8;
$es-controls-modal-border-m-border-width: 1;
$es-controls-modal-states-default-text-primary-color: #1a1c1e;
$es-controls-modal-states-default-text-brand-color: #b72913;
$es-controls-modal-states-default-text-secondary-color: #828282;
$es-controls-modal-states-default-background-brand-lvl1-color: rgba(
  183,
  41,
  19,
  0.08
);
$es-controls-modal-states-default-background-brand-lvl2-color: rgba(
  183,
  41,
  19,
  0.2
);
$es-controls-modal-states-default-background-brand-lvl3-color: rgba(
  183,
  41,
  19,
  0.4
);
$es-controls-modal-states-default-background-lvl1-color: #ffffff;
$es-controls-modal-states-default-background-lvl2-color: #f7f7f7;
$es-controls-modal-states-default-background-lvl3-color: #eff0f0;
$es-controls-modal-states-default-background-lvl4-color: #ebebeb;
$es-controls-modal-states-default-background-lvl5-color: #e5e5e6;
$es-controls-modal-states-default-background-lvl6-color: #ffffff;
$es-controls-modal-states-default-icon-primary-color: #b72913;
$es-controls-modal-states-default-icon-error-color: #e24e36;
$es-controls-modal-states-default-icon-secondary-color: #828282;
$es-controls-modal-states-default-divider-primary-color: #d5d6d8;
$es-controls-modal-states-default-divider-secondary-color: #c1c2c3;
$es-controls-popover-medium-top-m-spacing: 20;
$es-controls-popover-medium-top-l-spacing: 24;
$es-controls-popover-medium-bottom-m-spacing: 16;
$es-controls-popover-medium-bottom-l-spacing: 20;
$es-controls-popover-medium-bottom-xl-spacing: 24;
$es-controls-popover-medium-bottom-xxl-spacing: 28;
$es-controls-popover-medium-gap-vertical-m-spacing: 8;
$es-controls-popover-medium-gap-horizontal-l-spacing: 16;
$es-controls-popover-medium-left-m-spacing: 20;
$es-controls-popover-medium-right-m-spacing: 20;
$es-controls-popover-medium-all-m-border-radius: 8;
$es-controls-popover-states-default-background-primary-color: #f7f7f7;
$es-controls-popover-states-default-background-secondary-color: #ffffff;
$es-controls-popover-states-default-text-primary-color: #1a1c1e;
$es-controls-popover-states-default-icon-primary-color: #b72913;
$es-controls-popover-states-default-icon-secondary-color: #0f4692;
$es-color-background-primary-default-color: #b72913;
$es-color-background-primary-hover-color: #d5341c;
$es-color-background-primary-pressed-color: #821d0d;
$es-color-background-primary-select-color: #5d1509;
$es-color-background-primary-focused-color: #b72913;
$es-color-background-primary-dragged-color: #b72913;
$es-color-background-primary-disabled-color: rgba(183, 41, 19, 0.3);
$es-color-background-secondary-default-color: #0f4692;
$es-color-background-secondary-hover-color: #5a78a5;
$es-color-background-secondary-pressed-color: #173f7b;
$es-color-background-secondary-selected-color: #0f4692;
$es-color-background-secondary-focused-color: #0f4692;
$es-color-background-secondary-dragged-color: #0f4692;
$es-color-background-secondary-disabled-color: rgba(15, 70, 146, 0.3);
$es-color-background-on-brand-default-color: #ffffff;
$es-color-background-on-brand-hover-color: #ffffff;
$es-color-background-on-brand-pressed-color: #ffffff;
$es-color-background-on-brand-selected-color: #ffffff;
$es-color-background-on-brand-focused-color: #ffffff;
$es-color-background-on-brand-dragged-color: #ffffff;
$es-color-background-on-brand-disabled-color: rgba(255, 255, 255, 0.6);
$es-color-background-main-lvl1-color: #ffffff;
$es-color-background-main-lvl2-color: #f7f7f7;
$es-color-background-main-lvl3-color: #eff0f0;
$es-color-background-main-lvl4-color: #ebebeb;
$es-color-background-main-lvl5-color: #e5e5e6;
$es-color-background-main-lvl6-color: #ffffff;
$es-color-background-gray-dark-color: #464749;
$es-color-background-gray-strong-color: #828282;
$es-color-background-gray-color: #c1c2c3;
$es-color-background-gray-light-color: #d5d6d8;
$es-color-background-gray-tint-color: #eff0f0;
$es-color-background-main-inverted-lvl1-color: #313335;
$es-color-background-main-inverted-lvl2-color: #292b2d;
$es-color-background-main-inverted-lvl3-color: #1f2123;
$es-color-background-main-inverted-lvl4-color: #1a1c1e;
$es-color-background-main-inverted-lvl5-color: #121416;
$es-color-background-main-inverted-lvl6-color: #3c3d3e;
$es-color-background-primary-lvl1-color: rgba(183, 41, 19, 0.08);
$es-color-background-primary-lvl2-color: rgba(183, 41, 19, 0.2);
$es-color-background-primary-lvl3-color: rgba(183, 41, 19, 0.4);
$es-color-background-secondary-lvl1-color: rgba(15, 70, 146, 0.1);
$es-color-background-secondary-lvl2-color: rgba(15, 70, 146, 0.2);
$es-color-background-secondary-lvl3-color: rgba(15, 70, 146, 0.4);
$es-color-background-error-default-color: #ff4d4d;
$es-color-background-error-hover-color: #ff2706;
$es-color-background-error-pressed-color: #aa2828;
$es-color-background-error-disabled-color: rgba(255, 39, 6, 0.2);
$es-color-background-success-color: #58975b;
$es-color-background-success50-color: rgba(46, 125, 50, 0.5);
$es-color-background-success30-color: rgba(46, 125, 50, 0.3);
$es-color-background-attention-color: #ef6c00;
$es-color-background-shades-color: #4b6f98;
$es-color-background-shades50-color: rgba(75, 111, 152, 0.5);
$es-color-background-shades20-color: rgba(75, 111, 152, 0.2);
$es-color-background-attention50-color: rgba(239, 108, 0, 0.5);
$es-color-background-error50-color: rgba(255, 39, 6, 0.5);
$es-color-background-error30-color: rgba(255, 39, 6, 0.3);
$es-color-background-white-everywhere-color: #ffffff;
$es-color-background-white-everywhere20-color: rgba(255, 255, 255, 0.2);
$es-color-background-black-everywhere-lvl1-color: #242628;
$es-color-background-black-everywhere-lvl2-color: #000000;
$es-color-background-black-everywhere-lvl3-color: #242628;
$es-color-stroke-main-lvl1-color: #ffffff;
$es-color-stroke-main-lvl2-color: #f7f7f7;
$es-color-stroke-main-lvl3-color: #eff0f0;
$es-color-stroke-main-lvl4-color: #ebebeb;
$es-color-stroke-main-lvl5-color: #e5e5e6;
$es-color-stroke-main-lvl6-color: #ffffff;
$es-color-stroke-main-inverted-lvl1-color: #1a1c1e;
$es-color-stroke-main-inverted-lvl2-color: #1f2123;
$es-color-stroke-main-inverted-lvl3-color: #121416;
$es-color-stroke-main-inverted-lvl4-color: #2c2e30;
$es-color-stroke-main-inverted-lvl5-color: #373839;
$es-color-stroke-main-inverted-lvl6-color: #464749;
$es-color-stroke-primary-default-color: #b72913;
$es-color-stroke-primary-hover-color: #d5341c;
$es-color-stroke-primary-pressed-color: #821d0d;
$es-color-stroke-primary-selected-color: #b72913;
$es-color-stroke-primary-focused-color: #d5341c;
$es-color-stroke-primary-dragged-color: #d5341c;
$es-color-stroke-primary-disabled-color: rgba(183, 41, 19, 0.3);
$es-color-stroke-secondary-default-color: #0f4692;
$es-color-stroke-secondary-hover-color: #2264bf;
$es-color-stroke-secondary-pressed-color: #173f7b;
$es-color-stroke-secondary-selected-color: #4982cf;
$es-color-stroke-secondary-focused-color: #2264bf;
$es-color-stroke-secondary-dragged-color: #2264bf;
$es-color-stroke-secondary-disabled-color: rgba(15, 70, 146, 0.4);
$es-color-stroke-on-brand-default-color: #ffffff;
$es-color-stroke-on-brand-hover-color: #ffffff;
$es-color-stroke-on-brand-pressed-color: #ffffff;
$es-color-stroke-on-brand-selected-color: #ffffff;
$es-color-stroke-on-brand-focused-color: #ffffff;
$es-color-stroke-on-brand-dragged-color: #ffffff;
$es-color-stroke-on-brand-disabled-color: rgba(255, 255, 255, 0.6);
$es-color-stroke-gray-dark-color: #464749;
$es-color-stroke-gray-strong-color: #828282;
$es-color-stroke-gray-color: #c1c2c3;
$es-color-stroke-gray-light-color: #d5d6d8;
$es-color-stroke-gray-tint-color: #eff0f0;
$es-color-stroke-primary-lvl1-color: rgba(183, 41, 19, 0.08);
$es-color-stroke-primary-lvl2-color: rgba(183, 41, 19, 0.2);
$es-color-stroke-primary-lvl3-color: rgba(183, 41, 19, 0.4);
$es-color-stroke-success-color: #58975b;
$es-color-stroke-shades-color: #4b6f98;
$es-color-stroke-error-default-color: #ff2706;
$es-color-stroke-error-hover-color: #ff4d4d;
$es-color-stroke-error-pressed-color: #e24e36;
$es-color-stroke-error-disabled-color: rgba(255, 39, 6, 0.2);
$es-color-stroke-attention-color: #ef6c00;
$es-color-stroke-white-everywhere-color: #ffffff;
$es-color-stroke-black-everywhere-color: #000000;
$es-color-icon-primary-lvl1-color: rgba(183, 41, 19, 0.08);
$es-color-icon-primary-lvl2-color: rgba(183, 41, 19, 0.2);
$es-color-icon-primary-lvl3-color: rgba(183, 41, 19, 0.4);
$es-color-icon-secondary-lvl1-color: rgba(15, 70, 146, 0.1);
$es-color-icon-secondary-lvl2-color: rgba(15, 70, 146, 0.2);
$es-color-icon-secondary-lvl3-color: rgba(15, 70, 146, 0.4);
$es-color-icon-primary-light-color: #eb7665;
$es-color-icon-primary-default-color: #b72913;
$es-color-icon-primary-hover-color: #d5341c;
$es-color-icon-primary-pressed-color: #821d0d;
$es-color-icon-primary-selected-color: #b72913;
$es-color-icon-primary-focused-color: #d5341c;
$es-color-icon-primary-dragged-color: #d5341c;
$es-color-icon-primary-disabled-color: rgba(183, 41, 19, 0.3);
$es-color-icon-secondary-default-color: #0f4692;
$es-color-icon-secondary-hover-color: #2264bf;
$es-color-icon-secondary-pressed-color: #173f7b;
$es-color-icon-secondary-selected-color: #4982cf;
$es-color-icon-secondary-focused-color: #2264bf;
$es-color-icon-secondary-dragged-color: #2264bf;
$es-color-icon-secondary-disabled-color: rgba(15, 70, 146, 0.4);
$es-color-icon-on-brand-default-color: #ffffff;
$es-color-icon-on-brand-hover-color: #ffffff;
$es-color-icon-on-brand-pressed-color: #ffffff;
$es-color-icon-on-brand-selected-color: #ffffff;
$es-color-icon-on-brand-focused-color: #ffffff;
$es-color-icon-on-brand-dragged-color: #ffffff;
$es-color-icon-on-brand-disabled-color: rgba(255, 255, 255, 0.6);
$es-color-icon-tertiary-color: #c1c2c3;
$es-color-icon-inverse-color: #ffffff;
$es-color-icon-shades-color: #4b6f98;
$es-color-icon-inverse-bg-color: #1f2123;
$es-color-icon-gray-dark-color: #464749;
$es-color-icon-gray-strong-color: #828282;
$es-color-icon-gray-color: #c1c2c3;
$es-color-icon-gray-light-color: #d5d6d8;
$es-color-icon-gray-tint-color: #eff0f0;
$es-color-icon-error-default-color: #ff2706;
$es-color-icon-error-hover-color: #ff4d4d;
$es-color-icon-error-pressed-color: #e24e36;
$es-color-icon-error-disabled-color: rgba(255, 39, 6, 0.2);
$es-color-icon-attention-color: #ef6c00;
$es-color-icon-success-color: #58975b;
$es-color-icon-white-everywhere-color: #ffffff;
$es-color-icon-black-everywhere-color: #121416;
$es-color-text-main-color: #1a1c1e;
$es-color-text-inverse-color: #ffffff;
$es-color-text-inverse-reserve-color: #eff0f0;
$es-color-text-primary-default-color: #b72913;
$es-color-text-primary-hover-color: #d5341c;
$es-color-text-primary-pressed-color: #821d0d;
$es-color-text-primary-selected-color: #b72913;
$es-color-text-primary-focused-color: #d5341c;
$es-color-text-primary-dragged-color: #d5341c;
$es-color-text-primary-disabled-color: rgba(183, 41, 19, 0.4);
$es-color-text-secondary-default-color: #0f4692;
$es-color-text-secondary-hover-color: #2264bf;
$es-color-text-secondary-pressed-color: #173f7b;
$es-color-text-secondary-selected-color: #4982cf;
$es-color-text-secondary-focused-color: #2264bf;
$es-color-text-secondary-dragged-color: #2264bf;
$es-color-text-secondary-disabled-color: rgba(15, 70, 146, 0.4);
$es-color-text-on-brand-default-color: #ffffff;
$es-color-text-on-brand-hover-color: #ffffff;
$es-color-text-on-brand-pressed-color: #ffffff;
$es-color-text-on-brand-selected-color: #ffffff;
$es-color-text-on-brand-focused-color: #ffffff;
$es-color-text-on-brand-dragged-color: #ffffff;
$es-color-text-on-brand-disabled-color: rgba(255, 255, 255, 0.6);
$es-color-text-gray-dark-color: #464749;
$es-color-text-gray-strong-color: #828282;
$es-color-text-gray-color: #c1c2c3;
$es-color-text-gray-light-color: #d5d6d8;
$es-color-text-gray-tint-color: #eff0f0;
$es-color-text-error-default-color: #ff4d4d;
$es-color-text-error-hover-color: #ff2706;
$es-color-text-error-pressed-color: #e24e36;
$es-color-text-error-disabled-color: rgba(255, 39, 6, 0.2);
$es-color-text-attention-color: #ef6c00;
$es-color-text-success-color: #58975b;
$es-color-text-shades-primary-color: #4b6f98;
$es-color-text-shades-primary-disabled-color: rgba(75, 111, 152, 0.5);
$es-color-text-shades-primary-on-dark-color: #6fa1d9;
$es-color-text-shades-secondary-color: #0f4692;
$es-color-text-shades-secondary-disabled-color: rgba(239, 108, 0, 0.5);
$es-color-text-shades-tertiary-color: #ff4d4d;
$es-color-text-white-everywhere-color: #ffffff;
$es-color-text-black-everywhere-color: #000000;
$es-sizing-x025-sizing: 1;
$es-sizing-x05-sizing: 2;
$es-sizing-x075-sizing: 3;
$es-sizing-x1-sizing: 4;
$es-sizing-x2-sizing: 8;
$es-sizing-x3-sizing: 12;
$es-sizing-x4-sizing: 16;
$es-sizing-x5-sizing: 20;
$es-sizing-x6-sizing: 24;
$es-sizing-x7-sizing: 28;
$es-sizing-x8-sizing: 32;
$es-sizing-x9-sizing: 36;
$es-sizing-x10-sizing: 40;
$es-sizing-x11-sizing: 44;
$es-sizing-x12-sizing: 48;
$es-sizing-x13-sizing: 52;
$es-sizing-x14-sizing: 56;
$es-sizing-x15-sizing: 60;
$es-sizing-x16-sizing: 64;
$es-sizing-x17-sizing: 68;
$es-sizing-x18-sizing: 72;
$es-sizing-x19-sizing: 76;
$es-sizing-x20-sizing: 80;
$es-sizing-x21-sizing: 84;
$es-sizing-x22-sizing: 88;
$es-sizing-x23-sizing: 92;
$es-sizing-x24-sizing: 96;
$es-sizing-x25-sizing: 100;
$es-sizing-x26-sizing: 104;
$es-sizing-x70-sizing: 280;
$es-sizing-x27-sizing: 108;
$es-sizing-x28-sizing: 112;
$es-sizing-x29-sizing: 116;
$es-sizing-x30-sizing: 120;
$es-sizing-x31-sizing: 124;
$es-sizing-x32-sizing: 128;
$es-sizing-x33-sizing: 132;
$es-sizing-x34-sizing: 136;
$es-sizing-x35-sizing: 140;
$es-sizing-x36-sizing: 144;
$es-sizing-x37-sizing: 148;
$es-sizing-x38-sizing: 152;
$es-sizing-x39-sizing: 156;
$es-sizing-x40-sizing: 160;
$es-sizing-x41-sizing: 164;
$es-sizing-x42-sizing: 168;
$es-sizing-x43-sizing: 172;
$es-sizing-x44-sizing: 176;
$es-sizing-x45-sizing: 180;
$es-sizing-x46-sizing: 184;
$es-sizing-x47-sizing: 188;
$es-sizing-x48-sizing: 192;
$es-sizing-x49-sizing: 196;
$es-sizing-x50-sizing: 200;
$es-sizing-x51-sizing: 204;
$es-sizing-x52-sizing: 208;
$es-sizing-x53-sizing: 212;
$es-sizing-x54-sizing: 216;
$es-sizing-x55-sizing: 220;
$es-sizing-x56-sizing: 224;
$es-sizing-x57-sizing: 228;
$es-spacing-x025-spacing: 1;
$es-spacing-x05-spacing: 2;
$es-spacing-x1-spacing: 4;
$es-spacing-x2-spacing: 8;
$es-spacing-x3-spacing: 12;
$es-spacing-x4-spacing: 16;
$es-spacing-x5-spacing: 20;
$es-spacing-x6-spacing: 24;
$es-spacing-x7-spacing: 28;
$es-spacing-x8-spacing: 32;
$es-spacing-x9-spacing: 36;
$es-spacing-x10-spacing: 40;
$es-spacing-x11-spacing: 44;
$es-spacing-x12-spacing: 48;
$es-spacing-x18-spacing: 72;
$es-spacing-x24-spacing: 96;
$es-spacing-x90-spacing: 360;
$es-border-width-0-border-width: 1;
$es-border-width-s-border-width: 1;
$es-border-width-m-border-width: 2;
$es-border-width-l-border-width: 4;
$es-border-width-xl-border-width: 8;
$es-border-radius-none-border-radius: 0;
$es-border-radius-xs-border-radius: 2;
$es-border-radius-s-border-radius: 4;
$es-border-radius-m-border-radius: 6;
$es-border-radius-l-border-radius: 8;
$es-border-radius-xl-border-radius: 10;
$es-border-radius-xxl-border-radius: 12;
$es-border-radius-xxxl-border-radius: 14;
$es-border-radius-xxxxl-border-radius: 16;
$es-border-radius-5-xl-border-radius: 20;
$es-border-radius-6-xl-border-radius: 24;
$es-border-radius-7-xl-border-radius: 28;
$es-border-radius-8-xl-border-radius: 32;
$es-border-radius-9-xl-border-radius: 36;
$es-shadow-material-xxxxs-x-box-shadow: 0;
$es-shadow-material-xxxxs-y-box-shadow: 1;
$es-shadow-material-xxxxs-blur-box-shadow: 3;
$es-shadow-material-xxxxs-spread-box-shadow: 0;
$es-shadow-material-xxxxs-color-box-shadow: rgba(0, 0, 0, 0.15);
$es-shadow-material-xxxs-x-box-shadow: 0;
$es-shadow-material-xxxs-y-box-shadow: 1;
$es-shadow-material-xxxs-blur-box-shadow: 2;
$es-shadow-material-xxxs-spread-box-shadow: 0;
$es-shadow-material-xxxs-color-box-shadow: rgba(0, 0, 0, 0.25);
$es-shadow-material-xxs-x-box-shadow: 0;
$es-shadow-material-xxs-y-box-shadow: 2;
$es-shadow-material-xxs-blur-box-shadow: 2;
$es-shadow-material-xxs-spread-box-shadow: 0;
$es-shadow-material-xxs-color-box-shadow: rgba(0, 0, 0, 0.25);
$es-shadow-material-xs-x-box-shadow: 0;
$es-shadow-material-xs-y-box-shadow: 3;
$es-shadow-material-xs-blur-box-shadow: 4;
$es-shadow-material-xs-spread-box-shadow: 0;
$es-shadow-material-xs-color-box-shadow: rgba(0, 0, 0, 0.3);
$es-shadow-material-s-x-box-shadow: 0;
$es-shadow-material-s-y-box-shadow: 3;
$es-shadow-material-s-blur-box-shadow: 4;
$es-shadow-material-s-spread-box-shadow: 0;
$es-shadow-material-s-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-shadow-material-m-x-box-shadow: 0;
$es-shadow-material-m-y-box-shadow: 4;
$es-shadow-material-m-blur-box-shadow: 7;
$es-shadow-material-m-spread-box-shadow: 0;
$es-shadow-material-m-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-shadow-material-l-x-box-shadow: 0;
$es-shadow-material-l-y-box-shadow: 6;
$es-shadow-material-l-blur-box-shadow: 10;
$es-shadow-material-l-spread-box-shadow: 0;
$es-shadow-material-l-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-shadow-material-xl-x-box-shadow: 0;
$es-shadow-material-xl-y-box-shadow: 8;
$es-shadow-material-xl-blur-box-shadow: 10;
$es-shadow-material-xl-spread-box-shadow: 0;
$es-shadow-material-xl-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-shadow-material-xxl-x-box-shadow: 0;
$es-shadow-material-xxl-y-box-shadow: 12;
$es-shadow-material-xxl-blur-box-shadow: 15;
$es-shadow-material-xxl-spread-box-shadow: 0;
$es-shadow-material-xxl-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-shadow-material-xxxl-x-box-shadow: 0;
$es-shadow-material-xxxl-y-box-shadow: 14;
$es-shadow-material-xxxl-blur-box-shadow: 22;
$es-shadow-material-xxxl-spread-box-shadow: 0;
$es-shadow-material-xxxl-color-box-shadow: rgba(0, 0, 0, 0.4);
$es-shadow-light-blur-xxxs-x-box-shadow: 0;
$es-shadow-light-blur-xxxs-y-box-shadow: 12;
$es-shadow-light-blur-xxxs-blur-box-shadow: 40;
$es-shadow-light-blur-xxxs-spread-box-shadow: 0;
$es-shadow-light-blur-xxxs-color-box-shadow: rgba(0, 0, 0, 0.05);
$es-shadow-light-blur-xxs-x-box-shadow: 0;
$es-shadow-light-blur-xxs-y-box-shadow: 4;
$es-shadow-light-blur-xxs-blur-box-shadow: 16;
$es-shadow-light-blur-xxs-spread-box-shadow: 0;
$es-shadow-light-blur-xxs-color-box-shadow: rgba(0, 0, 0, 0.1);
$es-shadow-light-blur-xs-x-box-shadow: 0;
$es-shadow-light-blur-xs-y-box-shadow: 4;
$es-shadow-light-blur-xs-blur-box-shadow: 8;
$es-shadow-light-blur-xs-spread-box-shadow: 0;
$es-shadow-light-blur-xs-color-box-shadow: rgba(0, 0, 0, 0.12);
$es-shadow-light-blur-s-x-box-shadow: 0;
$es-shadow-light-blur-s-y-box-shadow: 6;
$es-shadow-light-blur-s-blur-box-shadow: 15;
$es-shadow-light-blur-s-spread-box-shadow: 0;
$es-shadow-light-blur-s-color-box-shadow: rgba(0, 0, 0, 0.1);
$es-shadow-light-blur-m-x-box-shadow: 0;
$es-shadow-light-blur-m-y-box-shadow: 14;
$es-shadow-light-blur-m-blur-box-shadow: 20;
$es-shadow-light-blur-m-spread-box-shadow: 0;
$es-shadow-light-blur-m-color-box-shadow: rgba(0, 0, 0, 0.1);
$es-shadow-light-blur-l-x-box-shadow: 0;
$es-shadow-light-blur-l-y-box-shadow: 20;
$es-shadow-light-blur-l-blur-box-shadow: 20;
$es-shadow-light-blur-l-spread-box-shadow: 0;
$es-shadow-light-blur-l-color-box-shadow: rgba(0, 0, 0, 0.08);
$es-shadow-light-blur-xl-x-box-shadow: 0;
$es-shadow-light-blur-xl-y-box-shadow: 16;
$es-shadow-light-blur-xl-blur-box-shadow: 32;
$es-shadow-light-blur-xl-spread-box-shadow: 0;
$es-shadow-light-blur-xl-color-box-shadow: rgba(0, 0, 0, 0.2);
$es-shadow-light-blur-xxl-x-box-shadow: 0;
$es-shadow-light-blur-xxl-y-box-shadow: 40;
$es-shadow-light-blur-xxl-blur-box-shadow: 60;
$es-shadow-light-blur-xxl-spread-box-shadow: 0;
$es-shadow-light-blur-xxl-color-box-shadow: rgba(0, 0, 0, 0.5);
$es-shadow-custom-inner-light-x-box-shadow: 0;
$es-shadow-custom-inner-light-y-box-shadow: 4;
$es-shadow-custom-inner-light-blur-box-shadow: 6;
$es-shadow-custom-inner-light-spread-box-shadow: 0;
$es-shadow-custom-inner-light-color-box-shadow: rgba(0, 0, 0, 0.08);
$es-shadow-custom-header-x-box-shadow: 0;
$es-shadow-custom-header-y-box-shadow: 10;
$es-shadow-custom-header-blur-box-shadow: 32;
$es-shadow-custom-header-spread-box-shadow: 0;
$es-shadow-custom-header-color-box-shadow: rgba(183, 41, 19, 0.08);
$es-shadow-custom-big-button-x-box-shadow: 0;
$es-shadow-custom-big-button-y-box-shadow: 12;
$es-shadow-custom-big-button-blur-box-shadow: 12;
$es-shadow-custom-big-button-spread-box-shadow: 0;
$es-shadow-custom-big-button-color-box-shadow: rgba(183, 41, 19, 0.5);
$es-typography-global-h1-300-font-weight: 300;
$es-typography-global-h1-400-font-weight: 400;
$es-typography-global-h1-500-font-weight: 500;
$es-typography-global-h1-600-font-weight: 600;
$es-typography-global-h1-700-font-weight: 700;
$es-typography-global-h1-800-font-weight: 800;
$es-typography-global-h1-m-font-size: 60;
$es-typography-global-h1-m-line-height: 88;
$es-typography-global-h1-s-line-height: 72;
$es-typography-global-h2-300-font-weight: 300;
$es-typography-global-h2-400-font-weight: 400;
$es-typography-global-h2-500-font-weight: 500;
$es-typography-global-h2-600-font-weight: 600;
$es-typography-global-h2-700-font-weight: 700;
$es-typography-global-h2-800-font-weight: 800;
$es-typography-global-h2-m-font-size: 42;
$es-typography-global-h2-m-line-height: 68;
$es-typography-global-h3-300-font-weight: 300;
$es-typography-global-h3-400-font-weight: 400;
$es-typography-global-h3-500-font-weight: 500;
$es-typography-global-h3-600-font-weight: 600;
$es-typography-global-h3-700-font-weight: 700;
$es-typography-global-h3-800-font-weight: 800;
$es-typography-global-h3-m-font-size: 32;
$es-typography-global-h3-m-line-height: 56;
$es-typography-global-h4-300-font-weight: 300;
$es-typography-global-h4-400-font-weight: 400;
$es-typography-global-h4-500-font-weight: 500;
$es-typography-global-h4-600-font-weight: 600;
$es-typography-global-h4-700-font-weight: 700;
$es-typography-global-h4-800-font-weight: 800;
$es-typography-global-h4-m-font-size: 24;
$es-typography-global-h4-m-line-height: 40;
$es-typography-global-h5-300-font-weight: 300;
$es-typography-global-h5-400-font-weight: 400;
$es-typography-global-h5-500-font-weight: 500;
$es-typography-global-h5-600-font-weight: 600;
$es-typography-global-h5-700-font-weight: 700;
$es-typography-global-h5-800-font-weight: 800;
$es-typography-global-h5-l-font-size: 22;
$es-typography-global-h5-m-font-size: 20;
$es-typography-global-h5-m-line-height: 32;
$es-typography-global-h6-300-font-weight: 300;
$es-typography-global-h6-400-font-weight: 400;
$es-typography-global-h6-500-font-weight: 500;
$es-typography-global-h6-600-font-weight: 600;
$es-typography-global-h6-700-font-weight: 700;
$es-typography-global-h6-800-font-weight: 800;
$es-typography-global-h6-m-font-size: 18;
$es-typography-global-h6-m-line-height: 32;
$es-typography-global-h6-s-font-size: 16;
$es-typography-global-h6-s-line-height: 24;
$es-typography-global-p1-300-font-weight: 300;
$es-typography-global-p1-400-font-weight: 400;
$es-typography-global-p1-500-font-weight: 500;
$es-typography-global-p1-600-font-weight: 600;
$es-typography-global-p1-700-font-weight: 700;
$es-typography-global-p1-800-font-weight: 800;
$es-typography-global-p1-xl-font-size: 22;
$es-typography-global-p1-m-font-size: 18;
$es-typography-global-p1-m-line-height: 36;
$es-typography-global-p1-s-font-size: 16;
$es-typography-global-p1-s-line-height: 32;
$es-typography-global-p1-xs-line-height: 24;
$es-typography-global-p2-300-font-weight: 300;
$es-typography-global-p2-400-font-weight: 400;
$es-typography-global-p2-500-font-weight: 500;
$es-typography-global-p2-600-font-weight: 600;
$es-typography-global-p2-700-font-weight: 700;
$es-typography-global-p2-800-font-weight: 800;
$es-typography-global-p2-xl-font-size: 18;
$es-typography-global-p2-m-font-size: 16;
$es-typography-global-p2-s-font-size: 15;
$es-typography-global-p2-m-line-height: 32;
$es-typography-global-p2-s-line-height: 28;
$es-typography-global-p2-xs-line-height: 20;
$es-typography-global-p3-300-font-weight: 300;
$es-typography-global-p3-400-font-weight: 400;
$es-typography-global-p3-500-font-weight: 500;
$es-typography-global-p3-600-font-weight: 600;
$es-typography-global-p3-700-font-weight: 700;
$es-typography-global-p3-800-font-weight: 800;
$es-typography-global-p3-m-font-size: 15;
$es-typography-global-p3-m-line-height: 28;
$es-typography-global-p3-s-font-size: 14;
$es-typography-global-p3-s-line-height: 20;
$es-typography-global-p4-300-font-weight: 300;
$es-typography-global-p4-400-font-weight: 400;
$es-typography-global-p4-500-font-weight: 500;
$es-typography-global-p4-600-font-weight: 600;
$es-typography-global-p4-700-font-weight: 700;
$es-typography-global-p4-800-font-weight: 800;
$es-typography-global-p4-m-font-size: 14;
$es-typography-global-p4-m-line-height: 24;
$es-typography-global-p4-s-font-size: 13;
$es-typography-global-p4-s-line-height: 16;
$es-typography-global-p5-300-font-weight: 300;
$es-typography-global-p5-400-font-weight: 400;
$es-typography-global-p5-500-font-weight: 500;
$es-typography-global-p5-600-font-weight: 600;
$es-typography-global-p5-700-font-weight: 700;
$es-typography-global-p5-800-font-weight: 800;
$es-typography-global-p5-m-font-size: 13;
$es-typography-global-p5-m-line-height: 24;
$es-typography-global-p5-s-font-size: 12;
$es-typography-global-p5-s-line-height: 16;
$es-typography-global-p6-300-font-weight: 300;
$es-typography-global-p6-400-font-weight: 400;
$es-typography-global-p6-500-font-weight: 500;
$es-typography-global-p6-600-font-weight: 600;
$es-typography-global-p6-700-font-weight: 700;
$es-typography-global-p6-800-font-weight: 800;
$es-typography-global-p6-m-font-size: 12;
$es-typography-global-p6-m-line-height: 24;
$es-typography-global-p6-s-font-size: 10;
$es-typography-global-p6-s-line-height: 16;
$es-opasity-100-opasity: 100;
$es-opasity-95-opasity: 95;
$es-opasity-90-opasity: 90;
$es-opasity-85-opasity: 85;
$es-opasity-80-opasity: 80;
$es-opasity-75-opasity: 75;
$es-opasity-70-opasity: 70;
$es-opasity-65-opasity: 65;
$es-opasity-60-opasity: 60;
$es-opasity-55-opasity: 55;
$es-opasity-50-opasity: 50;
$es-opasity-45-opasity: 45;
$es-opasity-40-opasity: 40;
$es-opasity-35-opasity: 35;
$es-opasity-30-opasity: 30;
$es-opasity-25-opasity: 25;
$es-opasity-20-opasity: 20;
$es-opasity-15-opasity: 15;
$es-opasity-10-opasity: 10;
$es-opasity-05-opasity: 5;
$es-opasity-0-opasity: 0;
$es-theme-neutral-gray-f-color: #ffffff;
$es-theme-neutral-gray-100-color: #f7f7f7;
$es-theme-neutral-gray-150-color: #eff0f0;
$es-theme-neutral-gray-200-color: #ebebeb;
$es-theme-neutral-gray-250-color: #e5e5e6;
$es-theme-neutral-gray-300-color: #ffffff;
$es-theme-neutral-gray-350-color: #d5d6d8;
$es-theme-neutral-gray-400-color: #c1c2c3;
$es-theme-neutral-gray-500-color: #bdbdbd;
$es-theme-neutral-gray-520-color: #9a9a9a;
$es-theme-neutral-gray-550-color: #828282;
$es-theme-neutral-gray-600-color: #666666;
$es-theme-neutral-gray-620-color: #464749;
$es-theme-neutral-gray-650-color: #3c3d3e;
$es-theme-neutral-gray-660-color: #373839;
$es-theme-neutral-gray-666-color: #313335;
$es-theme-neutral-gray-670-color: #2c2e30;
$es-theme-neutral-gray-700-color: #2c2e30;
$es-theme-neutral-gray-750-color: #292b2d;
$es-theme-neutral-gray-780-color: #242628;
$es-theme-neutral-gray-800-color: #1f2123;
$es-theme-neutral-gray-850-color: #1a1c1e;
$es-theme-neutral-gray-890-color: #121416;
$es-theme-neutral-gray-900-color: #000000;
$es-theme-neutral-gray-990-color: #242628;
$es-theme-neutral-gray-f-60-color: rgba(255, 255, 255, 0.6);
$es-theme-neutral-gray-f-20-color: rgba(255, 255, 255, 0.2);
$es-theme-neutral-gray-0-20-color: rgba(0, 0, 0, 0.2);
$es-theme-neutral-gray-0-60-color: rgba(0, 0, 0, 0.6);
$es-theme-brand-zam-opasity-0-opasity: 0;
$es-theme-brand-zam-opasity-05-opasity: 5;
$es-theme-brand-zam-opasity-10-opasity: 10;
$es-theme-brand-zam-opasity-15-opasity: 15;
$es-theme-brand-zam-opasity-20-opasity: 20;
$es-theme-brand-zam-opasity-25-opasity: 25;
$es-theme-brand-zam-opasity-30-opasity: 30;
$es-theme-brand-zam-opasity-35-opasity: 35;
$es-theme-brand-zam-opasity-40-opasity: 40;
$es-theme-brand-zam-opasity-45-opasity: 45;
$es-theme-brand-zam-opasity-50-opasity: 50;
$es-theme-brand-zam-opasity-55-opasity: 55;
$es-theme-brand-zam-opasity-60-opasity: 60;
$es-theme-brand-zam-opasity-65-opasity: 65;
$es-theme-brand-zam-opasity-70-opasity: 70;
$es-theme-brand-zam-opasity-75-opasity: 75;
$es-theme-brand-zam-opasity-80-opasity: 80;
$es-theme-brand-zam-opasity-85-opasity: 85;
$es-theme-brand-zam-opasity-90-opasity: 90;
$es-theme-brand-zam-opasity-95-opasity: 95;
$es-theme-brand-zam-opasity-100-opasity: 100;
$es-theme-brand-zam-color-primary-bg-default-color: #2e7d32;
$es-theme-brand-zam-color-primary-bg-hover-color: #388e3c;
$es-theme-brand-zam-color-primary-bg-pressed-color: #18531c;
$es-theme-brand-zam-color-primary-bg-selected-color: #123f16;
$es-theme-brand-zam-color-primary-bg-focused-color: #2e7d32;
$es-theme-brand-zam-color-primary-bg-disabled-color: rgba(46, 125, 50, 0.25);
$es-theme-brand-zam-color-primary-on-bg-default-color: #2e7d32;
$es-theme-brand-zam-color-primary-on-bg-hover-color: #388e3c;
$es-theme-brand-zam-color-primary-on-bg-pressed-color: #18531c;
$es-theme-brand-zam-color-primary-on-bg-selected-color: #2e7d32;
$es-theme-brand-zam-color-primary-on-bg-focused-color: #2e7d32;
$es-theme-brand-zam-color-primary-on-bg-disabled-color: rgba(46, 125, 50, 0.2);
$es-theme-brand-zam-color-secondary-bg-default-color: #ef6c00;
$es-theme-brand-zam-color-secondary-bg-hover-color: #ff9137;
$es-theme-brand-zam-color-secondary-bg-pressed-color: #b74b00;
$es-theme-brand-zam-color-secondary-bg-selected-color: #e65100;
$es-theme-brand-zam-color-secondary-bg-focused-color: #ef6c00;
$es-theme-brand-zam-color-secondary-bg-disabled-color: rgba(239, 108, 0, 0.3);
$es-theme-brand-zam-color-secondary-on-bg-default-color: #ef6c00;
$es-theme-brand-zam-color-secondary-on-bg-hover-color: #fb8c00;
$es-theme-brand-zam-color-secondary-on-bg-pressed-color: #b74b00;
$es-theme-brand-zam-color-secondary-on-bg-selected-color: #e65100;
$es-theme-brand-zam-color-secondary-on-bg-focused-color: #e65100;
$es-theme-brand-zam-color-secondary-on-bg-disabled-color: rgba(
  239,
  108,
  0,
  0.3
);
$es-theme-brand-zam-color-primary-lvl1-color: rgba(46, 125, 50, 0.1);
$es-theme-brand-zam-color-primary-lvl2-color: rgba(46, 125, 50, 0.2);
$es-theme-brand-zam-color-primary-lvl3-color: rgba(46, 125, 50, 0.4);
$es-theme-brand-zam-color-secondary-lvl1-color: rgba(239, 108, 0, 0.1);
$es-theme-brand-zam-color-secondary-lvl2-color: rgba(239, 108, 0, 0.2);
$es-theme-brand-zam-color-secondary-lvl3-color: rgba(239, 108, 0, 0.4);
$es-theme-brand-zam-color-shades-color: #557aa5;
$es-theme-brand-zam-color-shades50-color: rgba(75, 111, 152, 0.5);
$es-theme-brand-zam-shadow-material-xxxxs-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxxxs-y-box-shadow: 1;
$es-theme-brand-zam-shadow-material-xxxxs-blur-box-shadow: 3;
$es-theme-brand-zam-shadow-material-xxxxs-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxxxs-color-box-shadow: rgba(0, 0, 0, 0.15);
$es-theme-brand-zam-shadow-material-xxxs-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxxs-y-box-shadow: 1;
$es-theme-brand-zam-shadow-material-xxxs-blur-box-shadow: 2;
$es-theme-brand-zam-shadow-material-xxxs-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxxs-color-box-shadow: rgba(0, 0, 0, 0.25);
$es-theme-brand-zam-shadow-material-xxs-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxs-y-box-shadow: 2;
$es-theme-brand-zam-shadow-material-xxs-blur-box-shadow: 2;
$es-theme-brand-zam-shadow-material-xxs-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxs-color-box-shadow: rgba(0, 0, 0, 0.25);
$es-theme-brand-zam-shadow-material-xs-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xs-y-box-shadow: 3;
$es-theme-brand-zam-shadow-material-xs-blur-box-shadow: 4;
$es-theme-brand-zam-shadow-material-xs-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xs-color-box-shadow: rgba(0, 0, 0, 0.3);
$es-theme-brand-zam-shadow-material-s-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-s-y-box-shadow: 3;
$es-theme-brand-zam-shadow-material-s-blur-box-shadow: 4;
$es-theme-brand-zam-shadow-material-s-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-s-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-zam-shadow-material-m-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-m-y-box-shadow: 4;
$es-theme-brand-zam-shadow-material-m-blur-box-shadow: 7;
$es-theme-brand-zam-shadow-material-m-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-m-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-zam-shadow-material-l-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-l-y-box-shadow: 6;
$es-theme-brand-zam-shadow-material-l-blur-box-shadow: 10;
$es-theme-brand-zam-shadow-material-l-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-l-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-zam-shadow-material-xl-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xl-y-box-shadow: 8;
$es-theme-brand-zam-shadow-material-xl-blur-box-shadow: 10;
$es-theme-brand-zam-shadow-material-xl-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xl-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-zam-shadow-material-xxl-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxl-y-box-shadow: 12;
$es-theme-brand-zam-shadow-material-xxl-blur-box-shadow: 15;
$es-theme-brand-zam-shadow-material-xxl-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxl-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-zam-shadow-material-xxxl-x-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxxl-y-box-shadow: 14;
$es-theme-brand-zam-shadow-material-xxxl-blur-box-shadow: 22;
$es-theme-brand-zam-shadow-material-xxxl-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-material-xxxl-color-box-shadow: rgba(0, 0, 0, 0.4);
$es-theme-brand-zam-shadow-light-blur-xxxs-x-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xxxs-y-box-shadow: 12;
$es-theme-brand-zam-shadow-light-blur-xxxs-blur-box-shadow: 40;
$es-theme-brand-zam-shadow-light-blur-xxxs-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xxxs-color-box-shadow: rgba(
  0,
  0,
  0,
  0.05
);
$es-theme-brand-zam-shadow-light-blur-xs-x-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xs-y-box-shadow: 4;
$es-theme-brand-zam-shadow-light-blur-xs-blur-box-shadow: 8;
$es-theme-brand-zam-shadow-light-blur-xs-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xs-color-box-shadow: rgba(0, 0, 0, 0.1);
$es-theme-brand-zam-shadow-light-blur-s-x-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-s-y-box-shadow: 6;
$es-theme-brand-zam-shadow-light-blur-s-blur-box-shadow: 15;
$es-theme-brand-zam-shadow-light-blur-s-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-s-color-box-shadow: rgba(0, 0, 0, 0.12);
$es-theme-brand-zam-shadow-light-blur-m-x-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-m-y-box-shadow: 14;
$es-theme-brand-zam-shadow-light-blur-m-blur-box-shadow: 20;
$es-theme-brand-zam-shadow-light-blur-m-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-m-color-box-shadow: rgba(0, 0, 0, 0.1);
$es-theme-brand-zam-shadow-light-blur-l-x-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-l-y-box-shadow: 20;
$es-theme-brand-zam-shadow-light-blur-l-blur-box-shadow: 20;
$es-theme-brand-zam-shadow-light-blur-l-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-l-color-box-shadow: rgba(0, 0, 0, 0.08);
$es-theme-brand-zam-shadow-light-blur-xl-x-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xl-y-box-shadow: 16;
$es-theme-brand-zam-shadow-light-blur-xl-blur-box-shadow: 32;
$es-theme-brand-zam-shadow-light-blur-xl-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xl-color-box-shadow: rgba(0, 0, 0, 0.2);
$es-theme-brand-zam-shadow-light-blur-xxl-x-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xxl-y-box-shadow: 40;
$es-theme-brand-zam-shadow-light-blur-xxl-blur-box-shadow: 60;
$es-theme-brand-zam-shadow-light-blur-xxl-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-light-blur-xxl-color-box-shadow: rgba(0, 0, 0, 0.5);
$es-theme-brand-zam-shadow-custom-inner-light-x-box-shadow: 0;
$es-theme-brand-zam-shadow-custom-inner-light-y-box-shadow: 4;
$es-theme-brand-zam-shadow-custom-inner-light-blur-box-shadow: 6;
$es-theme-brand-zam-shadow-custom-inner-light-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-custom-inner-light-color-box-shadow: rgba(
  0,
  0,
  0,
  0.08
);
$es-theme-brand-zam-shadow-custom-header-x-box-shadow: 0;
$es-theme-brand-zam-shadow-custom-header-y-box-shadow: 10;
$es-theme-brand-zam-shadow-custom-header-blur-box-shadow: 32;
$es-theme-brand-zam-shadow-custom-header-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-custom-header-color-box-shadow: rgba(
  129,
  255,
  141,
  0.08
);
$es-theme-brand-zam-shadow-custom-big-button-x-box-shadow: 0;
$es-theme-brand-zam-shadow-custom-big-button-y-box-shadow: 12;
$es-theme-brand-zam-shadow-custom-big-button-blur-box-shadow: 12;
$es-theme-brand-zam-shadow-custom-big-button-spread-box-shadow: 0;
$es-theme-brand-zam-shadow-custom-big-button-color-box-shadow: rgba(
  46,
  125,
  50,
  0.5
);
$es-theme-brand-dot-gov-opasity-0-opasity: 0;
$es-theme-brand-dot-gov-opasity-05-opasity: 5;
$es-theme-brand-dot-gov-opasity-10-opasity: 10;
$es-theme-brand-dot-gov-opasity-15-opasity: 15;
$es-theme-brand-dot-gov-opasity-20-opasity: 20;
$es-theme-brand-dot-gov-opasity-25-opasity: 25;
$es-theme-brand-dot-gov-opasity-30-opasity: 30;
$es-theme-brand-dot-gov-opasity-35-opasity: 35;
$es-theme-brand-dot-gov-opasity-40-opasity: 40;
$es-theme-brand-dot-gov-opasity-45-opasity: 45;
$es-theme-brand-dot-gov-opasity-50-opasity: 50;
$es-theme-brand-dot-gov-opasity-55-opasity: 55;
$es-theme-brand-dot-gov-opasity-60-opasity: 60;
$es-theme-brand-dot-gov-opasity-65-opasity: 65;
$es-theme-brand-dot-gov-opasity-70-opasity: 70;
$es-theme-brand-dot-gov-opasity-75-opasity: 75;
$es-theme-brand-dot-gov-opasity-80-opasity: 80;
$es-theme-brand-dot-gov-opasity-85-opasity: 85;
$es-theme-brand-dot-gov-opasity-90-opasity: 90;
$es-theme-brand-dot-gov-opasity-95-opasity: 95;
$es-theme-brand-dot-gov-opasity-100-opasity: 100;
$es-theme-brand-dot-gov-color-primary-on-bg-disabled-color: rgba(
  183,
  41,
  19,
  0.3
);
$es-theme-brand-dot-gov-color-primary-bg-disabled-color: rgba(183, 41, 19, 0.3);
$es-theme-brand-dot-gov-color-primary-bg-hover-color: #d5341c;
$es-theme-brand-dot-gov-color-primary-bg-default-color: #b72913;
$es-theme-brand-dot-gov-color-primary-bg-pressed-color: #821d0d;
$es-theme-brand-dot-gov-color-primary-bg-selected-color: #5d1509;
$es-theme-brand-dot-gov-color-primary-bg-focused-color: #b72913;
$es-theme-brand-dot-gov-color-primary-on-bg-default-color: #b72913;
$es-theme-brand-dot-gov-color-primary-on-bg-hover-color: #d5341c;
$es-theme-brand-dot-gov-color-primary-on-bg-pressed-color: #821d0d;
$es-theme-brand-dot-gov-color-primary-on-bg-selected-color: #b72913;
$es-theme-brand-dot-gov-color-primary-on-bg-focused-color: #d5341c;
$es-theme-brand-dot-gov-color-secondary-bg-hover-color: #5a78a5;
$es-theme-brand-dot-gov-color-secondary-bg-default-color: #0f4692;
$es-theme-brand-dot-gov-color-secondary-bg-pressed-color: #173f7b;
$es-theme-brand-dot-gov-color-secondary-bg-selected-color: #0f4692;
$es-theme-brand-dot-gov-color-secondary-bg-focused-color: #0f4692;
$es-theme-brand-dot-gov-color-secondary-bg-disabled-color: rgba(
  15,
  70,
  146,
  0.3
);
$es-theme-brand-dot-gov-color-secondary-on-bg-default-color: #0f4692;
$es-theme-brand-dot-gov-color-secondary-on-bg-hover-color: #2264bf;
$es-theme-brand-dot-gov-color-secondary-on-bg-pressed-color: #173f7b;
$es-theme-brand-dot-gov-color-secondary-on-bg-selected-color: #4982cf;
$es-theme-brand-dot-gov-color-secondary-on-bg-focused-color: #2264bf;
$es-theme-brand-dot-gov-color-secondary-on-bg-disabled-color: rgba(
  15,
  70,
  146,
  0.4
);
$es-theme-brand-dot-gov-color-primary-lvl1-color: rgba(183, 41, 19, 0.08);
$es-theme-brand-dot-gov-color-primary-lvl2-color: rgba(183, 41, 19, 0.2);
$es-theme-brand-dot-gov-color-primary-lvl3-color: rgba(183, 41, 19, 0.4);
$es-theme-brand-dot-gov-color-secondary-lvl1-color: rgba(15, 70, 146, 0.1);
$es-theme-brand-dot-gov-color-secondary-lvl2-color: rgba(15, 70, 146, 0.2);
$es-theme-brand-dot-gov-color-secondary-lvl3-color: rgba(15, 70, 146, 0.4);
$es-theme-brand-dot-gov-color-shades-color: #4b6f98;
$es-theme-brand-dot-gov-color-shades50-color: rgba(75, 111, 152, 0.5);
$es-theme-brand-dot-gov-shadow-material-xxxxs-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxxxs-y-box-shadow: 1;
$es-theme-brand-dot-gov-shadow-material-xxxxs-blur-box-shadow: 3;
$es-theme-brand-dot-gov-shadow-material-xxxxs-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxxxs-color-box-shadow: rgba(
  0,
  0,
  0,
  0.15
);
$es-theme-brand-dot-gov-shadow-material-xxxs-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxxs-y-box-shadow: 1;
$es-theme-brand-dot-gov-shadow-material-xxxs-blur-box-shadow: 2;
$es-theme-brand-dot-gov-shadow-material-xxxs-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxxs-color-box-shadow: rgba(
  0,
  0,
  0,
  0.25
);
$es-theme-brand-dot-gov-shadow-material-xxs-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxs-y-box-shadow: 2;
$es-theme-brand-dot-gov-shadow-material-xxs-blur-box-shadow: 2;
$es-theme-brand-dot-gov-shadow-material-xxs-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxs-color-box-shadow: rgba(
  0,
  0,
  0,
  0.25
);
$es-theme-brand-dot-gov-shadow-material-xs-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xs-y-box-shadow: 3;
$es-theme-brand-dot-gov-shadow-material-xs-blur-box-shadow: 4;
$es-theme-brand-dot-gov-shadow-material-xs-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xs-color-box-shadow: rgba(0, 0, 0, 0.3);
$es-theme-brand-dot-gov-shadow-material-s-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-s-y-box-shadow: 3;
$es-theme-brand-dot-gov-shadow-material-s-blur-box-shadow: 4;
$es-theme-brand-dot-gov-shadow-material-s-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-s-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-dot-gov-shadow-material-m-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-m-y-box-shadow: 4;
$es-theme-brand-dot-gov-shadow-material-m-blur-box-shadow: 7;
$es-theme-brand-dot-gov-shadow-material-m-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-m-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-dot-gov-shadow-material-l-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-l-y-box-shadow: 6;
$es-theme-brand-dot-gov-shadow-material-l-blur-box-shadow: 10;
$es-theme-brand-dot-gov-shadow-material-l-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-l-color-box-shadow: rgba(0, 0, 0, 0.35);
$es-theme-brand-dot-gov-shadow-material-xl-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xl-y-box-shadow: 8;
$es-theme-brand-dot-gov-shadow-material-xl-blur-box-shadow: 10;
$es-theme-brand-dot-gov-shadow-material-xl-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xl-color-box-shadow: rgba(
  0,
  0,
  0,
  0.35
);
$es-theme-brand-dot-gov-shadow-material-xxl-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxl-y-box-shadow: 12;
$es-theme-brand-dot-gov-shadow-material-xxl-blur-box-shadow: 15;
$es-theme-brand-dot-gov-shadow-material-xxl-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxl-color-box-shadow: rgba(
  0,
  0,
  0,
  0.35
);
$es-theme-brand-dot-gov-shadow-material-xxxl-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxxl-y-box-shadow: 14;
$es-theme-brand-dot-gov-shadow-material-xxxl-blur-box-shadow: 22;
$es-theme-brand-dot-gov-shadow-material-xxxl-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-material-xxxl-color-box-shadow: rgba(
  0,
  0,
  0,
  0.4
);
$es-theme-brand-dot-gov-shadow-light-blur-xxxs-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xxxs-y-box-shadow: 12;
$es-theme-brand-dot-gov-shadow-light-blur-xxxs-blur-box-shadow: 40;
$es-theme-brand-dot-gov-shadow-light-blur-xxxs-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xxxs-color-box-shadow: rgba(
  0,
  0,
  0,
  0.05
);
$es-theme-brand-dot-gov-shadow-light-blur-xxs-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xxs-y-box-shadow: 4;
$es-theme-brand-dot-gov-shadow-light-blur-xxs-blur-box-shadow: 16;
$es-theme-brand-dot-gov-shadow-light-blur-xxs-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xxs-color-box-shadow: rgba(
  0,
  0,
  0,
  0.1
);
$es-theme-brand-dot-gov-shadow-light-blur-xs-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xs-y-box-shadow: 4;
$es-theme-brand-dot-gov-shadow-light-blur-xs-blur-box-shadow: 8;
$es-theme-brand-dot-gov-shadow-light-blur-xs-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xs-color-box-shadow: rgba(
  0,
  0,
  0,
  0.1
);
$es-theme-brand-dot-gov-shadow-light-blur-s-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-s-y-box-shadow: 6;
$es-theme-brand-dot-gov-shadow-light-blur-s-blur-box-shadow: 15;
$es-theme-brand-dot-gov-shadow-light-blur-s-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-s-color-box-shadow: rgba(
  0,
  0,
  0,
  0.12
);
$es-theme-brand-dot-gov-shadow-light-blur-m-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-m-y-box-shadow: 14;
$es-theme-brand-dot-gov-shadow-light-blur-m-blur-box-shadow: 20;
$es-theme-brand-dot-gov-shadow-light-blur-m-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-m-color-box-shadow: rgba(
  0,
  0,
  0,
  0.1
);
$es-theme-brand-dot-gov-shadow-light-blur-l-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-l-y-box-shadow: 20;
$es-theme-brand-dot-gov-shadow-light-blur-l-blur-box-shadow: 20;
$es-theme-brand-dot-gov-shadow-light-blur-l-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-l-color-box-shadow: rgba(
  0,
  0,
  0,
  0.08
);
$es-theme-brand-dot-gov-shadow-light-blur-xl-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xl-y-box-shadow: 16;
$es-theme-brand-dot-gov-shadow-light-blur-xl-blur-box-shadow: 32;
$es-theme-brand-dot-gov-shadow-light-blur-xl-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xl-color-box-shadow: rgba(
  0,
  0,
  0,
  0.2
);
$es-theme-brand-dot-gov-shadow-light-blur-xxl-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xxl-y-box-shadow: 40;
$es-theme-brand-dot-gov-shadow-light-blur-xxl-blur-box-shadow: 60;
$es-theme-brand-dot-gov-shadow-light-blur-xxl-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-light-blur-xxl-color-box-shadow: rgba(
  0,
  0,
  0,
  0.5
);
$es-theme-brand-dot-gov-shadow-custom-inner-light-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-custom-inner-light-y-box-shadow: 4;
$es-theme-brand-dot-gov-shadow-custom-inner-light-blur-box-shadow: 6;
$es-theme-brand-dot-gov-shadow-custom-inner-light-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-custom-inner-light-color-box-shadow: rgba(
  0,
  0,
  0,
  0.08
);
$es-theme-brand-dot-gov-shadow-custom-header-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-custom-header-y-box-shadow: 10;
$es-theme-brand-dot-gov-shadow-custom-header-blur-box-shadow: 32;
$es-theme-brand-dot-gov-shadow-custom-header-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-custom-header-color-box-shadow: rgba(
  183,
  41,
  19,
  0.08
);
$es-theme-brand-dot-gov-shadow-custom-big-button-x-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-custom-big-button-y-box-shadow: 12;
$es-theme-brand-dot-gov-shadow-custom-big-button-blur-box-shadow: 12;
$es-theme-brand-dot-gov-shadow-custom-big-button-spread-box-shadow: 0;
$es-theme-brand-dot-gov-shadow-custom-big-button-color-box-shadow: rgba(
  183,
  41,
  19,
  0.5
);
