.withImage {
  padding: 1rem;
}

.signImage {
  width: max-content;
  padding: 4px !important;
  p {
    line-height: 10px;
  }
}

.noStyleSignImage {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.signatureText {
  width: 132px !important;
}

.imageSignaturePadding {
  padding-right: 5px;
}
