.fileUploadContainer {
  min-height: 420px;
}

.fileUploadButton {
  width: 315px;
  height: 52px;
}

#fileUpload {
  width: 100%;
  height: 60%;
  opacity: 0;
  text-align: center;
  margin-top: -17rem;
  cursor: pointer;
}
