.historyPdf {
  min-height: 625px;
}

.closeBtn {
  background: none;
}

.historyDetails :global {
  .modal-title {
    flex: 1;
  }

  .otp {
    width: 100%;
  }
}

.pendingStatus :global,
.canceledStatus :global {
  .status {
    height: 100%;
    flex-direction: column;

    .status-buttons {
      flex: 1;

      .buttons-row {
        height: 100%;
      }
    }
  }
}

.pendingStatus :global {
  .buttons-col {
    display: flex;
    flex-direction: column-reverse;
  }
}

.canceledStatus :global {
  .buttons-col {
    display: flex;
    flex-direction: column-reverse;
  }
}
