.timer {
  font-size: 36px;
}

.title {
  font-size: 19px;
}

.subTitle {
  font-size: 14px;
}

.button {
  width: 193px;
}
