:global {
  .tooltip {
    white-space: pre-wrap;
    .tooltip-inner {
      max-width: 250px;
      text-align: left;
      white-space: break-spaces;
    }
  }
}

.tooltipWrapper {
  display: inline-block;
  position: relative;
}
