.footer {
  min-height: 240px;
  a {
    font-size: 0.9rem;
  }
}

.copyright {
  font-size: 0.75rem;
}
