.termsAndConditions {
  h2,
  h3,
  h4 {
    scroll-margin: 6rem;
  }

  @media (max-width: 800px) {
    margin-top: 150px !important;
  }

  @media (max-width: 600px) {
    margin-top: 200px !important;
  }
}

.menu {
  right: calc(var(--bs-gutter-x) * 0.5);
}
