.stepIcon {
  border-style: solid;
  height: 30px;
  width: 30px;
  line-height: 28px;
  font-size: 14px !important;
}

.stepper :global {
  .done:nth-child(2) {
    box-shadow: 10px 0.5rem 1rem rgba(51, 51, 51, 0.15) !important;
  }
}
