@import './fonts.scss';
[data-theme='lightDotgov'] {
  @import './variables/dotgov-light.scss';
  .open-sans-light {
    font-family: $open-sans-light !important;
  }

  .open-sans-regular {
    font-family: $open-sans-regular !important;
  }

  .open-sans-medium {
    font-family: $open-sans-medium !important;
  }

  .open-sans-semibold {
    font-family: $open-sans-semibold !important;
  }

  .open-sans-bold {
    font-family: $open-sans-bold !important;
  }

  .helvetica-regular {
    font-family: $helvetica-regular !important;
  }

  .icon-primary {
    color: $primary;
  }

  .text-danger {
    color: $text-error;
  }

  .not-focusable:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .border-dashed {
    border: 3px dashed $border-dashed-color;
  }

  .border-dashed-1 {
    border: 1px dashed $border-dashed-color;
  }

  // checkbox
  .checkbox-container {
    &:has(input:checked) {
      label {
        color: $text-success;
      }
    }
    input {
      width: $accordion-width;
      height: $accordion-height;
      border: $accordion-border;
      &:hover {
        border: $accordion-border-hover;
        background-color: $accordion-bg-hover;
      }
    }
    label {
      font-size: $accordion-label-font-size;
    }
  }
  .custom-checkbox {
    width: 16px !important;
    height: 16px !important;
  }

  // slider
  .slider-container {
    > .swiper {
      .swiper-pagination {
        .swiper-pagination-bullet-active {
          background: $primary;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      background: url('../../assets/images/icons/dotgov-arrow.png') no-repeat
        center;
    }
    h3 {
      font-size: $slider-title-font-size !important;
    }
    .swiper-slide {
      height: 428px;
    }
  }

  // switch theme
  .theme-toggle {
    background: $white;
    border: 1px solid $custom-control-indicator-border-color;
    font-size: $font-size-1;
    i {
      color: $grey-4;
    }
  }

  // tooltip
  .tooltip {
    color: $white;
    line-height: $line-height-170;
    .tooltip-inner {
      font-size: $font-size-5;
      border-radius: $tooltip-border-radius;
      color: $white !important;
    }
  }

  // status details
  .new-otp {
    .progress-bar {
      background-color: $progressBarBg;
    }
  }

  // delimiter
  .delimiter {
    background-color: $delimiter-bg;
  }

  // signature
  .signature {
    background-color: $signature-bg;
    border-radius: $signature-border-radius;
  }
  .default-sign-image {
    background-color: $signature-text-bg;
    border: $signature-border;
    box-shadow: $signature-box-shadow;
  }

  // navigations
  .custom-header {
    .nav-item.default-item {
      border-radius: 4px;
      &.active {
        background-color: $link-active-bg;
      }
      &:hover:not(.active) {
        background-color: $nav-item-hover-bg !important;
      }
      a {
        font-weight: 400 !important;
        &:hover {
          color: $text-main !important;
        }
        &:focus {
          color: initial !important;
        }
      }
    }
  }
  .navigation-section {
    a {
      background-color: $primary;
      color: $white;
      &:hover {
        box-shadow: 0px 10px 17px $home-button-hover-box-shadow;
      }
    }
  }

  // document view
  .uploaded-document::-webkit-scrollbar-track {
    background: $grey;
  }
  .uploaded-document::-webkit-scrollbar-thumb {
    background-color: $grey-5;
  }

  // footer
  .copyright {
    color: $grey-5;
  }

  .btn-attention {
    border-color: $btn-attention-color !important;
    color: $btn-attention-color !important;
    i {
      color: $btn-attention-color !important;
    }
    &:hover {
      background-color: $btn-attention-color !important;
      color: $white !important;
      i {
        color: $white !important;
      }
    }
  }

  // profile
  .profile-link {
    background-color: $profile-link-bg;
    border-radius: $profile-link-border-radius;
    p {
      font-size: $profile-link-font-size;
    }
  }
  .profile-menu {
    li {
      &.active {
        background-color: $profile-link-active-bg;
      }
      &:hover:not(.active) {
        background-color: $white;
      }
    }
  }
  .no-hover,
  .no-hover:hover,
  .dropdown-item:active {
    color: inherit !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .profile-link-dropdown {
    &.show {
      i {
        display: block !important;
        transform: rotate(180deg) !important;
      }
    }
  }
  .profile-link-dropdown + .dropdown-menu {
    background-color: $profile-link-dropdown-bg !important;
    box-shadow: $profile-link-box-shadow;
    border: none !important;
    p,
    a {
      color: $text-primary !important;
    }
    .dropdown-item {
      &:hover {
        background-color: $profile-link-dropdown-item-hover-bg !important;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  // history details
  .history-pdf :global .react-pdf__Document {
    background-color: $white;
  }
  .close-btn {
    font-size: $h3-font-size;
  }
  .history-details :global {
    background-color: $background-color !important;
  }

  //login
  .login-container {
    background-size: cover;
    i {
      font-size: $h4-font-size;
    }
    .login-background {
      p {
        font-size: $h6-font-size;
        line-height: 22px;
        &.unverified {
          font-size: $h4-font-size;
        }
        &.unverified-info {
          font-size: $font-size-2;
        }
        &.download-app {
          font-size: $font-size-5;
          font-weight: 500;
          line-height: $font-size-3;
        }
      }
    }
  }

  // data table
  .table-container {
    background-color: $grey-10;
  }
  .data-table {
    thead {
      th {
        border-bottom: $input-border-width solid $grey-8;
        color: $table-thead-color;
        &.sorting-column {
          background-color: $table-thead-active-bg !important;
        }
        div {
          font-size: 15px;
        }
        &:hover {
          background-color: $table-thead-hover-bg;
        }
      }
    }
    tbody {
      tr {
        &.selected {
          td {
            background-color: $table-tbody-selected-bg;
          }
        }
        td {
          font-size: 15px;
          padding: 0;
          max-width: 300px;
          border-bottom-color: $grey-7;
          .table-text {
            min-height: 38px;
          }
        }
      }
      > tr:hover > * {
        background-color: $table-tbody-hover-bg;
      }
      .btn.btn-link.action-button {
        color: $primary;
        padding-left: 5px;
        padding-right: 5px;
        text-decoration: none;
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  // values
  .tag-value {
    background-color: $values-item-bg;
    border-radius: $values-item-border-radius;
    box-shadow: $values-item-boxshadow;
    .icon-close {
      color: $values-icon-close-color;
    }
  }

  // status
  .status {
    p {
      font-size: 15px;
    }
    .new-otp {
      p {
        font-size: 32px;
      }
    }
  }

  // filter table
  .filter-table {
    .react-datepicker__header {
      h2.react-datepicker__current-month {
        font-size: initial !important;
      }
    }
    input.form-control {
      font-size: 14px !important;
    }
    .react-datepicker__input-container {
      input.form-control {
        min-width: 200px !important;
      }
    }
    .multi-select {
      position: absolute;
      .dropdown-heading {
        min-width: 250px !important;
        height: 33px !important;
      }
    }
    .btn.filter-button {
      color: $primary;
      padding-left: 5px;
      padding-right: 5px;
      text-decoration: none;
      &:hover {
        background-color: transparent !important;
      }
      i {
        &.rotate {
          transform: rotateX(180deg);
        }
      }
    }
    .filter-tag {
      background-color: $filter-tag-bg;
      border-radius: 8px;
      font-size: 13px;
      height: 24px;
      .remove-icon {
        color: $filter-tag-icon-color;
      }
    }
    .filter-form {
      background-color: $filter-form-bg;
      height: 0px;
      opacity: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      &.with-animation {
        height: 75px;
        overflow: visible;
        opacity: 1;
      }
    }
    .form-label {
      font-size: 13px !important;
    }
    .refresh-button {
      padding: 7px !important;
      height: 28px !important;
      i {
        font-size: 19px;
      }
    }
  }

  .selection-options {
    background-color: $table-selection-options-bg;
    border-radius: 4px;
    height: 28px;
    .text-selection {
      font-size: 14px;
    }
  }

  // header section
  .section-page {
    background-color: $es-color-background-main-lvl2-color;
    .header-section {
      background-color: $header-section-bg;
      height: 56px;
      p {
        font-size: 16px;
        line-height: 25px;
        i {
          color: $header-section-icon-color;
        }
      }
    }
    .inner-section {
      border-radius: 5px;
    }
  }
  .logo-text {
    font-size: $font-size-3;
  }

  // sidebar
  .sidebar {
    background-color: $sidebar-bg;
    .full-name {
      color: $text-primary;
    }
  }

  // layout
  .container-inner {
    background-color: $layout-bg;
    box-shadow: 0px 20px 20px 0px $layout-box-shadow-color;
  }

  // pagination
  .btn-pagination {
    background-color: $pagination-item-bg !important;
    border: none !important;
    min-height: 40px !important;
    margin: 0px 2px !important;
    color: $text-primary !important;
    font-size: 16px !important;
    &:hover,
    &[aria-pressed='true'] {
      background-color: $primary !important;
      color: $white !important;
      i {
        color: $white !important;
      }
    }
    i {
      color: $primary;
    }
  }

  .pagination-info {
    font-size: 14px;
    font-weight: 500;
  }

  .select-pagination {
    width: 52px !important;
    padding: 4px 5px !important;
    background-position: right 0.3rem center !important;
    background-color: $es-controls-button-admin-states-default-background-primary-color;
    height: 40px !important;
    font-size: 16px !important;
  }

  // checkbox table
  .checkbox-table {
    width: 18px !important;
    height: 18px !important;
    border: 2px solid $checkbox-table-border !important;
    border-radius: 2px !important;
    background-size: 10px !important;
    &:checked {
      --bs-form-check-bg-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%228%22%20viewBox%3D%220%200%2010%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%204.58012L1.14303%203.40788L4.47771%206.82776L3.33468%208L0%204.58012Z%22%20fill%3D%22%23B72913%22%2F%3E%3Cpath%20d%3D%22M8.85697%200L10%201.17224L3.34902%207.99316L2.20598%206.82092L8.85697%200Z%22%20fill%3D%22%23B72913%22%2F%3E%3C%2Fsvg%3E) !important;
    }
  }

  //separator
  .separator {
    background-color: $separator-bg;
  }
  .selection-separator {
    height: 28px !important;
  }

  // sign page
  .signed-container :global {
    .react-pdf__Document {
      box-shadow: 0 1rem 3rem rgba($black, 0.175);
    }
  }

  //modal
  .card-max-width,
  .status-buttons {
    max-width: 450px !important;
    .delete-button {
      background-color: $modal-button-error-bg;
      color: $white;
      &:hover {
        background-color: $modal-button-error--hover-bg;
        color: $white;
      }
    }
    .delete-button,
    .cancel-button,
    .archive-button,
    .modal-button {
      width: 183px;
    }
    h2 {
      font-size: 24px !important;
    }
  }
  .card-max-width {
    background-color: $modal-bg;
  }

  .status-buttons {
    .delete-button {
      width: 100% !important;
    }
  }

  // spinner
  .lds-spinner {
    color: $spinner-bg;
    div:after {
      background: url('../../assets/images/icons/dotgov-spinner.svg') no-repeat;
    }
  }

  // datepicker
  .react-datepicker-wrapper {
    .react-datepicker__close-icon {
      &:after {
        color: $black;
        background-color: transparent !important;
        font-size: 18px !important;
      }
    }
  }

  // main container
  #mainContainer {
    background-color: $background-color;
  }

  // user info
  .user-info {
    .form-label {
      color: $input-label-disabled-color !important;
    }
  }

  // stepper
  .stepper-container {
    .step-button {
      border-color: $primary !important;
    }
    &:first-child {
      .active {
        border-top-left-radius: 50rem;
        border-bottom-left-radius: 50rem;
        border-top-right-radius: 50rem;
        border-bottom-right-radius: 50rem;
      }
      .completed {
        border-top-left-radius: 50rem;
        border-bottom-left-radius: 50rem;
      }
    }
    .completed {
      background-color: $stepper-bg;
    }

    &:not(:first-child) {
      .active {
        border-top-right-radius: 50rem;
        border-bottom-right-radius: 50rem;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
    .active {
      background-color: $stepper-bg;
    }
  }

  // upload signature
  .file-information {
    box-shadow: $file-information-shadow;
    border-radius: $file-information-border-radius;
    .file-name {
      font-size: 14px;
      color: $file-name-color;
    }
    .file-size {
      font-size: 12px;
      color: $file-size-color;
    }
  }

  // home page
  .home-page {
    p {
      &.subtitle {
        font-size: $home-page-subtitle-font-size;
        font-weight: 600;
      }
    }
  }

  // slider range
  .slider-range {
    i,
    p {
      color: $slider-range-icon-color;
    }
  }
}
