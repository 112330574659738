@import '../themes/zam-light.scss';

$open-sans-light: 'Open Sans Light';
$open-sans-regular: 'Open Sans Regular';
$open-sans-medium: 'Open Sans Medium';
$open-sans-semibold: 'Open Sans SemiBold';
$open-sans-bold: 'Open Sans Bold';

$helvetica-regular: 'Helvetica';

$font-family-sans-serif: 'Open Sans Regular', sans-serif !important;
$font-family: $font-family-sans-serif;

$black: #333333;
$spacer: 1rem !default;
$font-size-base: 1rem !default;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1280px,
);

$navbar-light-toggler-border-color: none;
$navbar-light-color: $black;
$navbar-nav-link-padding-x: 25px;

$h1-font-size: 60px;
$h2-font-size: 42px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 18px;

$h1-sm-font-size: 32px;
$h2-sm-font-size: 28px;
$h3-sm-font-size: 24px;
$h4-sm-font-size: 18px;
$h5-sm-font-size: 14px;

$font-size-1: 18px;
$font-size-2: 16px;
$font-size-2-1: 15px;
$font-size-3: 14px;
$font-size-4: 13px;
$font-size-5: 12px;
$font-size-6: 10px;
$font-size-7: 9px;
$font-size-8: 8px;

$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

$btn-border-radius: 4px;
$btn-border-radius-lg: 4px;
$btn-border-radius-sm: 4px;
$btn-disabled-opacity: 0.25;
$link-color: $es-controls-link-states-default-text-primary-color;

$grey: #f2f2f2;
$grey-0: $es-theme-neutral-gray-100-color;
$grey-1: $es-theme-neutral-gray-100-color;
$grey-2: $es-theme-neutral-gray-200-color;
$grey-3: $es-theme-neutral-gray-100-color;
$grey-4: $es-theme-neutral-gray-400-color;
$grey-5: $es-theme-neutral-gray-500-color;
$grey-6: $es-theme-neutral-gray-600-color;
$grey-6: $es-theme-neutral-gray-600-color;
$grey-7: #f2f2f2;
$grey-8: $es-theme-neutral-gray-150-color;
$grey-10: $es-theme-neutral-gray-100-color;

$background-color: $es-color-background-main-lvl2-color;

$green: $es-color-green-500-color;
$orange: $es-color-attention-500-color;
$red: $es-color-text-error-default-color;
$white: $es-color-background-white-everywhere-color;

$primary: $es-color-stroke-primary-default-color;
$secondary: $es-color-text-secondary-default-color;
$text-main: $es-color-neutral-blue-900-color;
$text-muted: $es-theme-neutral-gray-600-color;
$text-warning: $es-color-attention-500-color;
$text-disabled: $es-color-stroke-gray-strong-color;
$text-description: $es-theme-neutral-gray-600-color;
$text-primary: $es-color-text-main-color;

$line-height-170: 170%;
$tooltip-border-radius: 4px;
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $green,
  'main': $text-main,
  'link': $link-color,
  'grey': $grey-0,
  'grey-1': $grey-1,
  'grey-2': $grey-2,
  'grey-3': $grey-3,
  'grey-4': $grey-4,
  'grey-5': $grey-5,
  'warning': $text-warning,
  'icon': $es-theme-neutral-gray-600-color,
  'input': $es-theme-neutral-gray-520-color,
  'error': $red,
  'disabled': $text-disabled,
  'description': $text-description,
);

$tooltip-color: $es-controls-tooltip-states-default-text-primary-color;
$tooltip-bg: $es-controls-tooltip-states-default-background-secondary-color;

$form-check-input-checked-bg-color: $primary;
$form-check-input-border: 2px solid $primary;

$input-color: $es-controls-input-states-default-text-input-color;
$input-label-color: $es-controls-input-states-default-text-label-primary-color;
$input-label-disabled-color: $es-controls-input-states-disabled-text-label-primary-color;
$input-font-size: 18px;
$input-border-width: 1px;
$input-border-color: $es-controls-input-states-default-border-color;
$input-height: 40px;
$input-placeholder-color: $es-controls-input-states-default-text-placeholder-color;
$form-label-color: $es-controls-input-states-default-text-label-primary-color;
$form-label-margin-bottom: 0px;
$input-md-focus-color: $es-controls-input-states-focused-text-input-color;
$input-disabled-color: $es-controls-input-states-disabled-text-input-color;
$input-disabled-border-color: $es-controls-input-states-disabled-border-color;
$input-error-color: $red;
$input-focus-border-color: $es-controls-input-states-focused-border-color;
$input-focus-box-shadow: none;

$input-form-control-padding-top: 4px;
$input-form-control-padding-bottom: 0px;

//text
$text-success: $es-color-text-success-color;

//checkbox
$form-check-input-width: 1em;
$form-check-min-height: 1em;
$form-check-input-checked-bg-color: $es-controls-check-state-default-background-primary-color;
$form-check-input-checked-border-color: $primary;
$form-check-input-checked-color: $black;
$form-switch-focus-color: $primary;
$form-switch-circle-color: $es-controls-switch-states-inactive-default-background-btn-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z' fill='#{$primary}'/></svg>") !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z' fill='#{$form-switch-circle-color}'/></svg>") !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z' fill='#{$primary}'/></svg>") !default;
$accordion-label-font-size: 18px;
$accordion-border: 1px solid
  $es-controls-switch-states-active-default-border-container-color !important;
$accordion-border-hover: 1px solid $primary !important;
$accordion-bg-hover: $es-controls-switch-states-active-hover-background-container-color !important;
$accordion-width: 2.75rem !important;
$accordion-height: 24px !important;
$checkbox-table-border: $es-controls-check-state-default-border-color;

//slider
$custom-control-indicator-border-color: $grey-5;
$custom-control-indicator-border-width: 2px;
$slider-title-font-size: 28px;

//buttons
$btn-padding-y: 6px;
$btn-padding-x: 19px;
$btn-font-size: 17px;
$btn-line-height: 23.8px;
$btn-attention-color: $es-controls-button-main-states-error-outlined-default-stroke-color;

//breadcrumbs
$breadcrumb-divider: quote('>') !default;
$breadcrumb-divider-color: $primary;
$breadcrumb-padding-y: 20px;
$breadcrumb-bg: $white;
$breadcrumb-font-size: 13px;
$breadcrumb-item-padding: 15px;

//Turn off !important property for Bootstrap utility classes
$enable-important-utilities: false;

//signature
$signature-bg: $es-color-background-main-lvl4-color;
$signature-border-radius: 8px;
$signature-border: 1px dashed
  $es-controls-divider-states-default-background-attention-color;
$signature-box-shadow: 0px 6px 10px 0px #00000059;
$signature-text-bg: $white;

//list group
$list-group-border: 1px solid rgba(51, 51, 51, 0.125);

// Profile link
$profile-link-bg: $es-controls-button-admin-states-hover-background-primary-color;
$profile-link-border-radius: 20px;
$profile-link-font-size: $font-size-1;
$profile-link-box-shadow: rgba(0, 0, 0, 0.35) 0px 12px 15px 0px;
$profile-link-dropdown-item-hover-bg: $es-controls-dropdown-states-dragged-background-secondary-color;
$profile-link-dropdown-bg: $es-controls-menu-states-default-background-secondary-color;
$profile-link-active-bg: $es-controls-sidebar-states-closed-active-background-secondary-color;

//delimiter
$delimiter-bg: $es-controls-divider-states-default-background-gray-l-ight-color;

//values
$values-item-bg: $es-controls-chips-states-selected-default-background-secondary-color;
$values-item-border-radius: 8px;
$values-item-boxshadow: 0px 1px 2px 0px #00000040;
$values-icon-close-color: $es-controls-chips-states-selected-hover-icon-secondary-color;

// status details
$progressBarBg: $es-color-success-30-color;

// separator
$separator-bg: $es-controls-divider-states-default-background-gray-l-ight-color;

// text error
$text-error: $es-color-error-500-color;

// table
$table-thead-color: $es-controls-data-table-thead-states-default-text-color;
$table-thead-active-bg: $es-controls-data-table-thead-states-active-background-secondary-color;
$table-thead-hover-bg: $es-controls-data-table-thead-states-hover-background-primary-color;
$table-tbody-selected-bg: $es-controls-data-table-tbody-states-select-background-primary-color;
$table-tbody-hover-bg: $es-controls-data-table-tbody-states-hover-background-primary-color;
$table-selection-options-bg: $es-controls-data-table-thead-states-default-background-secondary-color;

// filter
$filter-tag-bg: $es-controls-chips-states-selected-default-background-secondary-color;
$filter-tag-icon-color: $es-controls-chips-states-selected-default-icon-secondary-color;
$filter-form-bg: $es-color-background-primary-lvl1-color;

// header section
$header-section-bg: $es-controls-header-states-default-background-primary-lvl-color;
$header-section-icon-color: $es-color-background-gray-strong-color;

// sidebar
$sidebar-bg: $es-controls-sidebar-states-open-default-background-secondary-color;

// layout
$layout-bg: $es-color-background-main-lvl3-color;
$layout-box-shadow-color: $es-color-neutral-0-08-color;

// pagination
$pagination-item-bg: $es-controls-pagination-states-default-background-primary-color;

// modal
$modal-bg: $es-controls-modal-states-default-background-lvl2-color;
$modal-button-error-bg: $es-controls-button-main-states-error-filled-default-background-color;
$modal-button-error--hover-bg: $es-controls-button-main-states-error-filled-hover-background-color;

// navigations
$nav-item-hover-bg: $es-controls-button-main-header-states-hover-background-primary-color;

// spinner
$spinner-bg: $es-color-icon-primary-default-color;

// home
$home-button-hover-box-shadow: $es-shadow-custom-big-button-color-box-shadow;

// border
$border-dashed-color: $es-color-stroke-gray-color;

// stepper
$stepper-bg: $es-controls-stepper-states-default-background-primary-lvl-color;
$stepper-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);

// upload signature
$file-information-shadow: $es-shadow-material-xxxs-color-box-shadow;
$file-information-border-radius: 4px;
$file-name-color: $es-controls-card-states-default-text-primary-color;
$file-size-color: $es-controls-card-states-default-text-secondary-color;

// login
$login-box-shadow: 0px 40px 60px 0px #000000;

// home page
$home-page-subtitle-font-size: 22px;

// menu link
$link-active-bg: $es-controls-link-states-active-background-primary-color;

// slider range
$slider-range-icon-color: $es-theme-neutral-gray-550-color;
$slider-range-text-color: $es-theme-neutral-gray-550-color;
