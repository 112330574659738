.tag {
  min-height: 32px;
  padding: 4px 8px;
  line-height: 20px;
  display: inline-flex;
  vertical-align: top;
  -webkit-box-align: center;
  align-items: center;
  span {
    max-width: 685px;
  }
}

.addTag {
  line-height: 17px;
}
