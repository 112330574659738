.checkbox {
  background-position: 8% !important;
  background-size: 16px 16px !important;
  border-radius: 2em !important;
  margin-left: -2.5em !important;
  transition: background-position 0.15s ease-in-out;
  margin-top: 0px !important;
  &:checked {
    background-position: 92% !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
