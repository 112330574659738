$font-family-sans-serif: 'Open Sans Regular', sans-serif !important;
$font-family: $font-family-sans-serif;

$h1-font-size: 60px;
$h2-font-size: 42px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 18px;

$h1-sm-font-size: 32px;
$h2-sm-font-size: 28px;
$h3-sm-font-size: 24px;
$h4-sm-font-size: 18px;
$h5-sm-font-size: 14px;

$font-size-1: 18px;
$font-size-2: 16px;
$font-size-2-1: 15px;
$font-size-3: 14px;
$font-size-4: 13px;
$font-size-5: 12px;
$font-size-6: 10px;
$font-size-7: 9px;
$font-size-8: 8px;

$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
